/* Conat Events Business HTML-5 Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Two / Three
5. Slider One / Two / Three
6. About One / Two
7. Counter One / Two / Three
8. Speaker One / Two / Three / Four
9. Feature One
10. Leadership One
11. Gallery One / Two
12. Price One / Two / Three
13. Video One
14. Clients One / Two
15. News One / Two / Three
16. Susbscribe One / Two
17. Main Footer / Two / Three
18. Event One / Two
19. Team One / Two
20. Faq One
21. Location One
22. Services One
23. Business One
24. Testimonial One
25. Page Title
26. Map One
27. Coming Soon
28. Terms & Condition
29. Privacy Policy
30. Speaker Detail
31. Event Detail
32. Register
33. Our Blog
34. Blog Widgets
35. Comment Form
36. Comment Box
37. Not Found / 404
38. Contact Form
39. Contact Info
40. Validate Form

**********************************************/

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

/* 

	font-family: 'Manrope', sans-serif;
	
*/

/*** 

====================================================================
	Root Code Variables
====================================================================

 ***/

.theme_color {
	color: var(--main-color);
}


/* Theme Color */

:root {
	scroll-behavior: unset;

	/* #ffc300 in decimal RGB */
	--main-color: #c99c18;
	--main-color-rgb: 255, 195, 0;

	/* #1a1719 in decimal RGB */
	--color-two: #fff;
	--color-twob: #000;
	--color-two-rgb: 26, 23, 25;

	/* #e00e7a in decimal RGB */
	--color-three: rgb(113, 0, 154);
	--color-three-rgb: 224, 14, 122;

	/* #2a00ff in decimal RGB */
	--color-four: rgb(42, 0, 255);
	--color-four-rgb: 42, 0, 255;

	/* #746a6f in decimal RGB */
	--color-five: #fff;
	--color-five-rgb: 116, 106, 111;

	/* #1967ea in decimal RGB */
	--color-six: rgb(25, 103, 234);
	--color-six-rgb: 25, 103, 234;

	/* #f5f5f5 in decimal RGB */
	--color-seven: rgb(245, 245, 245);
	--color-seven-rgb: 245, 245, 245;

	/* #0e0058 in decimal RGB */
	--color-eight: rgb(14, 0, 88);
	--color-eight-rgb: 14, 0, 88;

	/* #150082 in decimal RGB */
	--color-nine: rgb(21, 0, 130);
	--color-nine-rgb: 21, 0, 130;

	/* #ebebeb in decimal RGB */
	--color-ten: rgb(235, 235, 235);
	--color-ten-rgb: 235, 235, 235;

	/* #000a43 in decimal RGB */
	--color-eleven: rgb(0, 10, 67);
	--color-eleven-rgb: 0, 10, 67;

	/* #f8f8f8 in decimal RGB */
	--color-twelve: rgb(248, 248, 248);
	--color-twelve-rgb: 248, 248, 248;


	/* #ffffff in decimal RGB */
	--white-color: rgb(255, 255, 255);
	--white-color-rgb: 255, 255, 255;

	/* #000000 in decimal RGB */
	--black-color: rgb(0, 0, 0);
	--black-color-rgb: 0, 0, 0;

	/* Fonts */
	--font-family-Manrope: 'Manrope', sans-serif;


	/* Heading Fonts */

	--font-10: 10px;
	--font-12: 12px;
	--font-13: 13px;
	--font-14: 14px;
	--font-15: 15px;
	--font-16: 16px;
	--font-18: 18px;
	--font-20: 20px;
	--font-22: 22px;
	--font-24: 24px;
	--font-26: 26px;
	--font-28: 28px;
	--font-30: 30px;
	--font-34: 34px;
	--font-36: 36px;
	--font-40: 40px;
	--font-44: 44px;
	--font-46: 46px;
	--font-48: 48px;
	--font-50: 50px;
	--font-52: 52px;
	--font-54: 54px;
	--font-56: 56px;
	--font-58: 58px;
	--font-60: 60px;
	--font-62: 62px;
	--font-64: 64px;
	--font-66: 66px;
	--font-68: 68px;
	--font-70: 70px;
	--font-72: 72px;
	--font-74: 74px;
	--font-76: 76px;
	--font-78: 78px;
	--font-80: 80px;
	--font-84: 84px;
	--font-90: 90px;
	--font-96: 96px;
	--font-100: 100px;
	--font-110: 110px;
	--font-120: 120px;
	--font-150: 150px;
	--font-200: 200px;





	--margin-zero: 0px;

	/* Margin Left Variables */
	--margin-left-5: 5px;
	--margin-left-10: 10px;
	--margin-left-15: 15px;
	--margin-left-20: 20px;
	--margin-left-25: 25px;
	--margin-left-30: 30px;
	--margin-left-35: 35px;
	--margin-left-40: 40px;
	--margin-left-45: 45px;
	--margin-left-50: 50px;
	--margin-left-55: 55px;
	--margin-left-60: 60px;
	--margin-left-65: 65px;
	--margin-left-70: 70px;
	--margin-left-75: 75px;
	--margin-left-80: 80px;
	--margin-left-85: 85px;
	--margin-left-90: 90px;
	--margin-left-95: 95px;
	--margin-left-100: 100px;
	--margin-left-120: 120px;
	--margin-left-150: 150px;

	/* Margin Top Variables */
	--margin-top-5: 5px;
	--margin-top-10: 10px;
	--margin-top-15: 15px;
	--margin-top-20: 20px;
	--margin-top-21: 21px;
	--margin-top-25: 25px;
	--margin-top-30: 30px;
	--margin-top-35: 35px;
	--margin-top-40: 40px;
	--margin-top-45: 45px;
	--margin-top-50: 50px;
	--margin-top-55: 55px;
	--margin-top-60: 60px;
	--margin-top-65: 65px;
	--margin-top-70: 70px;
	--margin-top-75: 75px;
	--margin-top-80: 80px;
	--margin-top-85: 85px;
	--margin-top-90: 90px;
	--margin-top-95: 95px;
	--margin-top-100: 100px;
	--margin-top-120: 120px;
	--margin-top-140: 140px;
	--margin-top-150: 150px;

	/* Margin Right Variables */
	--margin-right-5: 5px;
	--margin-right-10: 10px;
	--margin-right-15: 15px;
	--margin-right-20: 20px;
	--margin-right-25: 25px;
	--margin-right-30: 30px;
	--margin-right-35: 35px;
	--margin-right-40: 40px;
	--margin-right-45: 45px;
	--margin-right-50: 50px;
	--margin-right-55: 55px;
	--margin-right-60: 60px;
	--margin-right-65: 65px;
	--margin-right-70: 70px;
	--margin-right-75: 75px;
	--margin-right-80: 80px;
	--margin-right-85: 85px;
	--margin-right-90: 90px;
	--margin-right-95: 95px;
	--margin-right-100: 100px;
	--margin-right-110: 110px;

	/* Margin Bottom Variables */
	--margin-bottom-5: 5px;
	--margin-bottom-10: 10px;
	--margin-bottom-15: 15px;
	--margin-bottom-20: 20px;
	--margin-bottom-25: 25px;
	--margin-bottom-30: 30px;
	--margin-bottom-35: 35px;
	--margin-bottom-40: 40px;
	--margin-bottom-45: 45px;
	--margin-bottom-50: 50px;
	--margin-bottom-55: 55px;
	--margin-bottom-60: 60px;
	--margin-bottom-65: 65px;
	--margin-bottom-70: 70px;
	--margin-bottom-75: 75px;
	--margin-bottom-80: 80px;
	--margin-bottom-85: 85px;
	--margin-bottom-90: 90px;
	--margin-bottom-95: 95px;
	--margin-bottom-100: 100px;
	--margin-bottom-130: 130px;
	--margin-bottom-150: 150px;
	--margin-bottom-200: 200px;


	--padding-zero: 0px;

	/* Padding Left Variables */
	--padding-left-5: 5px;
	--padding-left-10: 10px;
	--padding-left-15: 15px;
	--padding-left-20: 20px;
	--padding-left-25: 25px;
	--padding-left-30: 30px;
	--padding-left-35: 35px;
	--padding-left-40: 40px;
	--padding-left-45: 45px;
	--padding-left-50: 50px;
	--padding-left-55: 55px;
	--padding-left-60: 60px;
	--padding-left-65: 65px;
	--padding-left-70: 70px;
	--padding-left-75: 75px;
	--padding-left-80: 80px;
	--padding-left-85: 85px;
	--padding-left-90: 90px;
	--padding-left-95: 95px;
	--padding-left-100: 100px;
	--padding-left-110: 110px;
	--padding-left-115: 115px;
	--padding-left-120: 120px;
	--padding-left-130: 130px;
	--padding-left-140: 140px;
	--padding-left-150: 150px;
	--padding-left-160: 160px;
	--padding-left-170: 170px;
	--padding-left-180: 180px;
	--padding-left-190: 190px;
	--padding-left-200: 200px;
	--padding-left-240: 240px;
	--padding-left-290: 290px;

	/* Padding Top Variables */
	--padding-top-5: 5px;
	--padding-top-10: 10px;
	--padding-top-15: 15px;
	--padding-top-20: 20px;
	--padding-top-25: 25px;
	--padding-top-30: 30px;
	--padding-top-35: 35px;
	--padding-top-40: 40px;
	--padding-top-45: 45px;
	--padding-top-50: 50px;
	--padding-top-55: 55px;
	--padding-top-60: 60px;
	--padding-top-65: 65px;
	--padding-top-70: 70px;
	--padding-top-75: 75px;
	--padding-top-80: 80px;
	--padding-top-85: 85px;
	--padding-top-90: 90px;
	--padding-top-95: 95px;
	--padding-top-100: 100px;
	--padding-top-110: 110px;
	--padding-top-120: 120px;
	--padding-top-125: 125px;
	--padding-top-130: 130px;
	--padding-top-140: 140px;
	--padding-top-150: 150px;
	--padding-top-155: 155px;
	--padding-top-160: 160px;
	--padding-top-200: 200px;
	--padding-top-250: 250px;

	/* Padding Right Variables */
	--padding-right-5: 5px;
	--padding-right-10: 10px;
	--padding-right-15: 15px;
	--padding-right-20: 20px;
	--padding-right-25: 25px;
	--padding-right-30: 30px;
	--padding-right-35: 35px;
	--padding-right-40: 40px;
	--padding-right-45: 45px;
	--padding-right-50: 50px;
	--padding-right-55: 55px;
	--padding-right-60: 60px;
	--padding-right-65: 65px;
	--padding-right-70: 70px;
	--padding-right-75: 75px;
	--padding-right-80: 80px;
	--padding-right-85: 85px;
	--padding-right-90: 90px;
	--padding-right-95: 95px;
	--padding-right-100: 100px;
	--padding-right-110: 110px;
	--padding-right-120: 120px;
	--padding-right-150: 150px;
	--padding-right-160: 160px;
	--padding-right-170: 170px;
	--padding-right-180: 180px;
	--padding-right-200: 200px;
	--padding-right-220: 220px;
	--padding-right-240: 240px;
	--padding-right-250: 250px;
	--padding-right-260: 260px;

	/* Padding Bottom Variables */
	--padding-bottom-5: 5px;
	--padding-bottom-10: 10px;
	--padding-bottom-15: 15px;
	--padding-bottom-20: 20px;
	--padding-bottom-25: 25px;
	--padding-bottom-30: 30px;
	--padding-bottom-35: 35px;
	--padding-bottom-40: 40px;
	--padding-bottom-45: 45px;
	--padding-bottom-50: 50px;
	--padding-bottom-55: 55px;
	--padding-bottom-60: 60px;
	--padding-bottom-65: 65px;
	--padding-bottom-70: 70px;
	--padding-bottom-75: 75px;
	--padding-bottom-80: 80px;
	--padding-bottom-85: 85px;
	--padding-bottom-90: 90px;
	--padding-bottom-95: 95px;
	--padding-bottom-100: 100px;
	--padding-bottom-110: 110px;
	--padding-bottom-120: 120px;
	--padding-bottom-130: 130px;
	--padding-bottom-150: 150px;
	--padding-bottom-200: 200px;
	--padding-bottom-250: 250px;

}

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
}

/*** 

====================================================================
	Global Settings
====================================================================

***/


/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: var(--main-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: var(--color-three);
}


body {
	font-family: var(--font-family-Manrope);
	font-size: 14px;
	color: var(--text-color);
	line-height: 1.6em;
	font-weight: 400;
	background-color: #000000;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}

.App {
	overflow: hidden;
}

.fi {
	font-family: "Flaticon";
	font-weight: normal;
	font-style: normal;
}

/* Preloader */

.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999999;
	background-color: #ffffff;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../images/icons/preloader.gif);
	background-size: 220px;

}

.bordered-layout .page-wrapper {
	padding: 0px 50px 0px;
}

a {
	text-decoration: none !important;
	cursor: pointer;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	color: var(--main-color);
}

button,
a:hover,
a:focus,
a:visited {
	text-decoration: none;
	outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	position: relative;
	margin: 0px;
	background: none;
	color: var(--main-color-two);
	font-family: var(--font-family-Manrope);
}

input,
button,
select,
textarea {}

textarea {
	overflow: hidden;
}

.text,
p {
	position: relative;
	line-height: 1.7em;
	font-size: var(--font-18);
	color: var(--text-color);
}

/* Typography */

h1 {
	line-height: 96px;
	font-weight: 800;
	font-size: var(--font-96);
}

h2 {
	line-height: 1.2em;
	font-weight: 700;
	font-size: var(--font-40);
}

h3 {
	line-height: 1.3em;
	font-weight: 700;
	font-size: var(--font-30);
}

h4 {
	line-height: 1.4em;
	font-weight: 700;
	font-size: var(--font-24);
}

h5 {
	line-height: 1.6em;
	font-weight: 700;
	font-size: var(--font-20);
}

h6 {
	line-height: 1.6em;
	font-weight: 700;
	font-size: var(--font-18);
}

.auto-container {
	position: static;
	max-width: 1313px;
	padding: 0px 15px;
	margin: 0 auto;
}

.medium-container {
	position: static;
	max-width: 1000px;
	padding: 0px 15px;
	margin: 0 auto;
}

.page-wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	min-width: 300px;
}

ul,
li {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

img {
	display: inline-block;
	max-width: 100%;
}

.theme-btn {
	cursor: pointer;
	display: inline-block;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.centered {
	text-align: center;
}

::-webkit-input-placeholder {
	color: inherit;
}

::-moz-input-placeholder {
	color: inherit;
}

::-ms-input-placeholder {
	color: inherit;
}

/***

====================================================================
	Scroll To Top style
====================================================================

***/

.progress-wrap {
	position: fixed;
	right: 10px;
	bottom: 32px;
	height: 50px;
	width: 50px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	z-index: 99;
	transform: translateY(15px);
	-webkit-transition: all 200ms linear;
	transition: all ease 0.5s;
	background-color: var(--main-color);
}

.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	bottom: 40px;
}

.progress-wrap::after {
	position: absolute;
	content: "\e627";
	font-weight: 400;
	font-family: 'themify';
	text-align: center;
	line-height: 50px;
	font-size: 18px;
	left: 0;
	top: 0;
	height: 50px;
	width: 50px;
	cursor: pointer;
	display: block;
	z-index: 1;
	color: #fff;
}

.progress-wrap svg path {
	fill: none;
}

.progress-wrap svg.progress-circle path {
	stroke: var(--color-four);
	stroke-width: 4;
	box-sizing: border-box;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}

/* List Style One */

.list-style-one {
	position: relative;
}

.list-style-one li {
	position: relative;
	color: var(--white-color);
	font-size: var(--font-18);
	font-weight: 400;
	line-height: 1.8em;
	margin-bottom: var(--margin-bottom-10);
	padding-left: var(--padding-left-30);
}

.list-style-one li a {
	position: relative;
	color: var(--white-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.list-style-one li a:hover {
	color: var(--main-color);
}

.list-style-one li .icon {
	position: absolute;
	left: 0px;
	top: 5px;
	color: var(--main-color);
	font-size: var(--font-18);
	line-height: 1em;
	font-weight: 300;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

/* Btn Style One */

.btn-style-one {
	position: relative;
	font-weight: 700;
	overflow: hidden;
	text-align: center;
	border-radius: 0px;
	padding: 18px 44px;
	letter-spacing: 2px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-12);
	text-transform: uppercase;
	background-color: var(--main-color);
}

.btn-style-one:before {
	-webkit-transition-duration: 800ms;
	transition-duration: 800ms;
	position: absolute;
	width: 200%;
	height: 200%;
	content: "";
	top: -200%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	border-radius: 50%;
	z-index: 1;
	background-color: #a47b01;
}

.btn-style-one:hover:before {
	top: 0%;
}

.btn-style-one .btn-wrap {
	position: relative;
	z-index: 1;
	float: left;
	overflow: hidden;
	display: inline-block;
}

.btn-style-one .btn-wrap .text-one {
	position: relative;
	display: block;
	color: var(--color-twob);
	transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
}

.btn-style-one:hover .btn-wrap .text-one:first-child {
	-webkit-transform: translateY(-150%);
	-ms-transform: translateY(-150%);
	transform: translateY(-150%);
}

.btn-style-one .btn-wrap .text-two {
	position: absolute;
	top: 100%;
	display: block;
	color: var(--white-color);
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.btn-style-one:hover .btn-wrap .text-two {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.btn-style-one:hover .btn-wrap .text-two {
	color: var(--white-color);
}

.btn-style-one:hover {}

.btn-style-one:hover:before {
	top: -40%;
}

/* Btn Style Two */

.btn-style-two {
	position: relative;
	font-weight: 700;
	overflow: hidden;
	text-align: center;
	border-radius: 0px;
	padding: 17px 52px;
	letter-spacing: 2px;
	display: inline-block;
	color: var(--white-color);
	font-size: var(--font-12);
	text-transform: uppercase;
	background-color: var(--color-four);
}

.btn-style-two:before {
	-webkit-transition-duration: 800ms;
	transition-duration: 800ms;
	position: absolute;
	width: 200%;
	height: 200%;
	content: "";
	top: -200%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	border-radius: 50%;
	z-index: 1;
	background-color: var(--color-two);
}

.btn-style-two:hover:before {
	top: 0%;
}

.btn-style-two .btn-wrap {
	position: relative;
	z-index: 1;
	float: left;
	overflow: hidden;
	display: inline-block;
}

.btn-style-two .btn-wrap .text-one {
	position: relative;
	display: block;
	color: var(--white-color);
	transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
}

.btn-style-two:hover .btn-wrap .text-one:first-child {
	-webkit-transform: translateY(-150%);
	-ms-transform: translateY(-150%);
	transform: translateY(-150%);
}

.btn-style-two .btn-wrap .text-two {
	position: absolute;
	top: 100%;
	display: block;
	color: var(--white-color);
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.btn-style-two:hover .btn-wrap .text-two {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.btn-style-two:hover .btn-wrap .text-two {
	color: var(--white-color);
}

.btn-style-two:hover {}

.btn-style-two:hover:before {
	top: -40%;
}

/* Btn Style Three */

.btn-style-three {
	position: relative;
	font-weight: 700;
	overflow: hidden;
	text-align: center;
	border-radius: 0px;
	padding: 18px 44px;
	letter-spacing: 2px;
	display: inline-block;
	color: var(--white-color);
	font-size: var(--font-12);
	text-transform: uppercase;
	background-color: var(--main-color);
}

.btn-style-three:before {
	-webkit-transition-duration: 800ms;
	transition-duration: 800ms;
	position: absolute;
	width: 200%;
	height: 200%;
	content: "";
	top: -200%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	border-radius: 50%;
	z-index: 1;
	background-color: var(--white-color);
}

.btn-style-three:hover:before {
	top: 0%;
}

.btn-style-three .btn-wrap {
	position: relative;
	z-index: 1;
	float: left;
	overflow: hidden;
	display: inline-block;
}

.btn-style-three .btn-wrap .text-one {
	position: relative;
	display: block;
	color: var(--color-two);
	transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
}

.btn-style-three:hover .btn-wrap .text-one:first-child {
	-webkit-transform: translateY(-150%);
	-ms-transform: translateY(-150%);
	transform: translateY(-150%);
}

.btn-style-three .btn-wrap .text-two {
	position: absolute;
	top: 100%;
	display: block;
	color: var(--white-color);
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.btn-style-three:hover .btn-wrap .text-two {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.btn-style-three:hover .btn-wrap .text-two {
	color: var(--color-two);
}

.btn-style-three:hover {}

.btn-style-three:hover:before {
	top: -40%;
}

img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
	position: relative;
	margin-bottom: var(--margin-bottom-40);
}

.sec-title_title {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	color: #c99c18;
	font-size: var(--font-18);
	/* text-transform: uppercase; */
	margin-bottom: var(--margin-bottom-20);
}

.sec-title_heading {
	color: #ffffff;
}

.sec-title_text {
	line-height: 30px;
	font-size: var(--font-18);
	color: var(--color-five);
	margin-top: var(--margin-top-25);
}

.sec-title.light .sec-title_title,
.sec-title.light .sec-title_text,
.sec-title.light .sec-title_heading {
	color: var(--white-color);
}

.sec-title.centered {
	text-align: center !important;
}

.sec-title.centered .separator {
	margin: 0 auto;
}

/* Custom Select */

.form-group .ui-selectmenu-button.ui-button {
	top: -2px;
	width: 100%;
	border: 0px;
	padding: 0px 0px;
	font-style: normal;
	line-height: 28px;
	font-size: var(--font-14);
	color: var(--color-three);
	background-color: var(--white-color);
}

.form-group .ui-button .ui-icon {
	background: none;
	position: relative;
	top: 12px;
	text-indent: 0px;
	color: #a5a5a5;
}

.form-group .ui-button .ui-icon:before {
	font-family: 'FontAwesome';
	content: "\f107";
	position: absolute;
	right: 0px;
	top: 2px !important;
	top: 13px;
	height: 20px;
	display: block;
	line-height: 20px;
	font-size: var(--font-12);
	font-weight: normal;
	text-align: center;
	z-index: 5;
	color: var(--color-fiftyfour);
}

.ui-menu .ui-menu-item {
	font-size: var(--font-14);
}

.ui-menu .ui-menu-item:last-child {
	border: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
	background-color: var(--main-color) !important;
	border-color: var(--main-color) !important;
}

.ui-menu .ui-menu-item-wrapper {
	position: relative;
	display: block;
	padding: 8px 20px;
	line-height: 24px;
	font-size: var(--font-14);
}

.ui-menu-item:hover {
	background-color: var(--main-color);
}

/* Newsletter Popup Area Section */

.newsletter-popup-area-section {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999999;
	-webkit-transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
	-o-transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
	transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
	overflow-y: scroll;
}

.newsletter-popup-area-section .newsletter-popup-area {
	position: relative;
	width: 100%;
	max-width: 1025px;
	z-index: 100;
	margin: 200px auto;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	background: #fff;
}

.newsletter-popup-area-section .newsletter-popup-area:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	width: 60%;
	bottom: 0px;
	background-color: var(--white-color);
}

.errorMessage{
	color: red;
	margin-top: 2px;
}

.active-newsletter-popup .newsletter-popup-area {
	margin-top: 0;
}

.newsletter-popup-area-section .newsletter-popup-area .newsletter-popup-ineer {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	flex-wrap: wrap;
}


.newsletter-popup-area-section .close-sidebar-widget {
	position: absolute;
	right: 25px;
	top: 25px;
	z-index: 1;
	font-weight: 700;
	cursor: pointer;
	font-size: var(--font-20);
	color: var(--black-color);
	font-family: "Flaticon";
}

.active-newsletter-popup {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 1s ease-in-out, z-index 1s ease-in-out;
	-o-transition: opacity 1s ease-in-out, z-index 1s ease-in-out;
	transition: opacity 1s ease-in-out, z-index 1s ease-in-out;
}

.active-newsletter-popup,
.activve-quick-view-single-product {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
	-o-transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
}

.newsletter-popup-area-section .img-holder {
	margin-left: -40px;
	min-height: 450px;
	flex-basis: 50%;
}

.newsletter-popup-area-section .img-holder img {
	position: relative;
	left: -1px;
}

.newsletter-popup-area-section .detail-box {
	position: relative;
	padding: 20px 40px;
}

.newsletter-popup-area-section .detail-box h3 {
	max-width: 360px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: var(--margin-bottom-35);
}

.newsletter-popup-area-section .detail-box h3 span {
	position: relative;
	font-weight: 300;
}

.newsletter-popup-area-section .detail-box .sub-title {
	position: relative;
	text-align: center;
	font-weight: 700;
	font-size: var(--font-18);
	color: var(--black-color);
	margin-top: var(--margin-top-20);
}


@media(max-width:1050px){
	.newsletter-popup-area-section .newsletter-popup-area .newsletter-popup-ineer {
		flex-wrap: wrap;
	}

	.newsletter-popup-area-section .newsletter-popup-area {
		max-width: 520px;
		background: #fff;
	}

	.newsletter-popup-area-section .close-sidebar-widget .fi{
		color: #fff;
	}

	.newsletter-popup-area-section .img-holder {
		margin-left: 2px;
		min-height: 400px;
		flex-basis: 100%;
	}

}

/* Newsletter Box */

.newsletter-popup-area-section .newsletter-box {
	position: relative;
}

.newsletter-popup-area-section .newsletter-box .form-group {
	position: relative;
	margin: 0px;
	width: 100%;
	max-width: 100%;
	border-radius: 4px;
	/* background-color:var(--color-eighteen); */
}

.newsletter-popup-area-section .newsletter-box .form-group input[type="text"],
.newsletter-popup-area-section .newsletter-box .form-group input[type="email"] {
	position: relative;
	line-height: 43px;
	display: block;
	height: 62px;
	width: 100%;
	margin: 0px 10px;
	border-radius: 4px;
	box-shadow: inherit;
	font-size: var(--font-18);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	padding: 10px 15px 10px 25px;
	color: var(--black-color);
	background-color: var(--white-color);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
}

.newsletter-popup-area-section .newsletter-box .form-group input::placeholder,
.newsletter-popup-area-section .newsletter-box .form-group textarea::placeholder {
	color: var(--black-color);
}

.newsletter-popup-area-section .newsletter-box .form-group button {
	position: relative;
	width: 100%;
	margin: 0px 10px;
	border-radius: 5px;
	font-weight: 700;
	padding: 22px 15px;
	letter-spacing: 1px;
	font-size: var(--font-18);
	text-transform: uppercase;
	color: var(--black-color);
	margin-top: var(--margin-top-15);
	background-color: var(--main-color);
}

.newsletter-popup-area-section .newsletter-box .form-group button:hover {
	color: var(--white-color);
	background-color: var(--black-color);
}