/*** 

====================================================================
	Header style One
====================================================================

***/

.main-header{
	position:absolute;
	left: 0px;
	right: 0px;
	z-index:99;
	width:100%;
}

.main-header .main-box{
	position:relative;
	padding:0px 0px;
	left:0px;
	top:0px;
	width:100%;
	background:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-box .outer-container{
	position:relative;
	padding:0px 40px;
}

.main-header .main-box .logo-box{
	position:relative;
	float:left;
	left:0px;
	z-index:10;
	padding:30px 0px;
}

.main-header .main-box .logo-box .logo img{
	display:inline-block;
	max-width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-header .header-lower{
	position:relative;
}

.main-header .header-lower .inner-container{
	position:relative;
	border-top:2px solid var(--color-five);
}

.main-header .header-lower .nav-outer{
	position:relative;
	padding-right:15px;
}

.main-header .header-upper .logo-box{
	position: relative;
	padding:30px 0px;
}

.main-header .header-upper .logo-box .logo{
	position:relative;
	width: 200px;
}

@media(max-width:1023px){
	.main-header .header-upper .logo-box{
		padding: 20px 0;
	}
	.main-header .header-upper .logo-box .logo{
		max-width: 120px;
	}
}
.main-header .main-menu .navigation > li:nth-child(7) > ul,
.main-header .main-menu .navigation > li:nth-child(8) > ul{
	left:0px;
	right:auto !important;
}

/* Main Menu */

.main-header .main-menu{
	position:relative;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-menu .navbar-collapse{
	padding:0px;
	display:block;
}

.main-header .header-lower .main-menu .navigation{
	position:relative;
}

.main-header .main-menu .navigation > li{
	position:relative;
	display:inline-block;
	padding:15px 0px 15px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	margin-right:var(--margin-right-15);
}

.main-header .main-menu .navigation > li.dropdown > a{
	padding-right:20px;
}

.main-header .main-menu .navigation > li.dropdown > a:before{
	position: absolute;
    content: "\f107";
    right: 0px;
	font-weight:800;
	font-size:var(--font-12);
	font-family: 'FontAwesome';
}

/* .main-header .main-menu .navigation > li.dropdown:hover > a:before{
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
    animation: icon-bounce 0.8s ease-out infinite;
} */

.main-header .sticky-header .main-menu .navigation > li > a{
	color: var(--color-two);
}

.main-header .sticky-header .nav-outer .options-box{
	margin-top:var(--margin-top-40);
}

/*Sticky Header*/

.main-header .sticky-header{
	position:fixed;
	opacity:0;
	visibility:hidden;
	left:0px;
	top:0px;
	width:100%;
	padding:0px 0px;
	z-index:0;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background-color:var(--white-color);
	box-shadow:0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .sticky-header .mobile-nav-toggler{
	display:none;
	cursor:pointer;
}

.main-header .sticky-header .logo{
	position:relative;
	padding:10px 0px;
}

.main-header.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;	
}

.main-header .main-menu .navigation > li > a{
	position:relative;
	display:block;
	font-weight:600;
	padding:0px 0px;
	text-align:center;
	line-height:20px;
	letter-spacing:1px;
	color:var(--white-color);
	font-size:var(--font-14);
	text-transform: uppercase;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > a .menu-text{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    text-shadow: 0 16px 0 var(--white-color);
}

.main-header .sticky-header .main-menu .navigation > li > a .menu-text{
	text-shadow: 0 16px 0 var(--black-color);
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(1){
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.main-header .main-menu .navigation > li:hover > a span{
    --m: calc(16px * -1);
}

.main-menu > li > a .menu-text span:nth-child(1), .main-menu-4 > li > a .menu-text span:nth-child(1) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(2) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(3) {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(4){
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(5){
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(6){
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(7){
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(8){
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(9){
    -webkit-transition-delay: 0.45s;
    transition-delay: 0.45s;
}

.main-header .main-menu .navigation > li > a .menu-text span:nth-child(10){
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

.main-header .main-menu .navigation > li > a .menu-text span{
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-style: normal;
    -webkit-transition: -webkit-transform 0.4s ease;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    -webkit-transform: translateY(var(--m)) translateZ(0);
    transform: translateY(var(--m)) translateZ(0);
}

.main-header .main-menu .navigation > li > a > span{
	position:absolute;
	right:-25px;
	top:20px;
	width:42px;
	height:16px;
	font-weight:600;
	line-height:1.6em;
	border-radius:50px;
	letter-spacing:1px;
	font-size:var(--font-11);
	text-transform:uppercase;
	color:var(--black-color);
	background-color:var(--main-color);
}

.main-header .main-menu .navigation > li:last-child{
	margin-right:var(--margin-zero);
}

.main-header .sticky-header .main-menu .navigation > li{
	position:relative;
}

.main-header .sticky-header .main-menu .navigation > li:last-child{
	margin-right:var(--margin-zero);
}

.main-header .main-menu .navigation > li:last-child ul{
	right:0px;
}

.main-header .main-menu .navigation > li:hover > a,
.main-header .main-menu .navigation > li.current > a{
	opacity:1;
}

.main-header .main-menu .navigation > li > ul{
	position:absolute;
	width:18rem;
	top:100%;
	padding:25px 25px 30px 25px;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	opacity: 0;
	visibility: hidden;
    visibility: hidden;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	border-radius:0px;
	background-color:var(--white-color);
	box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .main-menu .navigation > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-header .main-menu .navigation > li > ul > li{
	position:relative;
	width:100%;
	opacity:0;
	text-align:left;
	transform:translateY(11px);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	margin-bottom:var(--margin-bottom-15);
}

.main-header .main-menu .navigation > li > ul > li:last-child{
	margin-bottom:var(--margin-zero);
}

.main-header .main-menu .navigation > li:hover > ul > li{
	opacity:1;
	transform:translateY(0px);
	-webkit-transition-delay: 70ms;
	-moz-transition-delay: 70ms;
	-ms-transition-delay: 70ms;
	-o-transition-delay: 70ms;
	transition-delay: 70ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(2){
	-webkit-transition-delay: 140ms;
	-moz-transition-delay: 140ms;
	-ms-transition-delay: 140ms;
	-o-transition-delay: 140ms;
	transition-delay: 140ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(3){
	-webkit-transition-delay: 210ms;
	-moz-transition-delay: 210ms;
	-ms-transition-delay: 210ms;
	-o-transition-delay: 210ms;
	transition-delay: 210ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(4){
	-webkit-transition-delay: 280ms;
	-moz-transition-delay: 280ms;
	-ms-transition-delay: 280ms;
	-o-transition-delay: 280ms;
	transition-delay: 280ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(5){
	-webkit-transition-delay: 350ms;
	-moz-transition-delay: 350ms;
	-ms-transition-delay: 350ms;
	-o-transition-delay: 350ms;
	transition-delay: 350ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(6){
	-webkit-transition-delay: 420ms;
	-moz-transition-delay: 420ms;
	-ms-transition-delay: 420ms;
	-o-transition-delay: 420ms;
	transition-delay: 420ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(7){
	-webkit-transition-delay: 490ms;
	-moz-transition-delay: 490ms;
	-ms-transition-delay: 490ms;
	-o-transition-delay: 490ms;
	transition-delay: 490ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(8){
	-webkit-transition-delay: 560ms;
	-moz-transition-delay: 560ms;
	-ms-transition-delay: 560ms;
	-o-transition-delay: 560ms;
	transition-delay: 560ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(9){
	-webkit-transition-delay: 630ms;
	-moz-transition-delay: 630ms;
	-ms-transition-delay: 630ms;
	-o-transition-delay: 630ms;
	transition-delay: 630ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(10){
	-webkit-transition-delay: 700ms;
	-moz-transition-delay: 700ms;
	-ms-transition-delay: 700ms;
	-o-transition-delay: 700ms;
	transition-delay: 700ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(11){
	-webkit-transition-delay: 770ms;
	-moz-transition-delay: 770ms;
	-ms-transition-delay: 770ms;
	-o-transition-delay: 770ms;
	transition-delay: 770ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(12){
	-webkit-transition-delay: 840ms;
	-moz-transition-delay: 840ms;
	-ms-transition-delay: 840ms;
	-o-transition-delay: 840ms;
	transition-delay: 840ms;
}

.main-header .main-menu .navigation > li:hover > ul > li:nth-child(13){
	-webkit-transition-delay: 900ms;
	-moz-transition-delay: 900ms;
	-ms-transition-delay: 900ms;
	-o-transition-delay: 900ms;
	transition-delay: 900ms;
}

.main-header .main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;
}

.main-header .main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	line-height:24px;
	letter-spacing:0.5px;
	font-weight:600;
	font-size:var(--font-14);
	text-transform:uppercase;
	color:var(--black-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li > a:before{
	position:absolute;
	content:'';
	left:0px;
	top:6px;
	width:16px;
	height:10px;
	opacity:0;
	display:inline-block;
	transform:rotate(45deg);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background:url(../images/icons/right-arrow.png) no-repeat;
}

.main-header .main-menu .navigation > li > ul > li:hover > a{
	color:var(--color-three);
	padding-left:var(--padding-left-20);
}

.main-header .main-menu .navigation > li > ul > li:hover > a::before{
	opacity:1;
}

.main-header .main-menu .navigation > li > ul > li.dropdown > a:after{
	position:absolute;
	content: "\f105";
	right:10px;
	top:1px;
	z-index:5;
	width:10px;
	height:20px;
	display:block;
	line-height:20px;
	font-weight:normal;
	text-align:center;
	font-weight:800;
	font-size:var(--font-12);
	color:var(--black-color);
	font-family: 'FontAwesome';
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > a:after{
	color:var(--color-three);
}

.main-header .main-menu .navigation > li > ul > li > ul{
	position:absolute;
	left:100%;
	top:0px;
	width: 16rem;
    padding:25px 25px 30px 25px;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	opacity: 0;
	visibility: hidden;
    visibility: hidden;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	border-radius:0px;
	background-color:var(--white-color);
	box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .main-menu .navigation > li > ul > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-header .main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	width:100%;
	text-align:left;
	opacity:0;
	margin-bottom: var(--margin-bottom-15);
	transform:translateY(-8px);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:last-child{
	margin-bottom:var(--margin-zero);
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li{
	opacity:1;
	transform:translateY(0px);
	-webkit-transition-delay: 70ms;
	-moz-transition-delay: 70ms;
	-ms-transition-delay: 70ms;
	-o-transition-delay: 70ms;
	transition-delay: 70ms;
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li:nth-child(2){
	-webkit-transition-delay: 140ms;
	-moz-transition-delay: 140ms;
	-ms-transition-delay: 140ms;
	-o-transition-delay: 140ms;
	transition-delay: 140ms;
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li:nth-child(3){
	-webkit-transition-delay: 210ms;
	-moz-transition-delay: 210ms;
	-ms-transition-delay: 210ms;
	-o-transition-delay: 210ms;
	transition-delay: 210ms;
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li:nth-child(4){
	-webkit-transition-delay: 280ms;
	-moz-transition-delay: 280ms;
	-ms-transition-delay: 280ms;
	-o-transition-delay: 280ms;
	transition-delay: 280ms;
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li:nth-child(5){
	-webkit-transition-delay: 350ms;
	-moz-transition-delay: 350ms;
	-ms-transition-delay: 350ms;
	-o-transition-delay: 350ms;
	transition-delay: 350ms;
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li:nth-child(6){
	-webkit-transition-delay: 420ms;
	-moz-transition-delay: 420ms;
	-ms-transition-delay: 420ms;
	-o-transition-delay: 420ms;
	transition-delay: 420ms;
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li:nth-child(7){
	-webkit-transition-delay: 490ms;
	-moz-transition-delay: 490ms;
	-ms-transition-delay: 490ms;
	-o-transition-delay: 490ms;
	transition-delay: 490ms;
}

.main-header .main-menu .navigation > li > ul > li.dropdown:hover > ul > li:nth-child(8){
	-webkit-transition-delay: 560ms;
	-moz-transition-delay: 560ms;
	-ms-transition-delay: 560ms;
	-o-transition-delay: 560ms;
	transition-delay: 560ms;
}

.map a{
	position:relative;
	display:block;
	width:100%;
	height:100%;
	z-index:1;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-header .main-menu .navigation > li > ul > li > ul > li > a{
	position:relative;
	display:block;
	line-height:24px;
	letter-spacing:0.5px;
	font-weight:600;
	font-size:var(--font-14);
	text-transform:uppercase;
	color:var(--black-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li > ul > li > a:before{
	position:absolute;
	content:'';
	left:0px;
	top:6px;
	width:16px;
	height:10px;
	opacity:0;
	display:inline-block;
	transform:rotate(45deg);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background:url(../images/icons/right-arrow.png) no-repeat;
}

.main-header .main-menu .navigation > li > ul > li > ul > li > a:hover::before{
	opacity:1;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:var(--main-color);
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:var(--color-three);
	padding-left:var(--padding-left-20);
}

.main-header .main-menu .navigation > li.dropdown:hover > ul{
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	visibility: visible;
}

.main-header .main-menu .navigation li > ul > li.dropdown:hover > ul{
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	visibility: visible;
}

.main-header .main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:6px;
	width:30px;
	height:30px;
	text-align:center;
	color:var(--white-color);
	line-height:28px;
	border:1px solid var(--white-color-opicity-two);
	background-size:20px;
	cursor:pointer;
	z-index:5;
	display:none;
}

.header-two{
	top: 40px;
}

.header-two .header-upper .inner-container{
	position: relative;
	padding: 0px 30px;
	background-color: var(--white-color);
	border-top: 10px solid var(--color-ten);
}

.header-two .header-upper .logo-box{
	padding: 10px 0px;
}

.main-header.header-two .search-box-btn{
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50px;
	font-size: var(--font-14);
	padding: var(--padding-zero);
	background-color: var(--color-three);
}

.header-two .main-menu .navigation > li > a{
	color: var(--color-two);
}

.main-header.header-two .header-upper .main-menu .navigation > li > a .menu-text{
    text-shadow: 0 16px 0 var(--black-color);
}

.main-header.header-two .outer-box .nav-btn{
	color: var(--color-four);
}

.header-top{
	position: relative;
}

.header-top_socials{
	position: relative;
	padding: 12px 30px;
	border-radius: 50px 0px 0px 50px;
	background-color: var(--color-four);
}

.header-top_socials a{
	position: relative;
	margin: 0px 8px;
	font-size: var(--font-16);
	color: var(--white-color);
}

.header-top_socials a:hover{
	color: var(--color-three);
}

/*** 

====================================================================
	Mobile Menu
====================================================================

***/

.main-header .mobile-nav-toggler{
	position: relative;
	float: right;
	font-size: var(--font-24);
	line-height: 44px;
	cursor: pointer;
	color:var(--white-color);
	display: none;
	font-family: "Flaticon";
	margin-left: var(--margin-left-20);
}

.main-header .sticky-header .mobile-nav-toggler{
	color:var(--black-color);
}

.mobile-menu{
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right:30px;
	max-width:100%;
	height: 100%;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .nav-logo{
	position:relative;
	padding:20px 20px;
	text-align:left;	
}

.mobile-menu .nav-logo img{
	max-width:200px;
}

.mobile-menu-visible{
	overflow: hidden;
}

.mobile-menu-visible .mobile-menu{
	visibility: visible;
}

.mobile-menu .menu-backdrop{
	position: fixed;
	right: 0;
	top: 0;
	width: 0%;
	height: 100%;
	z-index: 1;
	background: rgba(0,0,0,0.90);
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.mobile-menu-visible .mobile-menu .menu-backdrop{
	opacity: 1;
	width:100%;
	visibility: visible;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
	

}

.mobile-menu .menu-box{
	position: absolute;
	right: -400px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #ffffff;
	padding: 0px 0px;
	z-index: 5;
	border-radius: 0px;
	
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.mobile-menu-visible .mobile-menu .menu-box{
	right:0px;
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.mobile-menu .close-btn{
	position: absolute;
	right: 15px;
	top: 15px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	font-size: 14px;
	color: #202020;
	cursor: pointer;
	z-index: 10;
	-webkit-transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	-ms-transition:all 0.5s ease;
	-o-transition:all 0.5s ease;
	transition:all 0.5s ease;
	-webkit-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
	font-family: "Flaticon";
}

.mobile-menu-visible .mobile-menu .close-btn{
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition-delay: 900ms;
	-moz-transition-delay: 900ms;
	-ms-transition-delay: 900ms;
	-o-transition-delay: 900ms;
	transition-delay: 900ms;
}

.mobile-menu .close-btn:hover{
	opacity: 0.50;
}

.mobile-menu .navigation{
	position: relative;
	display: block;
	width:100%;
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > ul > li:last-child{
	border-bottom: none;
}

.mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li:hover > a,
.mobile-menu .navigation li.current > a{
	color:#111111;
}

.mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:44px;
	height:44px;
	text-align:center;
	font-size:16px;
	line-height:44px;
	color:#404040;
	cursor:pointer;
	z-index:5;
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.mobile-menu .navigation li > ul > li > a{
	padding-left:30px;
}

.mobile-menu .navigation li > ul > li > ul{
	padding-left:0px;
}

.mobile-menu .navigation li > ul > li > ul > li > a{
	padding-left:40px;
}

.main-header .mobile-menu .close-btn:hover{
	opacity: 0.50;
}

.main-header .mobile-menu .navigation{
	position: relative;
	display: block;
	width:100%;
	border-top: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li{
	position: relative;
	display: block;
	border-bottom: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > ul > li:last-child{
	border-bottom: none;
}

.main-header .mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > a{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 12px 20px;
	font-size: var(--font-14);
	color: var(--black-color);
	text-transform: capitalize;
}

.main-header .mobile-menu .navigation li:hover > a,
.main-header .mobile-menu .navigation li.current > a{
	
}

.main-header .mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:44px;
	height:44px;
	text-align:center;
	color: var(--dark-color);
	font-size: var(--font-16);
	line-height:44px;
	cursor:pointer;
	z-index:5;
}

.main-header .mobile-menu .navigation li.dropdown .dropdown-btn:after{
	content:'';
	position:absolute;
	left:0px;
	top:10px;
	width:1px;
	height:24px;
	border-left:1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > ul,
.main-header .mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.main-header .sticky-header .navbar-header{
	display:none;
}

.main-header .outer-box{
	position: relative;
}

/* Search Box Outer */

.main-header .search-box-outer{
	position:relative;
}

.main-header .search-box-btn{
	position:relative;
	display:block;
	cursor:pointer;
	background:none;
	padding:19px 0px;
	text-align:center;
	color:var(--white-color);
	font-size:var(--font-20);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .outer-box .nav-btn{
	position:relative;
	width:60px;
	cursor:pointer;
	font-weight:800;
	padding:21px 0px;
	text-align:center;
	font-size:var(--font-18);
	color:var(--white-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Font Awesome 6 Free';
}

.main-header .outer-box .nav-btn:hover{
	color:var(--color-four);
}

.appointmeny_button-box{
	position:relative;
	height:60px;
	margin-left: var(--margin-left-10);
}

.hamburger {
	position:relative;
	
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 70px;
	height: 74px;
	cursor: pointer;
	padding: 27px 20px;
	background-color:var(--color-four);
}

.hamburger span {
  /* align-self: flex-end; */
  height: 2px;
  width: 83.33333333%;
  background: #ffffff;
  transition: all 400ms ease-in-out;
}

.hamburger .meat {
  width: 100%;
  transition: all 200ms ease-in-out;
}

.hamburger .bottom-bun {
  width: 58.33333333%;
  transition: all 100ms ease-in-out;
}

.hamburger:hover span {
  width: 100%;
}

html:not(.body-menu-opened) .hamburger .top-bun {
  -webkit-animation: burger-hover 1s infinite ease-in-out alternate forwards 200ms;
          animation: burger-hover 1s infinite ease-in-out alternate forwards 200ms;
}

html:not(.body-menu-opened) .hamburger .meat {
  -webkit-animation: burger-hover 1s infinite ease-in-out alternate forwards 400ms;
          animation: burger-hover 1s infinite ease-in-out alternate forwards 400ms;
}

html:not(.body-menu-opened) .hamburger .bottom-bun {
  -webkit-animation: burger-hover 1s infinite ease-in-out alternate forwards 600ms;
          animation: burger-hover 1s infinite ease-in-out alternate forwards 600ms;
}

@-webkit-keyframes burger-hover {
  0% {
    width: 100%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

@keyframes burger-hover {
  0% {
    width: 100%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

/*** 

====================================================================
	Search Popup
====================================================================

***/

.search-popup{
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 99999;
	margin-top: -540px;
	transform: translateY(-100%); 
    transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.search-popup .color-layer{
	position: absolute;
	content:'';
	left: 0;
	top: 0;
	height: 120px;
	width: 100%;
	box-shadow:0px 15px 15px rgba(0,0,0,0.07);
	background-color: rgba(255,255,255,1);
    transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sidenav-bar-visible .search-popup{
	width: 80%;
}

.search-active .search-popup{
	transform: translateY(0%);
	margin-top:var(--margin-zero);
}

.search-popup .close-search{
	position: absolute;
	right: 25px;
	top: 25px;
	cursor:pointer;
	background:none;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	opacity: 0;
	z-index:9999;
	visibility: hidden;
}

.search-popup .close-search span{
	position: relative;
	display: block;
	z-index:2;
	font-weight:800;
	font-size:var(--font-20);
	color: var(--black-color);
	font-family: "Flaticon";
}

.search-active .search-popup .close-search{
	visibility: visible;
	opacity: 1;
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.search-popup form{
	position: absolute;
	max-width: 1300px;
	top: 25px;
	left: 15px;
	right: 15px;
	z-index:999;
	margin:0px auto 0;
	transform: scaleX(0);
	transform-origin: center;
	background-color: var(--dark-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.search-active .search-popup form{
	transform: scaleX(1);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.search-popup .form-group{
	position:relative;
	margin:var(--margin-zero);
	overflow: hidden;
}

.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"]{
	position:relative;
	display:block;
	font-size:var(--font-16);
	line-height: 50px;
	color:var(--color-two);
	height:70px;
	width:100%;
	padding: 10px 30px 10px 60px;
	background-color: var(--white-color);
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
	font-weight:500;
	text-transform:capitalize;
	border-bottom:1px solid rgba(0,0,0,0.10)
}

.search-popup .form-group input[type="submit"],
.search-popup .form-group button{
	position:absolute;
	left:10px;
	top:0px;
	line-height: 70px;
	background: transparent;
	text-align:center;
	font-size:var(--font-24);
	color:var(--black-color);
	cursor:pointer;
	font-weight:800;
	display:inline-block;
	font-family: 'Font Awesome 6 Free';
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover{
	color: var(--black-color);
}

.search-popup input::placeholder,
.search-popup textarea::placeholder{
	color:var(--color-two);
}

.search-popup .close-search.style-two{
	position: absolute;
	right: 25px;
	left: auto;
	color: var(--white-color);
	width:auto;
	height:auto;
	top:25px;
	margin:0px;
	border:none;
	background:none !important;
	box-shadow:none !important;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.search-popup .close-search.style-two span{
	font-size:var(--font-20);
	color: var(--white-color);
}

.main-header .sticky-header .search-box-btn{
	color: var(--color-two);
}

.main-header .sticky-header .outer-box .nav-btn{
	color: var(--color-two);
}

.main-header .mobile-menu .menu-box .mCSB_scrollTools{
	width:3px;
}

/*** 

====================================================================
	Hidden Sidebar style
====================================================================

***/

.sidebar-info-contents{
	position:relative;
	height:100%;
}

.sidebar-textwidget,
.sidebar-widget-container{
	height:100%;
}

.sidebar-info-contents .content-inner{
	height:100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.sidebar-info-contents .content-inner h5{
	margin:15px 20px;
	font-weight:400;
	color:var(--black-color);
	text-transform:capitalize;
}

.sidebar-info-contents .content-inner h5 span{
	position:relative;
	font-weight: 600;
}

.sidebar-info-contents .price{
	position:relative;
	padding:14px 10px;
	text-align:center;
	font-weight:400;
	color:var(--white-color);
	font-size:var(--font-14);
	background-color:var(--main-color);
}

.sidebar-info-contents .empty-cart-box{
	position:relative;
	margin:15px 0px;
	/* display: flex;
    align-items: center;
    justify-content: center; */
}

.sidebar-info-contents .no-cart{
	position:relative;
	text-align:center;
	color:var(--black-color);
	font-size:var(--font-16);
}

.sidebar-info-contents .no-cart .icon{
	position:relative;
	width:55px;
	height:55px;
	display:block;
	margin:0 auto;
	line-height:57px;
	text-align:center;
	border-radius:50px;
	font-size:var(--font-18);
	color:var(--white-color);
	background-color:var(--main-color);
	margin-bottom:var(--margin-bottom-20);
	font-family: 'Font Awesome 6 Free';
}

.sidebar-info-contents .lower-box h5{
	padding:25px 20px;
	margin:0px;
}
	
.sidebar-info-contents .lower-box{
	position:relative;
	border-top:1px solid rgba(var(--black-color-rgb), 0.10);
}

.sidebar-info-contents .lower-box:before{
	position:absolute;
	content:'';
	right:0px;
	top:0px;
	width:10px;
	bottom:0px;
	z-index:100;
	background-color:var(--white-color);
}

/* Post Block */

.post-block{
	position:relative;
	padding:0px 10px;
	margin-bottom:var(--margin-bottom-10);
}

.post-block .inner-box{
	position:relative;
	min-height:144px;
	padding-right:var(--padding-right-10);
	padding-left:var(--padding-left-120);
}

.post-block .rating{
	position:relative;
	color:var(--color-six);
	font-size:var(--font-12);
	margin-top: var(--margin-top-5);
}

.post-block .inner-box .image{
	position:absolute;
	left:0px;
	top:0px;
	width:106px;
	height:144px;
}

.post-block .inner-box h6{
	font-weight:700;
	font-size:var(--font-16);
	text-transform: capitalize;
}

.post-block .inner-box h6 a{
	position:relative;
	color:var(--color-two);
}

.post-block .inner-box h6 a:hover{
	color:var(--black-color);
}

.post-block .inner-box .price-box{
	position:relative;
	font-weight:500;
	color:var(--color-two);
	font-size:var(--font-16);
	margin-top:var(--margin-top-5);
}

.post-block .inner-box .bag-btn{
	position:relative;
	display:block;
	padding:10px 10px;
	border-radius:4px;
	font-weight:700;
	text-align:center;
	letter-spacing:1px;
	font-size:var(--font-12);
	color:var(--white-color);
	text-transform:uppercase;
	margin-top:var(--margin-top-15);
	background-color:var(--main-color);
}

.post-block .inner-box .bag-btn:hover{
	color:var(--white-color);
	background-color:var(--color-two);
}

.xs-sidebar-widget::-webkit-scrollbar,
.xs-sidebar-widget-two::-webkit-scrollbar,
.about-sidebar .sidebar-inner::-webkit-scrollbar{
  width: 8px;
}

/* Track */
.xs-sidebar-widget::-webkit-scrollbar-track,
.xs-sidebar-widget-two::-webkit-scrollbar-track,
.about-sidebar .sidebar-inner::-webkit-scrollbar-track{
  background: #dddddd;
}

/* Handle */
.xs-sidebar-widget::-webkit-scrollbar-thumb,
.xs-sidebar-widget-two::-webkit-scrollbar-thumb,
.about-sidebar .sidebar-inner::-webkit-scrollbar-thumb {
  background-color:var(--main-color);
}

/* Handle on hover */
.xs-sidebar-widget::-webkit-scrollbar-thumb:hover,
.xs-sidebar-widget-two::-webkit-scrollbar-thumb:hover,
.about-sidebar .sidebar-inner::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Close Side Widget */

.close-side-widget{
	width:20px;
	height:20px;
	display:inline-block;
	color:var(--black-color);
	font-size:var(--font-30);
}

.close-side-widget:hover{
	color:var(--main-color);
}

.close-side-widget:before,
.close-side-widget:after {
    content: '';
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    -webkit-transition: background-position 0.7s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    transition: background-position 0.7s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    background: linear-gradient(90deg, currentColor 0 36%, transparent 36% 56%, currentColor 56% 100%);
    background-size: 270% 100%;
    background-position-x: 100%;
}

.close-side-widget:before {
    top: 14px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.close-side-widget:after {
    top: 13.5px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    /* transform: rotate(-45deg); */
    transition-delay: .1s,0s,0s;
}

.close-side-widget:hover:before,
.close-side-widget:hover:after {
    background-position-x: 0;
    -webkit-transition: background-position 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition: background-position 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.close-side-widget:before {
    top: 14px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.close-side-widget:hover:after {
    transition-delay: .15s,0s,0s;
}

.close-side-widget:hover:before,
.close-side-widget:hover:after {
    background-position-x: 0;
    -webkit-transition: background-position 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition: background-position 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}



/* About Sidebar */

.about-sidebar{
	position:fixed;
	left:-500px;
	top:0px;
	bottom:0px;
	width:450px;
	z-index:999999;
	-webkit-transition:all 900ms ease;
	-ms-transition:all 900ms ease;
	-o-transition:all 900ms ease;
	-moz-transition:all 900ms ease;
	transition:all 900ms ease;
	background-color:var(--white-color);
}

.about-sidebar .gradient-layer{
	position:absolute;
	left:0%;
	top:0px;
	bottom:0px;
	width:0%;
	opacity:0.60;
	z-index:-1;
	background-color:var(--black-color);
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
	cursor: url(../images/cross-out.png), pointer;
}

.about-sidebar.active .gradient-layer{
	width:500%;
	left:100%;
}

.about-sidebar.active{
	left:0px;
}

.about-sidebar .fa{
	position:absolute;
	left:20px;
	top:20px;
	z-index:10;
	cursor:pointer;
	font-size:var(--font-30);
	color:var(--black-color);
	font-family: 'Font Awesome 6 Free';
}

.about-sidebar .sidebar-inner{
	position:relative;
	overflow-y: auto;
	height:100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.about-sidebar .content-box{
	position:relative;
	padding:30px 50px;
}

.about-sidebar h3{
	font-weight:400;
	color:var(--black-color);
	margin-bottom:var(--margin-bottom-15);
}

.about-sidebar h3 span{
	font-weight:700;
}

.about-sidebar .text{
	line-height:30px;
	color:var(--color-three);
	margin-top:var(--margin-top-10);
	margin-bottom:var(--margin-bottom-20);
}

.about-sidebar .social-box{
	position:relative;
	margin-left:var(--margin-left-50);
	margin-bottom:var(--margin-bottom-35);
}

.about-sidebar .social-box a{
	position:relative;
	margin-right:15px;
	color:var(--color-three);
	font-size:var(--font-18);
}

.about-sidebar .social-box a:hover{
	color:var(--color-four);
}

.about-sidebar .sidebar-inner::-webkit-scrollbar{
  width: 8px;
}

/* Track */
.about-sidebar .sidebar-inner::-webkit-scrollbar-track{
  background: #dddddd;
}

/* Handle */
.about-sidebar .sidebar-inner::-webkit-scrollbar-thumb {
  background-color:var(--main-color);
}

/* Handle on hover */
.about-sidebar .sidebar-inner::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* mobailmenu */

.mobile-menu {
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right: 30px;
	max-width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .nav-logo {
	position: relative;
	padding: 20px 20px;
	text-align: left;
}

.mobile-menu .nav-logo img {
	max-width: 200px;
}

.mobile-menu-visible .mobile-menu {
	opacity: 1;
	visibility: visible;
}

.mobile-menu .menu-backdrop {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.90);
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.7s ease;
	-moz-transition: all 0.7s ease;
	-ms-transition: all 0.7s ease;
	-o-transition: all 0.7s ease;
	transition: all 0.7s ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .menu-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #000000;
	padding: 0px 0px;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	border-radius: 0px;
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.7s ease 500ms;
	-moz-transition: all 0.7s ease 500ms;
	-ms-transition: all 0.7s ease 500ms;
	-o-transition: all 0.7s ease 500ms;
	transition: all 0.7s ease 500ms;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .close-btn {
	position: absolute;
	right: 15px;
	top: 15px;
	line-height: 30px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 14px;
	background: #000000;
	color: #ffffff;
	cursor: pointer;
	z-index: 10;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.mobile-menu-visible .mobile-menu .close-btn {
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
}

.mobile-menu .close-btn:hover {
	background: #ff0300;
	color: #fff;
}

.mobile-menu .navigation {
	position: relative;
	display: block;
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.10);
	/* padding-top: 70px; */
}

.mobile-menu .navigation li {
	position: relative;
}

.mobile-menu .navigation li>ul>li:last-child {
	border-bottom: none;
}

.mobile-menu .navigation li>ul>li:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation .MuiList-root {
	padding: 0;
}

.mobile-menu .navigation .MuiList-root li a,
.mobile-menu .navigation .MuiList-root li p,
.mobile-menu .navigation .MuiList-root a {
	position: relative;
	display: block;
	line-height: 24px;
	padding: 12px 20px;
	font-size: 15px;
	color: #ffffff;
	text-transform: capitalize;
	display: block;
	border-bottom: 1px solid rgba(0, 0, 0, 0.10);
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	margin-bottom: 0;
}

.mobile-menu .navigation .MuiList-root li a i {}

.mobile-menu .navigation li:hover>a,
.mobile-menu .navigation li.current>a {
	color: #000000;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 44px;
	height: 44px;
	text-align: center;
	font-size: 16px;
	line-height: 44px;
	color: #404040;
	cursor: pointer;
	z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after {
	content: '';
	position: absolute;
	left: 0px;
	top: 10px;
	width: 1px;
	height: 24px;
	border-left: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul {
	display: none;
}


.fixed-navbar.active .main-header {
	position: fixed;
	z-index: 555;
	top: 0;
	left: 0;
	width: 100%;
	background: #000000;
	border: none;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.fixed-navbar.active .header-two {
	background: #fff;
	padding-top: 0;
}

.fixed-navbar.active .header-two .header-top{
	height: 0;
}

.fixed-navbar.active .header-two .header-upper .inner-container{
	border: 0;
}

/* .fixed-navbar.active .main-header  .logo-box{
	padding: 0;
} */

.fixed-navbar.active .main-header  .logo-box .logo{
	max-width: 200px;
}