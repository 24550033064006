/* Conat Events Business HTML-5 Template */

/*** 

====================================================================
	Attachment Css Files
====================================================================

====================================================================
	Main Slider Section
====================================================================

***/
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}
.slider-one {
	position: relative;
	height: 760px;
}

.slider-one_slide {
	position: relative;
	overflow: hidden;
	background-size: cover;
	padding: 280px 0px 400px;
	background-size: cover;
	height: 760px;
}

@media(max-width:1023px) {
	.slider-one {
		height: 600px;
	}

	.slider-one_slide {
		height: 600px;
		padding: 100px 0px;
	}
}

@media(max-width:991px) {
	.slider-one {
		height: 600px;
	}

	.slider-one_slide {
		height: 600px;
	}
}

.slider-one_image-layer:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.70;
	z-index: 1;
	background: url(../images/main-slider/homepage-banner.jpg);
	background-size: cover;
}

.slider-one_pattern {
	position: absolute;
	right: -50px;
	top: 0px;
	z-index: 2;
	width: 375px;
	height: 765px;
	background-repeat: no-repeat;
}

.slider-one_pattern-two {
	position: absolute;
	right: 0px;
	z-index: 2;
	width: 100%;
	height: 472px;
	bottom: -130px;
	background-position: center center;
	background-repeat: repeat-x;
	/* animation: 35s linear 0s infinite normal none running slide; */
}

/* @keyframes slide{
	from { background-position: 0 0; }
    to { background-position: 3000px 0; }
} */

.slider-one_image-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	background-size: cover;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 6000ms linear;
	-moz-transition: all 6000ms linear;
	-ms-transition: all 6000ms linear;
	-o-transition: all 6000ms linear;
	transition: all 6000ms linear;
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_image-layer {
	-webkit-transform: scale(1.15);
	-ms-transform: scale(1.15);
	transform: scale(1.15);
}

.slider-one_content {
	position: relative;
	z-index: 1;
}

.titlrk{
	margin-top: -20px;
	margin-left: 20px;
}
.slider-one_content-inner {
	position: relative;
	margin-left: -125px;
	margin-top: -91px;
	padding-left: var(--padding-left-60);
}

.slider-one_date {
	position: relative;
	font-weight: 700;
	line-height: 44px;
	color: var(--white-color);
	font-size: var(--font-40);
	padding-left: var(--padding-left-130);

	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.slider-one_date span {
	position: absolute;
	left: 0px;
	top: -8px;
	line-height: 1em;
	font-weight: 700;
	color: var(--main-color);
	font-size: var(--font-100);
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_date {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.slider-one_title {
	position: absolute;
	left: -120px;
	top: 150px;
	opacity: 0;
	font-weight: 800;
	font-size: var(--font-18);
	color: var(--white-color);
	transform: rotate(-90deg);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	text-transform: uppercase;
	margin-bottom: var(--margin-bottom-20);
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_title {
	opacity: 1;
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.slider-one_heading {
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	color: var(--white-color);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-top: var(--margin-top-30);
	margin-bottom: var(--margin-bottom-50);
}

.slider-one_heading span {
	color: var(--color-four);
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_heading {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.slider-one .slider-one_buttons {
	opacity: 0;
	margin-top: 120px;
	margin-left: 70px;
	transform: scaleY(0);
	transform-origin: top;
	display: inline-block;
	-webkit-transition: all 100ms ease;
	-moz-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
}

.slider-one .slider-one_buttons .theme-btn {
	margin-right: var(--margin-right-15);
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_buttons {
	opacity: 1;
	transform: scaleY(1);
	transition-delay: 1200ms;
}

.slider-one .owl-dots {
	display: none;
}

.slider-one:hover .owl-nav {
	opacity: 1;
}

.slider-one .slick-arrow {
	position: absolute;
	right: 80px;
	left: auto;
	top: 50%;
	opacity: 0;
	z-index: 3;
	margin-top: -130px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.slider-one .slick-prev,
.slider-one .slick-next {
	display: none !important;
	width: 55px;
	height: 55px;
	line-height: 70px;
	margin: 10px 0px;
	border-radius: 50px;
	text-align: center;
	color: var(--color-two);
	font-size: var(--font-20);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: rgba(var(--white-color-rgb), 0.70);
	opacity: 1;
	font-size: 0;
}

.slider-one .slick-prev {
	margin-top: -6px;
	top: 44%;
}

@media(max-width:1023px) {
	.slider-one .slick-prev {
		top: 42%;
	}

	.slider-one .slick-next {
		top: 52%;
	}


	.slider-one .slick-arrow {
		right: 50px;
	}

}

@media(max-width:767px) {
	.slider-one .slick-prev {
		display: none!important;
	}

	.slider-one .slick-next {
		display: none!important;
	}

}

.slider-one .slick-prev:before {
	content: "\e629";
	font-family: "themify";
	line-height: normal;
	color: var(--color-two);
}

.slider-one .slick-next:before {
	content: "\e628";
	font-family: "themify";
	line-height: normal;
	color: var(--color-two);
}

.slider-one .slick-prev:hover,
.slider-one .slick-next:hover {
	color: var(--main-color);
	background-color: #000000;
}

/*** 

====================================================================
	About One
====================================================================

***/

.about-one {
	position: relative;
	padding: 39px 0px 100px;
}

.about-one.style-two {
	padding-top: var(--padding-top-120);
}

.about-one_pattern-one {
	position: absolute;
	left: 0px;
	top: 240px;
	width: 69px;
	height: 194px;
	background-repeat: no-repeat;
}

.mob {
    display: none;
}

.desk {
    display: block;
}
.about-one_pattern-two {
	position: absolute;
	right: 0px;
	top: 370px;
	width: 69px;
	height: 194px;
	background-repeat: no-repeat;
}

.about-one_pattern-three {
	position: absolute;
	right: 2%;
	top: 15%;
	width: 112px;
	height: 115px;
	background-repeat: no-repeat;
}

.about-one_image-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.about-one_image-inner {
	position: relative;
	padding-right: var(--padding-right-70);
}

.about-one_image {
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	margin-left: -110px;
	display: inline-block;
	border: 15px solid #c99c18;
}

.about-one_pattern-four {
	position: absolute;
	left: -200px;
	top: -50px;
	right: 0px;
	bottom: -50px;
	background-repeat: no-repeat;
}

/* Video One Play */

.slider-one_play {
	position: absolute;
	right: 100px;
	top: 100px;
	width: 130px;
	height: 130px;
	text-align: center;
	border-radius: 50%;
	line-height: 130px;
	border: 10px solid var(--white-color);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.slider-one_play span {
	position: relative;
	width: 110px;
	height: 110px;
	z-index: 10;
	text-align: center;
	display: block;
	line-height: 110px;
	border-radius: 50%;
	font-size: var(--font-28);
	color: var(--white-color);
	padding-left: var(--padding-left-5);
	background-color: #c99c18;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.slider-one_play span:hover {
	color: var(--white-color);
	background-color: #c88c19;
}

.slider-one_play .ripple,
.slider-one_play .ripple:before,
.slider-one_play .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 130px;
	height: 130px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50% 50% 50% 50%;
	-moz-border-radius: 50% 50% 50% 50%;
	-ms-border-radius: 50% 50% 50% 50%;
	-o-border-radius: 50% 50% 50% 50%;
	border-radius: 50% 50% 50% 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	-moz-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	-ms-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	-o-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	box-shadow: 0 0 0 0 rgba(66, 48, 90, .3);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.slider-one_play .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.slider-one_play .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

@-webkit-keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);
	}
}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);
	}
}

.about-one_content-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.about-one_content-column .sec-title {
	margin-bottom: var(--margin-bottom-20);
}

.about-one_content-inner {
	position: relative;
}

.about-one_bold-text {
	position: relative;
	font-weight: 700;
	line-height: 36px;
	color: var(--color-two);
	font-size: var(--font-20);
	margin-bottom: var(--margin-bottom-20);
}

.about-one_text {
	position: relative;
	line-height: 30px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-40);
}

.about-one_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.about-one_block-inner {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-65);
}



.about-one_block-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	line-height: 1em;
	color: rgb(200 140 25);
	font-size: var(--font-50);
	font-family: "Flaticon";
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.about-one_block-inner:hover .about-one_block-icon {
	transform: rotateY(180deg);
}

.about-one_block strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-20);
	margin-bottom: var(--margin-bottom-10);
}

.about-one_button {
	position: relative;
	margin-top: var(--margin-top-10);
}

/*** 

====================================================================
	Counter One
====================================================================

***/

.counter-one {
	position: relative;

}

.counter-one .inner-container {
	position: relative;
	padding: 90px 0px 0px;
}

.counter-one_image {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 200%;
	overflow: hidden;
	border-radius: 0px 100px 0px 0px;
	background-size: cover;
}

.counter-one_image::before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.80;
	background-color: rgb(25 103 234 / 0%);
}

.counter-one_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.counter-one_inner {
	position: relative;
}

.counter-one_counter h4{
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	font-size: 50px;
}

.counter-one_text {
	display: block;
	position: relative;
	color: var(--white-color);
	font-size: 22px;
	/* margin-top: var(--margin-top-10); */
}

/*** 

====================================================================
	Speaker One
====================================================================

***/

.speakers-one {
	position: relative;
	overflow: hidden;
}
.speakers-on{
	padding: 110px 0px 50px;
}
.speakers-onn{
	padding: 10px 0px 50px;
}
.business-on{
	margin-top: 120px;
}
.speakers-one_pattern {
	position: absolute;
	right: -200px;
	top: 90px;
	width: 475px;
	height: 460px;
	background-repeat: no-repeat;
}

.speakers-one_pattern-two {
	position: absolute;
	left: -40px;
	top: 64%;
	width: 112px;
	height: 115px;
	background-repeat: no-repeat;
}

.speaker-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-40);
}

.speaker-block_one-inner {
	position: relative;
}

.speaker-block_one-image {
	position: relative;
	width: 270px;
	margin: auto;
	height: 280px;
	padding: 5px 5px;
	border-radius: 0px 50% 50% 50%;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.speaker-block_one-image img {
	position: relative;
	border-radius: 0px 50% 50% 50%;
}

.speaker-block_one-image::before {
	position: absolute;
	content: '';
	left: 10px;
	top: 10px;
	right: 5px;
	bottom: -15px;
	border-radius: 0px 50% 50% 50%;
	border: 2px dashed var(--color-two);
}

.speaker-block_one-social {
	position: absolute;
	left: 20px;
	top: 20px;
	z-index: 2;
	bottom: 20px;
	overflow: hidden;
	border-radius: 50px;
}

.speaker-block_one-share {
	position: relative;
	width: 40px;
	height: 40px;
	z-index: 2;
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--white-color);
	background-color: var(--main-color);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.speaker-block_one-social-list {
	position: absolute;
	left: 0px;
	top: -100%;
	width: 40px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.speaker-block_one-inner:hover .speaker-block_one-social-list {
	top: 50px;
}

.speaker-block_one-social-list a {
	position: relative;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50px;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--black-color);
	background-color: var(--white-color);
	margin-bottom: var(--margin-bottom-5);
}

.speaker-block_one-social-list a.youtube {
	color: #ff0000;
}

.speaker-block_one-social-list a.twitter {
	color: #1da1f2;
}

.speaker-block_one-social-list a.facebook {
	color: #4267b2;
}

.speaker-block_one-social-list a.instagram {
	color: #e73a67;
}

.speaker-block_one-pattern {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -30px;
	height: 129px;
	background-position: center center;
	background-repeat: no-repeat;
}

.speaker-block_one-content {
	position: relative;
	text-align: center;
	padding-top: var(--padding-top-45);
}

.speaker-one_title {
	position: relative;
	font-weight: 700;
}

.speaker-one_title a {
	position: relative;
	color: var(--color-two);
}

.speaker-one_title a:hover {
	color: var(--main-color);
}

.speaker-one_detail {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-10);
}

/*** 

====================================================================
	Feature One
====================================================================

***/

.feature-one {
	position: relative;
	padding: 110px 0px 80px;
	background-color: rgb(0 0 0);
}
input#email::placeholder {
    color: #000;
}
.feature-one_pattern {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 461px;
	background-position: center right;
	background-repeat: no-repeat;
}

.feature-one_pattern-two {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 461px;
	background-position: center right;
	background-repeat: no-repeat;
}

.feature-one_pattern-three {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 381px;
	background-position: center right;
	background-repeat: no-repeat;
}

.feature-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.feature-block_one-inner {
	position: relative;
	padding-right: 77px;
	/* padding-right: var(--padding-right-50); */
	text-align: center;
}

.feature-block_one:last-child .feature-block_one-inner::before {
	display: none;
}

.feature-block_one-inner::before {
	position: absolute;
	content: '';
	right: 40px;
	top: 0px;
	width: 2px;
	bottom: 0px;
	background-color: var(--white-color);
}

.feature-block_one-icon::after {
	position: absolute;
	content: '';
	left: 0px;
	top: -10px;
	width: 88px;
	height: 93px;
	z-index: -1;
		/* background: url(../images/icons/feature-bg.png) no-repeat; */
}

.feature-block_one-icon {
	position: relative;
	line-height: 1em;
	z-index: 1;
	display: inline-block;
	font-size: var(--font-60);
	color: #fff;
	font-family: "Flaticon";
}

.feature-block_one-inner:hover .feature-block_one-icon {
	animation: icon-bounce 0.8s ease-out;
	-webkit-animation: icon-bounce 0.8s ease-out;
}

.feature-block_one-title {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-35);
}

.feature-block_one-title a {
	position: relative;
	color: var(--color-two);
}

.feature-block_one-title a:hover {
	color: #c88c19;
}

.feature-block_one-more {
	position: relative;
	font-weight: 700;
	display: inline-block;
	color: var(--main-color);
	margin-top: var(--margin-top-20);
}

/*** 

====================================================================
	Leadership One
====================================================================

***/

.leadership-one {
	position: relative;
	overflow: hidden;
	padding: 110px 0px 110px;
}

.leadership-one_icon {
	position: absolute;
	left: 10%;
	top: 10%;
	width: 40px;
	height: 59px;
	background-repeat: no-repeat;
}

.leadership-one_icon-two {
	position: absolute;
	right: 20%;
	top: 10%;
	width: 189px;
	height: 135px;
}

.leadership-one_icon-three {
	position: absolute;
	left: 10%;
	top: 45%;
	width: 104px;
	height: 66px;
}

.leadership-one_icon-four {
	position: absolute;
	left: 15%;
	top: 85%;
	width: 110px;
	height: 111px;
}

.leadership-one_icon-five {
	position: absolute;
	right: -80px;
	top: 5%;
	width: 50%;
	height: 100%;
	background-position: right top;
	background-repeat: no-repeat;
}

/*** 

====================================================================
	Product Tabs Style
====================================================================

***/

.leadership-tabs {
	position: relative;
	margin-top: var(--margin-top-30);
}

.leadership-tabs .tab-btns {
	position: relative;
}

.btn-style-onee {
    position: relative;
    font-weight: 700;
    overflow: hidden;
    text-align: center;
    border-radius: 0px;
    padding: 18px 44px;
    letter-spacing: 2px;
    display: inline-block;
    color: var(--color-two);
    font-size: var(--font-12);
    text-transform: uppercase;
    background-color: var(--main-color);
}

.leadership-tabs .tab-btns .tab-btn .nav-link{
	position: relative;
	line-height: 24px;
	padding: 8px;
	font-weight: 500;
	cursor: pointer;
	display: inline-block;
	color: var(--color-five);
	font-size: var(--font-14);
	text-transform: capitalize;
	transition: all 500ms ease;
	/* padding: 22px 80px 22px 35px; */
	margin-right: var(--margin-right-10);
	background: url(../images/background/leadership-1.png) left center no-repeat;
}

.leadership-tabs .tab-btns .tab-btn a{
	padding: 0;
	background: none;
	border: 0;
}

.leadership-tabs .tab-btns .tab-btn span {
	position: relative;
	display: block;
	font-weight: 700;
	font-size: var(--font-18);
	text-transform: uppercase;
	color: var(--color-two);
	margin-top: var(--margin-top-5);
}

.leadership-tabs .tab-btns .tab-btn:nth-child(2) .nav-link{
	background: url(../images/background/leadership-2.png) left center no-repeat;
}

.leadership-tabs .tab-btns .tab-btn:nth-child(3) a{ 
	color: var(--white-color);
}

.leadership-tabs .tab-btns .tab-btn:nth-child(3) .nav-link{
	color: var(--white-color);
	background: url(../images/background/leadership-3.png) left center no-repeat;
}

.leadership-tabs .tab-btns .tab-btn:nth-child(3) span {
	color: var(--white-color);
}

.leadership-tabs .tab-btns .tab-btn:nth-child(4) .nav-link{
	color: var(--white-color);
	background: url(../images/background/leadership-4.png) left center no-repeat;
}


.leadership-tabs .tab-btns .tab-btn:nth-child(4) a{ 
	color: var(--white-color);
}


.leadership-tabs .tab-btns .tab-btn:nth-child(4) span {
	color: var(--white-color);
}

.leadership-tabs .tab-btns .tab-btn:hover,
.leadership-tabs .tab-btns .tab-btn.active-btn {}

.leadership-tabs .tab-btns .tab-btn:last-child {
	margin-right: var(--margin-zero);
}

.leadership-tabs .tabs-content {
	position: relative;
	padding: 40px 0px 0px;
}

.leadership-tabs .tab-btns {
	border: 0;
}

.leadership-tabs .tab-btns a{
    color: var(--color-five);
}

.leadership-tabs .tabs-content .tab {
	position: relative;
	display: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: translateY(80px);
}

.leadership-tabs .tabs-content .tab.active-tab {
	display: block;
	transform: translateY(0px);
}

.leadership-one_block {
	position: relative;
}

.leadership-one_block-inner:hover {
	border-color: rgba(255, 195, 0, 0.30);
	box-shadow: inset 0px 0px 15px rgba(255, 195, 0, 0.30);
}

.leadership-one_block-inner {
	position: relative;
	padding: 60px 60px;
	background-color: rgb(0 0 0);
    border: 3px solid #c99c18;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.leadership-one_block-content {
	position: relative;
	padding-left: 260px;
}

.leadership-one_block-image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 210px;
	height: 210px;
	padding: 5px 5px;
	border-radius: 50% 50% 0% 50%;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.leadership-one_block-image img {
	position: relative;
	overflow: hidden;
	border-radius: 50% 50% 0% 50%;
}

.leadership-one_block-icon {
	position: absolute;
	right: -20px;
	bottom: 25px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: var(--font-20);
	color: var(--color-three);
	font-family: "Flaticon";
	background-color: var(--color-seven);
}

.leadership-one_block-time {
	position: relative;
	font-weight: 700;
	color: #c88c19;
	font-size: var(--font-16);
}

.leadership-one_block-name {
	position: relative;
	font-weight: 700;
	color: #c99C18;
	font-size: var(--font-20);
	margin-top: var(--margin-top-15);
}

.leadership-one_block-title {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-15);
}

.leadership-one_block-title a {
	position: relative;
	color: var(--color-two);
}

.leadership-one_block-title a:hover {
	color: #c88c19;
}

.leadership-one_block-text {
	position: relative;
	line-height: 34px;
	color: var(--color-five);
	font-size: var(--font-18);
	margin-top: var(--margin-top-25);
}

.leadership-one_block.style-two .leadership-one_block-content {
	padding-right: 260px;
	padding-left: var(--padding-zero);
}

.leadership-one_block.style-two .leadership-one_block-image {
	right: 0px;
	left: auto;
	border-radius: 50% 50% 50% 0%;
}

.leadership-one_block.style-two .leadership-one_block-image img {
	border-radius: 50% 50% 50% 0%;
}

.leadership-one_block.style-two .leadership-one_block-icon {
	left: -20px;
	right: auto;
}

/*** 

====================================================================
	Gallery One
====================================================================

***/

.gallery-one {
	position: relative;
}

.gallery-one .gallery-one_block {
	float: left;
	padding: var(--padding-zero);
}

.gallery-one_block {
	position: relative;
}

.gallery-one_block-inner {
	position: relative;
}

.gallery-one_block-image {
	position: relative;
	/* cursor: pointer; */
}

.gallery-one_block-image img {
	position: relative;
	width: 100%;
	display: block;
}

.gallery-one_block-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #c99c1873;

	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
}

.gallery-one_block-inner:hover .gallery-one_block-overlay {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;
}

.gallery-one_block-icon {
	position: relative;
	font-size: var(--font-40);
	color: var(--white-color);
	font-family: "Flaticon";
}

.gallery-one_block-icon:hover {
	color: var(--color-three);
}

/*** 

====================================================================
	Price One
====================================================================

***/

.price-one {
	position: relative;
	padding: 110px 0px 80px;
}

.price-one_pattern {
	position: absolute;
	left: -260px;
	top: 90px;
	width: 475px;
	height: 460px;
	background-repeat: no-repeat;
}

.price-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.price-block_one-inner {
	position: relative;
	padding: 100px 50px 70px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.price-block_one-pattern {
	position: absolute;
	right: 0px;
	top: 0px;
	left: 0px;
	height: 167px;
	background-repeat: no-repeat;
}

.price-block_one-pattern-two {
	position: absolute;
	left: 0px;
	bottom: 15px;
	width: 199px;
	height: 49px;
	background-repeat: no-repeat;
}

.price-block_one-title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-14);
}

.price-block_one-price {
	position: relative;
	font-weight: 700;
	line-height: 1em;
	color: var(--color-four);
	font-size: var(--font-72);
	margin-top: var(--margin-top-10);
}

.price-block_one-price span {
	position: relative;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
	color: var(--color-five);
}

.price-block_one-options {
	position: relative;
	margin-top: var(--margin-top-10);
}

.price-block_one-options li {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-20);
}

.price-block_one-options li strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-5);
}

.price-block_one-options li.light {
	opacity: 0.70;
}

.price-block_one-options li:last-child {
	margin-bottom: var(--margin-zero);
}

.price-block_one-button {
	position: relative;
	margin-top: var(--margin-top-30);
}

.price-block_one-charges {
	position: relative;
	font-weight: 600;
	color: var(--color-two);
	font-size: var(--font-14);
	margin-top: var(--margin-top-20);
}

.price-block_one:nth-child(2) .price-block_one-price {
	color: var(--color-three);
}

.price-block_one:nth-child(2) .price-block_one-button .theme-btn {
	background-color: var(--color-three);
}

.price-block_one:nth-child(3) .price-block_one-price {
	color: var(--main-color);
}

.price-block_one:nth-child(3) .price-block_one-button .theme-btn {
	background-color: var(--main-color);
}

/*** 

====================================================================
	Video One
====================================================================

***/

.video-one {
	position: relative;
	padding: 120px 0px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-attachment: fixed;
	background-size: cover;
}

.video-one_pattern-one {
	position: absolute;
	left: 0px;
	top: 100px;
	width: 148px;
	height: 210px;
	background-repeat: no-repeat;
}

.video-one_pattern-two {
	position: absolute;
	left: 110px;
	bottom: 15%;
	width: 104px;
	height: 66px;
	background-repeat: no-repeat;
}

.video-one_pattern-three {
	position: absolute;
	right: 50px;
	top: 15%;
	width: 189px;
	height: 135px;
	background-repeat: no-repeat;
}

.video-one_pattern-four {
	position: absolute;
	right: 50px;
	bottom: 15%;
	width: 40px;
	height: 59px;
	background-repeat: no-repeat;
}

.video-one::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.80;
	background-color: rgb(224 14 122 / 0%);
}

/* Video One Play */

.video-one_play {
	position: relative;
	width: 110px;
	height: 110px;
	margin: 0 auto;
	text-align: center;
	border-radius: 50%;
	line-height: 130px;
	display: inline-block;
	margin-top: var(--margin-top-40);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.video-one_play span {
	position: relative;
	width: 110px;
	height: 110px;
	z-index: 10;
	text-align: center;
	display: block;
	line-height: 110px;
	border-radius: 50%;
	font-size: var(--font-28);
	color: rgb(255 255 255);
    padding-left: var(--padding-left-5);
    background-color: #c99c18;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.video-one_play span:hover {
	color: var(--white-color);
	background-color: #c99c18;
}

.video-one_play .ripple,
.video-one_play .ripple:before,
.video-one_play .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 110px;
	height: 110px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50% 50% 50% 50%;
	-moz-border-radius: 50% 50% 50% 50%;
	-ms-border-radius: 50% 50% 50% 50%;
	-o-border-radius: 50% 50% 50% 50%;
	border-radius: 50% 50% 50% 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	box-shadow: 0 0 0 0 #c99c1829;
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.video-one_play .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.video-one_play .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

/*** 

====================================================================
	Clients One
====================================================================

***/

.clients-one {
	position: relative;
	padding: 50px 0px 80px;
}

.clients-one_column {
	position: relative;
	text-align: center;
	/* margin-bottom: var(--margin-bottom-30); */
}

.clients-one_column a {
	position: relative;
	display: block;
	background-color: #ffffff00;
	border: 1px solid rgba(var(--black-color-rgb), 0.07);
}

.clients-one_column a:hover {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

/*** 

====================================================================
	News One
====================================================================

***/

.news-one {
	position: relative;
	padding: 0px 0px 80px;
}

.news-block_one {
	position: relative;
	z-index: 2;
	margin-bottom: var(--margin-bottom-30);
}

.news-block_one-inner {
	position: relative;
}
.mt-10{
	margin-top: 10px;
}
.news-block_one-tag {
	position: absolute;
	right: 25px;
	top: 25px;
	z-index: 2;
	font-weight: 500;
	padding: 7px 20px;
	letter-spacing: 1px;
	color: var(--white-color);
	font-size: var(--font-14);
	background-color: var(--color-three);
}

.news-block_one-image {
	position: relative;
	overflow: hidden;
}

.news-block_one-image img:first-child {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	-webkit-transform: translatex(50%) scalex(2);
	transform: translatex(50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_one-inner:hover .news-block_one-image img:first-child {
	-webkit-transform: translatex(0) scalex(1);
	transform: translatex(0) scalex(1);
	opacity: 1;
	-webkit-filter: blur(0);
	filter: blur(0);
}

.news-block_one-inner:hover .news-block_one-image img:nth-child(2) {
	-webkit-transform: translatex(-50%) scalex(2);
	transform: translatex(-50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_one-image img {
	position: relative;
	width: 100%;
	display: block;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.news-block_one-content {
	position: relative;
	z-index: 10;
	margin-top: -50px;
	padding: 40px 30px 30px;
	background-color: var(--white-color);
	margin-right: var(--margin-right-30);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
	height: 230px;
}

.news-block_one-date {
	position: absolute;
	left: 30px;
	top: -17px;
	font-weight: 600;
	padding: 6px 15px;
	letter-spacing: 2px;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: uppercase;
	background-color: var(--main-color);
}

.news-block_one-meta {
	position: relative;
}

.news-block_one-meta li {
	position: relative;
	display: inline-block;
	color: var(--color-five);
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
}

.news-block_one-meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
	color: var(--color-four);
}

.news-block_one-meta li:last-child {
	margin-right: var(--margin-zero);
}

.news-block_one-title {
	position: relative;
	text-transform: capitalize;
	margin-top: var(--margin-top-15);
}

.news-block_one-title a {
	position: relative;
	color: #000000;
}

.news-block_one-title a:hover {
	color: #c99c18;
}

.news-block_one-text {
	position: relative;
	line-height: 28px;
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.news-block_one-more {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	display: inline-block;
	color: #c88c19;
	text-transform: capitalize;
	margin-top: var(--margin-top-15);
	padding-right: var(--padding-right-25);
}

.news-block_one-more:hover {
	color: #000000;
}

.news-block_one-more::before {
	position: absolute;
	content: '\f178';
	right: 0px;
	top: 5px;
	line-height: 1em;
	font-family: 'FontAwesome';
}

/*** 

====================================================================
	Subscribe One
====================================================================

***/

.subscribe-one {
	position: relative;
	z-index: 1;
}

.subscribe-one .inner-container {
	position: relative;
	overflow: hidden;
	padding: 60px 60px 50px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.subscribe-one_title {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	color: #c88c19;
	font-size: var(--font-16);
	text-transform: uppercase;
}

.subscribe-one_heading {
	position: relative;
	color: #000000;
	margin-top: var(--margin-top-10);
}

.subscribe-box {
	position: relative;
	margin-top: var(--margin-top-5);
}

.subscribe-box .form-group {
	position: relative;
	margin: 0px;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	/* border: 1px solid rgba(var(--black-color-rgb), 0.10); */
}

.subscribe-box .form-group input[type="text"],
.subscribe-box .form-group input[type="email"] {
	position: relative;
	line-height: 43px;
	background: none;
	display: block;
	height: 55px;
	width: 100%;
	box-shadow: inherit;
	color: var(--black-color);
	font-size: var(--font-14);
	padding: 10px 55px 10px 25px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: #ffffff;
}

.subscribe-box .form-group input::placeholder,
.subscribe-box .form-group textarea::placeholder {
	color: var(--color-five);
}

.subscribe-box .form-group button {
	position: absolute;
	right: 0px;
	top: 0px;
	font-weight: 600;
	cursor: pointer;
	line-height: 100%;
	text-align: center;
	padding: 21px 45px;
	display: inline-block;
	letter-spacing: 1px;
	font-size: var(--font-14);
	color: var(--color-two);
	text-transform: uppercase;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: var(--main-color);
}

.subscribe-box .form-group button:hover {
	color: var(--white-color);
	background-color: #cb9329;
}

.subscribe-box.style-two .form-group {
	position: relative;
	/* padding: 5px 5px; */
	border-radius: 50px;
	box-shadow: 0 0 4px 1px #bababa;
	background-color: var(--white-color);
}

.subscribe-box.style-two .form-group input {
	border: none;
}

.subscribe-box.style-two .form-group button {
	/* right: 5px;
	top: 4px; */
	color: var(--white-color);
	background-color: #000000;
}

.subscribe-box.style-three .form-group input {
	background: none;
	color: var(--white-color);
	border: 1px solid var(--white-color);
}

.subscribe-box.style-three .form-group button {
	position: relative;
	margin-top: var(--margin-top-15);
}

.subscribe-box.style-three .form-group input::placeholder,
.subscribe-box.style-three .form-group textarea::placeholder {
	color: var(--white-color);
}

.subscribe-one_pattern {
	position: absolute;
	left: 0px;
	bottom: -20px;
	width: 189px;
	height: 91px;
	background-repeat: no-repeat;
}

.subscribe-one_pattern-two {
	position: absolute;
	right: 0px;
	top: -20px;
	width: 69px;
	height: 91px;
	background-repeat: no-repeat;
}

.subscribe-one_pattern-three {
	position: absolute;
	right: 0px;
	top: -280px;
	width: 281px;
	height: 538px;
	background-repeat: no-repeat;
}

.fix {
	overflow: hidden;
}

#bd-cstm-sliders #bd-cstm-sliders__container {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	padding: 0;
	overflow: hidden;
	width: 300%;
}

#bd-cstm-sliders #bd-cstm-sliders__container .bd-cstm-slide {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 100%;
}

/*** 

====================================================================
	Slider Two
====================================================================

***/

.slider-two {
	position: relative;
	overflow: hidden;
}

.slider-two_icon-one {
	position: absolute;
	left: 2%;
	bottom: 55%;
	width: 221px;
	height: 221px;
	z-index: 1;
	opacity: 0.50;
	background-repeat: no-repeat;
}

.slider-two_icon-two {
	position: absolute;
	left: 15%;
	bottom: 40%;
	width: 250px;
	height: 250px;
	z-index: 1;
	opacity: 0.50;
	background-repeat: no-repeat;
}

.slider-two_icon-three {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 100%;
	height: 601px;
	z-index: 1;
	opacity: 0.90;
	background-position: right top;
	background-repeat: no-repeat;
}

.slider-two .owl-dots,
.slider-two .owl-nav {
	display: none;
}

.slider-two_cloud-layer {
	position: absolute;
	left: 0px;
	bottom: -10px;
	right: 0px;
	height: 531px;
	z-index: 1;
	background-repeat: repeat-x;
}

.slider-two .slide {
	position: relative;
	padding: 130px 0px 0px;
}

.slider-two_image-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.slider-two_image-layer::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.90;
	background-color: var(--color-two);
}

.slider-two_image-column {
	position: relative;
	padding-top: var(--padding-top-130);
}

.slider-two_image {
	position: relative;
}

.slider-two_image img {
	max-width: inherit !important;
	width: auto !important;
	margin: 0 auto;
}

.slider-two_content {
	position: relative;
}

.slider-two_content-inner {
	position: relative;
	padding-top: 300px;
}

.slider-two_date {
	position: relative;
	font-weight: 600;
	color: var(--white-color);
	font-size: var(--font-18);
	padding-left: var(--padding-left-35);
}

.slider-two_date::before {
	position: absolute;
	left: 0px;
	content: "\f107";
	font-weight: normal;
	font-family: "Flaticon";
	color: var(--main-color);
}

.slider-two_heading {
	color: var(--white-color);
	margin-top: var(--margin-top-30);
}

.slider-two_heading::before {
	position: absolute;
	content: '';
	left: -70px;
	bottom: -135px;
	width: 101px;
	height: 192px;
	background: url(../images/main-slider/line.png) no-repeat;
}

.slider-two_location {
	position: relative;
	font-size: var(--font-18);
	color: var(--white-color);
	margin-top: var(--margin-top-30);
	padding-left: var(--padding-left-35);
}

.slider-two_location::before {
	position: absolute;
	left: 0px;
	content: "\f13e";
	font-weight: normal;
	font-family: "Flaticon";
	color: var(--main-color);
}

.slider-two_button {
	position: relative;
	margin-top: var(--margin-top-40);
	margin-left: var(--margin-left-50);
}

.slider-two_timer {
	position: absolute;
	right: 50px;
	bottom: 30px;
	z-index: 10;
}

.slider-two .time-countdown {
	position: relative;
}

.slider-two .time-countdown .react-countdown .time-section {
	position: relative;
	z-index: 7;
	line-height: 1em;
	font-weight: 600;
	text-align: center;
	border-radius: 0px;
	margin: 0 30px 20px;
	color: var(--color-two);
	text-transform: uppercase;
	font-size: var(--font-20);
	display: inline-block;
}

.slider-two .time-countdown .react-countdown .time-section .time {
	position: relative;
	display: block;
	line-height: 1em;
	font-weight: 800;
	color: var(--color-two);
	font-size: var(--font-84);
	margin-bottom: var(--margin-bottom-10);
}

.slider-two .time-countdown .react-countdown .time-section .time-text {
	color: var(--color-two);
	text-transform: uppercase;
	font-size: var(--font-20);
	display: inline-block;
}

.slider-two .time-countdown .react-countdown .time-section::before {
	position: absolute;
	content: ':';
	right: -50px;
	top: 5px;
	line-height: 1em;
	font-weight: 800;
	color: var(--color-two);
	font-size: var(--font-100);
}

.slider-two .time-countdown .react-countdown .time-section::before {
	display: none;
}

/*** 

====================================================================
	About One
====================================================================

***/

.about-two {
	position: relative;
	overflow: hidden;
	padding: 110px 0px 50px;
}

.about-two_pattern-one {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-position: left center;
	background-repeat: no-repeat;
}

.about-two_pattern-two {
	position: absolute;
	right: 0;
	top: 0px;
	width: 853px;
	height: 624px;
	opacity: 1;
	background-position: right top;
	background-repeat: no-repeat;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}


.about-two_pattern-three {
	position: absolute;
	right: 60px;
	bottom: 60px;
	width: 97px;
	height: 97px;
	background-position: right top;
	background-repeat: no-repeat;
}

.about-two_content-column {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.about-two_content-inner {
	position: relative;
}

.about-two_options {
	position: relative;
	padding: 35px 35px;
	border-radius: 10px;
	background-color: var(--color-seven);
	margin-bottom: var(--margin-bottom-50);
	border-bottom: 3px solid var(--main-color);
}

.about-two_options-list {
	position: relative;
}

.about-two_options-list li {
	position: relative;
	line-height: 30px;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-60);
	margin-bottom: var(--margin-bottom-20);
}

.about-two_options-list li .icon {
	position: absolute;
	left: 0px;
	top: 6px;
	width: 40px;
	height: 40px;
	font-weight: 700;
	line-height: 40px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	font-family: "Flaticon";
	color: var(--color-three);
	border: 1px solid var(--color-five);
}

.about-two_options-list li:last-child {
	margin-bottom: var(--margin-zero);
}

.about-two_signature {
	position: relative;
	line-height: 1em;
	color: var(--color-four);
	font-size: var(--font-60);
	text-transform: capitalize;
	margin-right: var(--margin-right-60);
	font-family: 'Mrs Saint Delafield', cursive;
}

.about-two_author {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
}

.about-two_author span {
	position: relative;
	font-weight: 400;
	display: block;
	text-transform: capitalize;
	margin-top: var(--margin-top-5);
}

.about-two_image-column {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.about-two_image-inner {
	position: relative;
	padding-left: var(--padding-left-50);
}

.about-two_image {
	position: relative;
}

.about-two_image img {
	position: relative;
	max-width: inherit;
}

/* About Two Counter */

.about-two_counter {
	position: absolute;
	left: 50px;
	bottom: 0px;
	width: 304px;
	height: 242px;
	text-align: left;
	background-repeat: no-repeat;
	padding-left: var(--padding-left-45);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.about-two_counter-words {
	position: relative;
	font-weight: 800;
	line-height: 80px;
	font-size: var(--font-80);
	color: var(--white-color);
	padding-top: var(--padding-top-40);
}

.about-two_counter-text {
	position: relative;
	line-height: 30px;
	font-weight: 500;
	font-size: var(--font-20);
	color: var(--white-color);
}

.about-two_counter-text span {
	position: relative;
	display: block;
	text-transform: uppercase;
}

/*** 

====================================================================
	Event One
====================================================================

***/

.event-one {
	position: relative;
	z-index: 1;
}

.event-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.event-block_one-inner {
	position: relative;
	min-height: 75px;
	padding: 30px 25px 25px 0px;
}

.event-block_one-inner::before {
	position: absolute;
	content: '';
	left: 40px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 10px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.event-block_one-content {
	position: relative;
	color: var(--color-five);
	padding-left: var(--padding-left-120);
}

.event-block_one-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 75px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	border-radius: 10px;
	display: inline-block;
	color: var(--white-color);
	font-family: "Flaticon";
	font-size: var(--font-30);
	background-color: var(--main-color);
}

.event-block_one:nth-child(2) .event-block_one-icon {
	background-color: var(--color-three);
}

.event-block_one-icon:after {
	position: absolute;
	content: '';
	left: 8px;
	bottom: -16px;
	border-top: 16px solid var(--color-five);
	border-left: 32px solid transparent;
}

.event-block_one strong {
	display: block;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-18);
	text-transform: uppercase;
	margin-bottom: var(--margin-bottom-10);
}

/*** 

====================================================================
	Counter Two
====================================================================

***/

.counter-two {
	position: relative;
	overflow: hidden;
	margin-top: -95px;
	background-color: var(--color-seven);
}

.counter-two .inner-container {
	position: relative;
	padding: 180px 0px 70px;
}

.counter-two_pattern {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	background-position: center center;
	background-repeat: repeat-x;
}

.counter-two_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.counter-two_inner {
	position: relative;
	padding-left: var(--padding-left-90);
}

.counter-two_counter {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-48);
	line-height: 55px;
}

.counter-two_text {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-18);
}

.counter-two_block-icon {
	position: absolute;
	left: 0px;
	top: 7px;
	line-height: 1em;
	color: var(--color-four);
	font-size: var(--font-60);
	font-family: "Flaticon";
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.counter-two_inner:hover .counter-two_block-icon {
	transform: rotateY(180deg);
}

.counter-two_block:nth-child(2) .counter-two_block-icon {
	color: var(--main-color);
}

.counter-two_block:nth-child(3) .counter-two_block-icon {
	color: var(--color-three);
}

.counter-two_block:nth-child(4) .counter-two_block-icon {
	color: var(--color-six);
}


/*** 

====================================================================
	Event Two
====================================================================

***/
p.para {
    text-align: center;
    color: #fff;
}

.event-two {
	position: relative;
	background-attachment: fixed;
	background-size: cover;
	padding: 120px 0px 100px;
}

.event-two::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.80;
	background-color: #00000094;
}

.event-two .event-block_two {
	width: 13.6%;
	float: left;
	padding: 0px 15px;
}

.event-block_two {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.event-block_two-inner {
	position: relative;
	text-align: center;
}

.event-block_two-icon {
	position: relative;
	width: 155px;
	height: 155px;
	display: block;
	margin: 0 auto;
	line-height: 168px;
	text-align: center;
	font-family: "Flaticon";
	color: var(--white-color);
	font-size: var(--font-80);
	border: 2px solid rgba(var(--white-color-rgb), 0.50);
}

.event-block_two-inner:hover .event-block_two-icon {
	animation: icon-bounce 0.8s ease-out;
	-webkit-animation: icon-bounce 0.8s ease-out;
}

.event-block_two-title {
	position: relative;
	line-height: 28px;
	margin: 0 auto;
	margin-top: var(--margin-top-20);
	max-width: 120px;
}

.event-block_two-title a {
	position: relative;
	color: var(--white-color);
}

/*** 

====================================================================
	Team One
====================================================================

***/

.team-one {
	position: relative;
	padding: 110px 0px 80px;
	background-color: #000000;
}

.team-block_one {
	position: relative;
}

.team-block_one-inner {
	position: relative;
}

.team-block_one-pattern {
	position: absolute;
	left: 10px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-position: left bottom;
	background-size: no-repeat;
}

.team-block_one-image {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	border: 4px solid var(--main-color);
}

.team-block_one-image img {
	position: relative;
	width: 100%;
	display: block;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.team-block_one-inner:hover .team-block_one-image img {
	opacity: 0.80;
	transform: scale(1.05, 1.05);
}

.team-block_one-content {
	position: relative;
	padding-top: var(--padding-top-25);
	/* padding-left: var(--padding-left-55); */
	text-align: center;
	padding-bottom: var(--padding-bottom-30);
}

.team-block_one-title {
	position: relative;
	font-weight: 700;
}

.team-block_one-title a {
	position: relative;
	color: var(--color-two);
}

.team-block_one-title a:hover {
	color: var(--color-three);
}

.team-block_one-designation {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-10);
}

.team-block_one-social-list {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -60px;
	text-align: center;
	padding: 17px 10px 13px;
	background-color: var(--main-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.team-block_one-social-list a {
	position: relative;
	margin: 0px 10px;
	line-height: 1em;
	color: var(--white-color);
	font-size: var(--font-16);
}

.team-block_one-inner:hover .team-block_one-social-list {
	bottom: 0px;
}

/*** 

====================================================================
	Speaker Two
====================================================================

***/

.speakers-two {
	position: relative;
	padding: 110px 0px 70px;
}

.speakers-two .inner-container {
	position: relative;
	padding: 0px 80px;
}

/*** 

====================================================================
	Speaker Tabs Style
====================================================================

***/

.speaker-tabs {
	position: relative;
	margin-top: var(--margin-top-30);
}

.speaker-tabs .tab-btns {
	position: relative;
	text-align: center;
	border: 0;
	justify-content: center;
}

.speaker-tabs .tab-btns .tab-btn .nav-link{
	position: relative;
	line-height: 24px;
	font-weight: 700;
	padding: 0;
	cursor: pointer;
	width: 170px;
	margin: 0px 12px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-18);
	text-transform: uppercase;
	transition: all 500ms ease;
	background-color: var(--color-seven);
	padding-bottom: var(--padding-bottom-15);
	border: 0;
}

.speaker-tabs .tab-btns .tab-btn .nav-link span {
	position: relative;
	display: block;
	font-weight: 700;
	padding: 5px 0px;
	letter-spacing: 1px;
	font-size: var(--font-12);
	text-transform: uppercase;
	color: var(--white-color);
	background-color: var(--color-four);
	margin-bottom: var(--margin-bottom-15);
}

.speaker-tabs .tab-btns .tab-btn .nav-link::before {
	position: absolute;
	content: '';
	left: 50%;
	opacity: 0;
	bottom: 0px;
	margin-left: -7px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 15px solid var(--color-seven);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.speaker-tabs .tab-btns .tab-btn:nth-child(2) .nav-link span {
	background-color: var(--color-three);
}

.speaker-tabs .tab-btns .tab-btn:nth-child(3) .nav-link span {
	background-color: var(--main-color);
}

.speaker-tabs .tab-btns .tab-btn .nav-link:hover::before,
.speaker-tabs .tab-btns .tab-btn .nav-link.active::before {
	opacity: 1;
	bottom: -15px;
}

.speaker-tabs .tab-btns .tab-btn:last-child {
	margin-right: var(--margin-zero);
}

.speaker-tabs .tabs-content {
	position: relative;
	padding: 40px 0px 0px;
}

.speaker-tabs .tabs-content .tab {
	position: relative;
	display: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: translateY(80px);
}

.speaker-tabs .tabs-content .tab.active-tab {
	display: block;
	transform: translateY(0px);
}

.leadership-two_block {
	position: relative;
	margin-bottom: var(--margin-bottom-5);
}

.leadership-two_block-inner {
	position: relative;
	padding-left: 340px;
	min-height: 310px;
}

.leadership-two_block-content {
	position: relative;
	top: 45px;
	padding: 35px 60px 35px 30px;
}

.leadership-two_block-content::before {
	position: absolute;
	content: '';
	left: -100px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 0px 200px 200px 0px;
	background-color: var(--color-seven);
}

.leadership-two_block-image {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
	width: 310px;
	height: 310px;
	padding: 35px 35px;
	border-radius: 50%;
	border: 10px solid var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
	background: #FFC600;
	background: linear-gradient(to right, #FFC600 0%, #ffe801 100%);
}

.leadership-two_block-image img {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	border: 5px solid var(--white-color);
}

.leadership-two_block-icon {
	position: absolute;
	left: 50%;
	bottom: 10px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50px;
	text-align: center;
	margin-left: -30px;
	font-family: "Flaticon";
	color: var(--color-two);
	font-size: var(--font-26);
	background-color: var(--white-color);
}

.leadership-two_block-inner:hover .leadership-two_block-icon {
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.leadership-two_block-time {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	font-size: var(--font-14);
	text-transform: uppercase;
	color: var(--color-five);
}

.leadership-two_block-name {
	position: relative;
	font-weight: 700;
	font-size: var(--font-18);
	text-transform: uppercase;
	color: var(--color-four);
	margin-top: var(--margin-top-10);
}

.leadership-two_block-name span {
	position: relative;
	color: var(--color-five);
	text-transform: capitalize;
	font-size: var(--font-14);
}

.leadership-two_block-title {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-10);
}

.leadership-two_block-title a {
	position: relative;
	color: var(--color-two);
}

.leadership-two_block-title a:hover {
	color: var(--color-three);
}

.leadership-two_block-location {
	position: relative;
	font-weight: 600;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-top: var(--margin-top-20);
}

.leadership-two_block-location span {
	position: relative;
	color: var(--color-three);
}

.leadership-two_block.style-two .leadership-two_block-inner {
	padding-right: 340px;
	padding-left: var(--padding-zero);
}

.leadership-two_block.style-two .leadership-two_block-image {
	left: auto;
	right: 0px;
}

.leadership-two_block.style-two .leadership-two_block-content::before {
	left: 0px;
	right: -200px;
	border-radius: 200px 0px 0px 200px;
}

.leadership-two_block.style-two .leadership-two_block-content {
	padding-left: var(--padding-left-80);
}

.leadership-two_block:nth-child(2) .leadership-two_block-image {
	background: #2A00FB;
	background: linear-gradient(to right, #2A00FB 0%, #3100C0 100%);
}

.leadership-two_block:nth-child(3) .leadership-two_block-image {
	background: #E00B78;
	background: linear-gradient(to right, #E00B78 0%, #b0005a 100%);
}

.leadership-two_block:nth-child(4) .leadership-two_block-image {
	background: #080707;
	background: linear-gradient(to right, #080707 0%, #70666b 100%);
}

/*** 

====================================================================
	Faq One
====================================================================

***/

.faq-one {
	position: relative;
	padding: 110px 0px 80px;
	background-size: cover;
	background-position: center center;
}

/*** 

====================================================================
	Accordion Style
====================================================================

***/

.accordion-box {
	position: relative;
	z-index: 1;
}

.accordion-box li{
	margin-bottom: 15px;
	list-style: decimal;
}

.accordion-box .block .MuiButtonBase-root {
    position: relative;
    padding: 15px 25px 15px 30px;
    background-color: rgb(0 0 0);
	border-radius: 8px;
}

.accordion-box .MuiPaper-root,
.accordion-box .MuiPaper-root.Mui-expanded {
	margin-bottom: 15px;
}

.accordion-box .block .MuiAccordionDetails-root{
	padding: 0px 25px 20px 30px;
}

.accordion-box .block:last-child {
	margin-bottom: var(--margin-zero);
}

.accordion-box .block .acc-btn.active {}

.accordion-box .block .acc-btn p{
	position: relative;
	cursor: pointer;
	font-weight: 600;
	color: #fff;
	font-size: var(--font-18);
	transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	width: 100%;
}

.MuiCollapse-wrapper.MuiCollapse-vertical.css-smkl36-MuiCollapse-wrapper {
    color: #fff;
    background: #000;
    border-radius: 8px;
}
.accordion-box .block .MuiAccordionSummary-content {
	padding: 0;
	margin: 0;
}

.accordion-box p {
	line-height: 28px;
	font-size: var(--font-16);
	color: #000;
	margin-bottom: var(--margin-bottom-10)
}

.accordion-box p:last-child {
	margin-bottom: var(--margin-zero);
}

.accordion-box .block .icon {
	position: absolute;
	right: 0px;
	top: 0px;
	font-size: var(--font-16);
	color: var(--black-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.accordion-box .block .icon-outer .icon {
	position: relative;
	right: 0px;
	display: inline-block;
	color: var(--color-fourty);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.accordion-box .block.active-block {
	border-color: var(--color-fourteen);
}

.accordion-box .block .acc-btn.active .icon-outer .icon {
	transform: rotate(180deg);
}

.accordion-box .block .acc-content {
	position: relative;
	display: none;
}

.accordion-box .block .acc-content .content-text {}

.accordion-box .block .acc-content.current {
	display: block;
}

.accordion-box .block .content {
	position: relative;
	padding: 20px 0px 0px 0px;
}

.accordion-box .block .content .text {
	line-height: 28px;
	font-size: var(--font-16);
	color: var(--color-three);
}

.accordion-box.style-two .block {
	border: 1px solid #c88c19;
    border-radius: 8px;
	background: #000;
}
a.keynote {
    padding: 12px;
    border-radius: 61px;
    color: #fff;
    background: #c88c19;
    font-size: 13px;
    margin-top: 10px;
    font-weight: 500;
}
/*** 

====================================================================
	Price Two
====================================================================

***/

.price-two {
	position: relative;
	overflow: hidden;
	padding: 110px 0px 80px;
}

.price-two_pattern-one {
	position: absolute;
	left: -540px;
	top: -50px;
	width: 1081px;
	height: 1081px;
	background-repeat: no-repeat;
}

.price-two_pattern-two {
	position: absolute;
	right: -540px;
	top: -50px;
	width: 1081px;
	height: 1081px;
	background-repeat: no-repeat;
}

.price-block_two {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.price-block_two-inner {
	position: relative;
	overflow: hidden;
	padding: 60px 40px 70px;
	background-color: var(--color-seven);
}

.price-block_two-inner::before {
	position: absolute;
	content: '';
	left: -250px;
	top: 30px;
	right: -250px;
	height: 140px;
	transform: rotate(-20deg);
	background-color: var(--color-two);
}

.price-block_two-color_layer {
	position: absolute;
	content: '';
	left: -250px;
	top: -10px;
	right: 50px;
	height: 150px;
	background-color: var(--main-color);
	transform: rotate(20deg);
	clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}

.price-block_two-time {
	position: absolute;
	right: 20px;
	top: 20px;
	letter-spacing: 1px;
	font-size: var(--font-18);
	color: var(--white-color);
	text-transform: uppercase;
}

.price-block_two-title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-16);
	padding-left: var(--padding-left-50);
}

.price-block_two-date {
	position: relative;
	font-weight: 800;
	line-height: 1em;
	color: var(--color-two);
	font-size: var(--font-72);
	margin-top: var(--margin-top-5);
	padding-left: var(--padding-left-50);
}

.price-block_two-content {
	position: relative;
	margin-top: var(--margin-top-100);
}

.price-block_two-options {
	position: relative;
}

.price-block_two-options li {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-30);
	margin-bottom: var(--margin-bottom-25);
}

.price-block_two-options li strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-5);
}

.price-block_two-options li.light {
	opacity: 0.70;
}

.price-block_two-options li:last-child {
	margin-bottom: var(--margin-zero);
}

.price-block_two-options li::before {
	position: absolute;
	content: "\f1a9";
	left: 0px;
	top: 0px;
	font-family: "Flaticon";
}

.price-block_two-button {
	position: relative;
	margin-top: var(--margin-top-40);
}

.price-block_two-charges {
	position: relative;
	font-weight: 600;
	color: var(--color-two);
	font-size: var(--font-14);
	margin-top: var(--margin-top-10);
}

.price-block_two:nth-child(2) .price-block_two-title,
.price-block_two:nth-child(3) .price-block_two-title,
.price-block_two:nth-child(2) .price-block_two-date,
.price-block_two:nth-child(3) .price-block_two-date {
	color: var(--white-color);
}

.price-block_two:nth-child(2) .price-block_two-color_layer {
	background-color: var(--color-three);
}

.price-block_two:nth-child(3) .price-block_two-color_layer {
	background-color: var(--color-four);
}

.price-block_two:nth-child(2) .theme-btn {
	background-color: var(--color-three);
}

.price-block_two:nth-child(2) .btn-style-one .btn-wrap .text-one {
	color: var(--white-color);
}

.price-block_two:nth-child(3) .theme-btn {
	background-color: var(--color-four);
}

.price-block_two:nth-child(3) .btn-style-one .btn-wrap .text-one {
	color: var(--white-color);
}

/*** 

====================================================================
	News Two
====================================================================

***/

.news-two {
	position: relative;
	padding: 100px 0px 80px;
}

.news-block_two {
	position: relative;
	z-index: 2;
	margin-bottom: var(--margin-bottom-30);
}

.news-block_two-inner {
	position: relative;
}

.news-block_two-date {
	position: absolute;
	left: 25px;
	top: 25px;
	z-index: 2;
	font-weight: 600;
	padding: 7px 20px;
	letter-spacing: 1px;
	color: var(--white-color);
	font-size: var(--font-12);
	text-transform: uppercase;
	background-color: #c88c19;
}

.news-block_two-image {
	position: relative;
	overflow: hidden;
}

.news-block_two-image img:first-child {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	-webkit-transform: translatex(50%) scalex(2);
	transform: translatex(50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_two-inner:hover .news-block_two-image img:first-child {
	-webkit-transform: translatex(0) scalex(1);
	transform: translatex(0) scalex(1);
	opacity: 1;
	-webkit-filter: blur(0);
	filter: blur(0);
}

.news-block_two-inner:hover .news-block_two-image img:nth-child(2) {
	-webkit-transform: translatex(-50%) scalex(2);
	transform: translatex(-50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_two-image img {
	position: relative;
	width: 100%;
	display: block;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.news-block_two-content {
	position: relative;
	padding: 30px 30px 30px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.news-block_two-meta {
	position: relative;
}

.news-block_two-meta li {
	position: relative;
	display: inline-block;
	color: #000000;
	text-transform: capitalize;
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
}

.news-block_two-meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
	color: var(--main-color);
}

.news-block_two-title {
	position: relative;
	font-weight: 800;
	text-transform: capitalize;
	margin-top: var(--margin-top-10);
}

.news-block_two-title a {
	position: relative;
	color: var(--color-two);
}

.news-block_two-title a:hover {
	color: var(--color-three);
}

.news-block_two-more {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	display: inline-block;
	color: var(--color-three);
	text-transform: capitalize;
	margin-top: var(--margin-top-15);
	padding-right: var(--padding-right-25);
}

.news-block_two-more:hover {
	color: var(--color-two);
}

.news-block_two-more::before {
	position: absolute;
	content: '\f178';
	right: 0px;
	top: 5px;
	line-height: 1em;
	font-family: 'Font Awesome 6 Free';
}

/*** 

====================================================================
	Location one
====================================================================

***/

.location-one {
	position: relative;
	padding: 100px 0px 80px;
}

.location-one_map-column {
	position: relative;
}

.location-one_map-column iframe {
	position: relative;
	width: 100%;
	height: 400px;
}

.location-info_block {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.location-info_block-inner {
	position: relative;
	padding: 35px 40px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.location-info_block-content {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-60);
}

.location-info_block-icon {
	position: absolute;
	left: 0px;
	top: 3px;
	line-height: 1em;
	font-size: var(--font-40);
	color: var(--color-three);
	font-family: "Flaticon";
}

.location-info_block strong {
	position: relative;
	display: block;
	color: var(--color-two);
	text-transform: uppercase;
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-5);
}

.location-info_block:nth-child(2) .location-info_block-icon {
	color: var(--color-four);
}

.location-info_block:nth-child(3) .location-info_block-icon {
	color: var(--main-color);
}

/*** 

====================================================================
	Subscribe One
====================================================================

***/

.subscribe-two {
	position: relative;
	overflow: hidden;
	padding: 50px 0px 50px;
	background-color: var(--main-color);
}

.subscribe-two .inner-container {
	position: relative;
}

.subscribe-two_title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-16);
	text-transform: capitalize;
}

.subscribe-two_heading {
	position: relative;
	color: var(--color-two);
	margin-top: var(--margin-top-10);
}

/*** 

====================================================================
	Slider Three
====================================================================

***/

.slider-three {
	position: relative;
	overflow: hidden;
}

.slider-three .owl-dots,
.slider-three .owl-nav {
	display: none;
}

.slider-three_slide {
	position: relative;
	overflow: hidden;
	padding: 0px 0px 60px;
}

.slider-three_pattern-one {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	opacity: 0.70;
}

.slider-three_image-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-size: cover;
}

.slider-three_image-column {
	position: relative;
	z-index: 1;
}

.slider-three_image {
	position: relative;
}

.slider-three_image img {
	max-width: inherit !important;
	width: auto !important;
	margin: 0 auto;
}

.slider-three_content {
	position: relative;
	z-index: 1;
}

.slider-three_content-inner {
	position: relative;
	padding-top: 380px;
}

.slider-three_title {
	position: relative;
	font-weight: 600;
	color: var(--main-color);
	font-size: var(--font-16);
}

.slider-three_heading {
	line-height: 80px;
	font-size: var(--font-72);
	color: var(--white-color);
	margin-top: var(--margin-top-15);
}

.slider-three_location {
	position: relative;
	font-size: var(--font-16);
	color: var(--white-color);
	margin-top: var(--margin-top-20);
	margin-bottom: var(--margin-bottom-25);
}

.slider-three_button {
	position: relative;
	margin-top: var(--margin-top-20);
}

.slider-three_timer {
	position: relative;
	z-index: 1;
	left: -35px;
}

.slider-three .time-countdown {
	position: relative;
}

.slider-three .time-countdown .react-countdown .time-section{
	position: relative;
	z-index: 7;
	line-height: 1em;
	font-weight: 600;
	text-align: center;
	border-radius: 0px;
	margin: 0 30px 20px;
	color: var(--white-color);
	text-transform: uppercase;
	font-size: var(--font-20);
	display: inline-block;
}

.slider-three .time-countdown .react-countdown .time-section .time {
	position: relative;
	display: block;
	line-height: 1em;
	font-weight: 800;
	color: var(--white-color);
	font-size: var(--font-60);
	margin-bottom: var(--margin-bottom-10);
}

@media(max-width:767px){
	.slider-three .time-countdown .react-countdown .time-section .time {
		font-size: var(--font-30);
	}

	.slider-three .time-countdown .react-countdown .time-section{ 
		margin: 0 10px 20px;
	}
	.slider-three .time-countdown .react-countdown{ 
		padding-left: 15px;
	}
}

.slider-three .time-countdown .counter-column::before {
	position: absolute;
	content: ':';
	right: -50px;
	top: 5px;
	line-height: 1em;
	font-weight: 800;
	color: var(--white-color);
	font-size: var(--font-60);
}

.slider-three .time-countdown .counter-column:last-child:before {
	display: none;
}

/*** 

====================================================================
	Services One
====================================================================

***/

.services-one {
	position: relative;
	padding: 110px 0px 90px;
}

.services-one_pattern-one {
	position: absolute;
	left: -40px;
	top: 64%;
	width: 112px;
	height: 115px;
	background-repeat: no-repeat;
}

.services-one_pattern-two {
	position: absolute;
	right: 0px;
	top: 5%;
	width: 50%;
	height: 100%;
	background-position: right top;
	background-repeat: no-repeat;
}

.service-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.service-block_one-inner {
	position: relative;
	text-align: center;
	padding: 45px 40px 60px;
	border: 1px solid var(--color-ten);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: var(--white-color);
}

.service-block_one-inner::before {
	position: absolute;
	content: '';
	left: -1px;
	top: -1px;
	right: -1px;
	bottom: -1px;
	opacity: 0;
	background-color: #1d0639;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;

	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
	-webkit-transform: perspective(400px) rotateY(-90deg);
	-moz-transform: perspective(400px) rotateY(-90deg);
	-ms-transform: perspective(400px) rotateY(-90deg);
	-o-transform: perspective(400px) rotateY(-90deg);
	transform: perspective(400px) rotateY(-90deg);
	-webkit-transform-origin: right;
	-moz-transform-origin: right;
	-ms-transform-origin: right;
	-o-transform-origin: right;
	transform-origin: right;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.service-block_one-inner:hover::before {
	opacity: 1;
	-webkit-transform: perspective(400px) rotateY(0deg);
	-moz-transform: perspective(400px) rotateY(0deg);
	-ms-transform: perspective(400px) rotateY(0deg);
	-o-transform: perspective(400px) rotateY(0deg);
	transform: perspective(400px) rotateY(0deg);
}

.service-block_one-inner:hover .service-block_one-text,
.service-block_one-inner:hover .service-block_one-heading a {
	color: var(--white-color);
}

.service-block_one-inner:hover .service-block_one-icon {
	color: var(--white-color);
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.service-block_one-icon {
	position: relative;
	line-height: 1em;
	font-family: "Flaticon";
	font-size: var(--font-60);
	color: var(--color-four);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block_one-heading {
	position: relative;
	margin-top: var(--margin-top-25);
}

.service-block_one-heading a {
	position: relative;
	color: #000000;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block_one-heading a:hover {}

.service-block_one-text {
	position: relative;
	line-height: 28px;
	font-size: var(--font-16);
	color: rgb(0 0 0);
	margin-top: var(--margin-top-15);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block_one-arrow {
	position: absolute;
	left: 50%;
	opacity: 0;
	bottom: -25px;
	width: 50px;
	height: 50px;
	font-weight: 700;
	line-height: 50px;
	text-align: center;
	display: inline-block;
	font-size: var(--font-12);
	font-family: "Flaticon";
	color: var(--color-two);
	background-color: var(--main-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: scale(0.5, 1);
}

.service-block_one-arrow:hover {
	color: var(--white-color);
	background-color: var(--color-two);
}

.service-block_one-inner:hover .service-block_one-arrow {
	opacity: 1;
	transform: scale(1, 1) translateX(-50%);
}

/*** 

====================================================================
	Business One
====================================================================

***/

.business-one {
	position: relative;
	overflow: hidden;
}

.business-one_content-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.business-one_content-inner {
	position: relative;
}

.business-one_content-column .sec-title {
	margin-bottom: var(--margin-bottom-25);
}

.business-one_bold-text {
	position: relative;
	line-height: 30px;
	font-weight: 700;
	color: var(--color-four);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-20);
}

.business-one_text {
	position: relative;
	line-height: 28px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-30);
}

.business-one_feature {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-70);
	margin-bottom: var(--margin-bottom-35);
}

.business-one_feature-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	line-height: 1em;
	display: inline-block;
	font-size: var(--font-54);
	color: var(--color-three);
	font-family: "Flaticon";
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.business-one_feature:hover .business-one_feature-icon {
	color: var(--color-four);
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.business-one_feature strong {
	position: relative;
	display: block;
	color: var(--color-two);
	font-size: var(--font-20);
	margin-bottom: var(--margin-bottom-10);
}

.business-one_image-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.business-one_image-inner {
	position: relative;
	padding-bottom: 60px;
	padding-left: var(--padding-left-40);
}

.business-one_image {
	position: relative;
	overflow: hidden;
	display: inline-block;
	border-radius: 0px 80px 0px 0px;
}

.business-one_image-two {
	position: absolute;
	left: 218px;
    bottom: -190px;
	right: -140px;
	border: 5px solid var(--white-color);
}

.business-one_experiance {
	position: absolute;
	right: 0px;
	bottom: 200px;
	z-index: 1;
	width: 240px;
	height: 170px;
	text-align: center;
	padding: 20px 5px 0px;
	background-color: var(--main-color);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.business-one_counter {
	position: relative;
	font-weight: 800;
	line-height: 1.3em;
	color: var(--color-two);
	font-size: var(--font-48);
	text-transform: capitalize;
}

.business-one_experiance i {
	position: relative;
	display: block;
	font-weight: 700;
	font-style: normal;
	color: var(--color-two);
	font-size: var(--font-16);
	margin-top: var(--margin-top-5);
}

.business-one_experiance strong {
	position: relative;
	display: block;
	font-weight: 800;
	font-style: normal;
	color: var(--color-two);
	font-size: var(--font-24);
	margin-top: var(--margin-top-15);
}

.business-one_pattern-one {
	position: absolute;
	left: 0px;
	top: 50px;
	right: 0px;
	bottom: 0px;
	background-position: center center;
	background-repeat: no-repeat;
}

/*** 

====================================================================
	Counter Three
====================================================================

***/

.counter-three {
	position: relative;
	z-index: 1;
}

.counter-three .inner-container {
	position: relative;
	text-align: center;
	padding: 60px 0px 30px;
	background: #2a00fe;
	border-radius: 0px 150px 150px 150px;
	background: linear-gradient(to top, #2a00fe 0%, #1B1622 100%);
}

.counter-three_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.counter-three_inner {
	position: relative;
	display: inline-block;
}

.counter-three_inner::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 101px;
	height: 103px;
	background: url(../images/background/pattern-22.png) no-repeat;
}

.counter-three_counter {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	font-size: var(--font-40);
}

.counter-three_text {
	position: relative;
	color: var(--white-color);
	font-size: var(--font-18);
	margin-top: var(--margin-top-5);
}

/*** 

====================================================================
	Speaker Three
====================================================================

***/
.max-width-important {
    max-width: 100% !important;
}
.speakers-three {
	position: relative;
	margin-top: -100px;
	padding: 84px 0px 70px;
	background-color: #000000;
}

.speakers-three_pattern {
	position: absolute;
	left: -80px;
	top: 100px;
	width: 523px;
	height: 446px;
	z-index: 1;
	background-repeat: no-repeat;
}

.speakers-three_image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-size: cover;
}


.speaker-block_three {
	position: relative;
	z-index: 1;
	margin-bottom: var(--margin-bottom-40);
}

.speaker-block_three-inner {
	position: relative;
	overflow: hidden;
}

.speaker-block_three-image {
	position: relative;
	border-radius: 20% 0% 30% 0%;
	background-color: #c99c18;
	border: 4px solid var(--main-color);
}

.speaker-block_three-image img {
	position: relative;
	overflow: hidden;
	width: 100%;
	border-radius: 20% 0% 30% 0%;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.mb-50{
	margin-bottom: 50px;
}
.vertically{
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 50px;
margin-top: -20px;
}
.speaker-block_three-inner:hover .speaker-block_three-image img {
	opacity: 0.60;
}

.speaker-block_three-social {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}

.speaker-block_three-share {
	position: relative;
	width: 40px;
	height: 40px;
	z-index: 2;
	line-height: 40px;
	text-align: center;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--color-two);
	border-radius: 0px 0px 15px 0px;
	font-family: 'FontAwesome';
	background-color: var(--main-color);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	overflow: hidden;
}

.speaker-block_three-social-list {
	position: absolute;
	left: 0px;
	width: 40px;
	bottom: -200%;
	opacity: 0;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.speaker-block_three-inner:hover .speaker-block_three-social-list {
	bottom: 50px;
	opacity: 1;
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.speaker-block_three-social-list a {
	position: relative;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50px;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--black-color);
	background-color: var(--white-color);
	margin-bottom: var(--margin-bottom-5);
}

.speaker-block_three-social-list a.youtube {
	color: #ff0000;
}

.speaker-block_three-social-list a.twitter {
	color: #1da1f2;
}

.speaker-block_three-social-list a.facebook {
	color: #4267b2;
}

.speaker-block_three-social-list a.instagram {
	color: #e73a67;
}
.color{
	color: rgb(255, 255, 255);
    text-align: center;
    line-height: 1.8;
}
.color2{
	color: rgb(255, 255, 255);
    line-height: 1.8;
}
.fea{
	position: relative !important;
    color: #c88c19 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}
.speaker-block_three-content {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -187px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	padding-left: var(--padding-left-30);
}

.speaker-block_three-inner:hover .speaker-block_three-content {
	bottom: 30px;
}

.speaker-block_three-title {
	position: relative;
	font-weight: 700;
}

.speaker-block_three-title a {
	position: relative;
	color: var(--white-color);
}

.speaker-block_three-title a:hover {
	color: var(--main-color);
}

.speaker-block_three-detail {
	position: relative;
	color: var(--white-color);
	font-size: var(--font-15);
	margin-top: var(--margin-top-10);
	padding-right: 13px;
}
.float{
	/* animation: bounce 2s infinite; */
	position:fixed;
	width:60px;
	height:60px;
	bottom: 75px;
	/*right:40px;*/
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
/*** 

====================================================================
	Speaker Four
====================================================================

***/

.speakers-four {
	position: relative;
	padding: 110px 0px 80px;
}

.speakers-four_pattern {
	position: absolute;
	left: -540px;
	top: 250px;
	width: 1081px;
	height: 1081px;
	background-repeat: no-repeat;
}

/*** 

====================================================================
	Speaker Tabs Two
====================================================================

***/

.speaker-tabs_two {
	position: relative;
	margin-top: -190px;
}

.speaker-tabs_two .tab-btns {
	position: relative;
	text-align: right;
	justify-content: flex-end;
	border: 0;
}
.slider-one_heading {
    color: var(--white-color);
    margin-bottom: var(--margin-bottom-50);
    margin-top: var(--margin-top-30);
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
    transition: all 1s ease;
}
@media(max-width:1123px){
	.speaker-tabs_two .tab-btns {
		justify-content: flex-start;
	}
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link{
	position: relative;
	line-height: 24px;
	font-weight: 800;
	cursor: pointer;
	width: 170px;
	margin: 0px 12px;
	text-align: left;
	display: inline-block;
	color: #000000;
	font-size: var(--font-18);
	text-transform: uppercase;
	transition: all 500ms ease;
	border-radius: 0px 20px 20px 0px;
	padding-left: var(--padding-left-55);
	background-color: var(--color-seven);
	padding-bottom: var(--padding-bottom-15);
	overflow: hidden;
    border: 0;
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link span {
	position: absolute;
	left: -56px;
	top: 55px;
	display: block;
	font-weight: 700;
	padding: 5px 46px;
	letter-spacing: 1px;
	font-size: var(--font-12);
	text-transform: uppercase;
	color: var(--white-color);
	transform: rotate(-90deg);
	background-color: #c88c19;
	margin-bottom: var(--margin-bottom-15);
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link strong {
	position: relative;
	display: block;
	font-weight: 800;
	line-height: 1em;
	color: var(--main-color);
	font-size: var(--font-72);
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link i {
	position: relative;
	font-style: normal;
	color: var(--color-three);
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link::before {
	position: absolute;
	content: '';
	left: 50%;
	opacity: 0;
	bottom: 0px;
	margin-left: -7px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 15px solid var(--color-seven);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.speaker-tabs_two .tab-btns .tab-btn:nth-child(2) .nav-link span {
	background-color: #000000;
}

.speaker-tabs_two .tab-btns .tab-btn:nth-child(3) .nav-link span {
	background-color: #000000;
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link:hover::before,
.speaker-tabs_two .tab-btns .tab-btn .nav-link.active::before {
	opacity: 1;
	bottom: -15px;
}

.speaker-tabs_two .tab-btns .tab-btn:last-child .nav-link{
	margin-right: var(--margin-zero);
}

.speaker-tabs_two .tabs-content {
	position: relative;
	padding: 60px 90px 0px;
}

.speaker-tabs_two .tabs-content .tab {
	position: relative;
	display: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: translateY(80px);
}

.speaker-tabs_two .tabs-content .tab.active-tab {
	display: block;
	transform: translateY(0px);
}

/* LeaderShip Three Block */

.leadership-three_block {
	position: relative;
	margin-bottom: var(--margin-bottom-40);
}

.leadership-three_block-inner {
	position: relative;
}

.leadership-three_block-content {
    position: relative;
    min-height: 152px;
    padding-left: 190px;
    overflow: hidden;
    border-radius: 8px;
    border: #fff solid 1px;
    background-color: rgb(245 245 245 / 7%);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.leadership-three_block-inner:hover .leadership-three_block-content {
	background-color: rgb(0 0 0 / 0%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.leadership-three_block-image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 210px;
	height: 210px;
	/* border-radius: 50% 50% 50% 0%;
	border: 8px solid var(--color-four); */
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.leadership-three_block-inner:hover .leadership-three_block-image {
	border-color: var(--main-color);
}

.leadership-three_block-image img {
    max-width: 70%;
    display: flex;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 50% 50% 50% 0%;
}

.leadership-three_block-icon {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	font-size: var(--font-44);
	color: var(--color-two);
	font-family: "Flaticon";
	border-radius: 50px 50px 0px 50px;
	background-color: var(--main-color);
}

.leadership-three_block-name {
	position: relative;
	font-weight: 700;
	color: var(--color-four);
	font-size: var(--font-20);
	margin-top: var(--margin-top-15);
}

.leadership-three_block-name span {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-14);
}

.leadership-three_block-title a:hover {
	color: #c99c18;
}

.leadership-three_block-title {
	position: relative;
	font-weight: 800;
	margin-top: var(--margin-top-10);
}

.leadership-three_block-title a {
	position: relative;
	color: var(--color-two);
}

.leadership-three_block-title a:hover {
	color: #c99c18;
}

.leadership-three_block-location {
	position: relative;
	font-weight: 600;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-top: var(--margin-top-15);
}

.leadership-three_block-location span {
	position: relative;
	color: #c99c18;
}

.leadership-three_block-timing {
	position: absolute;
	right: 3px;
	padding-left: 50px !important	;
	bottom: 0px;
	font-weight: 700;
	color: var(--color-five);
	font-size: var(--font-17);
	padding: 15px 80px 15px 30px;
	background-color: #1c122a;
	border-radius: 50px 0px 0px 50px;
}
.zsiq_floatmain.zsiq_theme1.siq_bR {
    margin-bottom:  65px;
}

/* Instagram feed */
.instagram-feed {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
  }
  
  .instagram-post {
	width: calc(33% - 10px); /* Adjust based on the number of posts per row */
	margin-bottom: 15px;
  }
  
  .instagram-post img,
  .instagram-post video {
	width: 120%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #c99c18;
  }
/*** 

====================================================================
	Testimonial One
====================================================================

***/

.testimonial-one {
	position: relative;
	padding: 110px 0px 80px;
}

.testimonial-one .owl-dots {
	display: none;
}

.testimonial-one .owl-nav {
	position: absolute;
	right: 0px;
	top: -100px;
}

.testimonial-one .owl-nav .owl-prev,
.testimonial-one .owl-nav .owl-next {
	position: relative;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-18);
	margin-left: var(--margin-left-15);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.testimonial-one .owl-nav .owl-prev:hover,
.testimonial-one .owl-nav .owl-next:hover {
	color: var(--white-color);
	background-color: var(--color-three);
}

.testimonial-one_pattern {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-size: cover;
}

.testimonial-block_one {
	position: relative;
}

.testimonial-block_one-inner {
	position: relative;
}

.testimonial-block_one-content {
	position: relative;
	padding: 40px 40px;
	border-radius: 0px 25px 25px 0px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.testimonial-block_one-text {
	position: relative;
	line-height: 34px;
	font-size: var(--font-18);
	color: var(--color-five);
}

.testimonial-block_one-rating {
	position: relative;
	color: var(--main-color);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
	margin-left: var(--margin-left-150);
}

.testimonial-block_one-quote {
	position: absolute;
	right: 40px;
	bottom: 30px;
	opacity: 0.10;
	line-height: 1em;
	font-family: "Flaticon";
	color: var(--color-two);
	font-size: var(--font-50);
}

.testimonial-block_one-lower {
	position: relative;
	margin-top: -50px;
	z-index: 1;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-left: var(--margin-left-50);
	padding-top: var(--padding-top-70);
	padding-left: var(--padding-left-140);
}

.testimonial-block_one-lower::before {
	position: absolute;
	content: '';
	left: -50px;
	top: 50px;
	border-top: 70px solid var(--main-color);
	border-left: 50px solid transparent;
}

.testimonial-block_one-author {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 120px;
	height: 120px;
	overflow: hidden;
	border-radius: 0px 30px 0px 0px;
}

.testimonial-block_one-lower strong {
	position: relative;
	display: block;
	font-weight: 800;
	font-size: var(--font-24);
	color: var(--color-four);
	margin-bottom: var(--margin-bottom-10);
}

.testimonial-one .slick-arrow {
	background: #fff;
	width: 50px;
	height: 50px;
	line-height: 65px;
	border: 1px solid #b7b7b7;
	color: #6e6e6e;
	padding: 0;
	font-size: 0px;
	margin: 0;
	border-radius: 50%;
	transition: all 0.3s;
	z-index: 1;
  }

  @media (max-width: 575px) {
	.testimonial-one .slick-arrow {
	  display: none !important;
	}
  }

  .testimonial-one .slick-next {
	position: absolute;
	top: -100px;
	right: 0;
  }
  .testimonial-one .slick-next:before {
	font-family: "themify";
	content: "";
	color: var(--color-three);;
  }
  .testimonial-one .slick-prev {
	position: absolute;
	top: -100px;
	left: auto;
	right: 70px;
  }
  .testimonial-one .slick-prev:after {
	left: -3px;
	bottom: -3px;
	border-right: 0 !important;
	border-left: 3px solid var(--color-three);;
	border-bottom: 3px solid var(--color-three);;
  }
  .testimonial-one .slick-prev:before {
	font-family: "themify";
	content: "";
	color: var(--color-three);;
  }
  .testimonial-one .owl-item {
	padding: 10px;
  }
  .testimonial-one .slick-slide {
	padding: 10px;
  }

/*** 

====================================================================
	Price Three
====================================================================

***/

.price-three {
	position: relative;
	padding: 110px 0px 80px;
	background-size: cover;
	background-attachment: fixed;
}

.price-block_three {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.price-block_three-inner {
	position: relative;
	padding: 50px 55px 70px;
}

.price-block_three-pattern {
	position: absolute;
	right: 0px;
	top: 0px;
	left: 0px;
	bottom: 0px;
	background-repeat: no-repeat;
}

.price-block_three-title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-14);
}

.price-block_three-price {
	position: relative;
	font-weight: 700;
	line-height: 1em;
	color: var(--color-four);
	font-size: var(--font-72);
	margin-top: var(--margin-top-10);
}

.price-block_three-price span {
	position: relative;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
	color: var(--color-five);
}

.price-block_three-options {
	position: relative;
	margin-top: var(--margin-top-10);
}

.price-block_three-options li {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-20);
}

.price-block_three-options li strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-5);
}

.price-block_three-options li.light {
	opacity: 0.70;
}

.price-block_three-options li:last-child {
	margin-bottom: var(--margin-zero);
}

.price-block_three-button {
	position: relative;
	margin-top: var(--margin-top-30);
}

.price-block_three-charges {
	position: relative;
	font-weight: 600;
	color: var(--color-two);
	font-size: var(--font-14);
	margin-top: var(--margin-top-20);
}

.price-block_three:nth-child(2) .price-block_three-price {
	color: var(--color-three);
}

.price-block_three:nth-child(2) .price-block_three-button .theme-btn {
	background-color: var(--color-three);
}

.price-block_three:nth-child(3) .price-block_three-price {
	color: var(--main-color);
}

.price-block_three:nth-child(3) .price-block_three-button .theme-btn {
	background-color: var(--main-color);
}

/*** 

====================================================================
	News Three
====================================================================

***/

.news-three {
	position: relative;
	padding: 100px 0px 80px;
}

.news-block_three {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.news-block_three-inner {
	position: relative;
}

.news-block_three-image {
	position: relative;
	overflow: hidden;
}

.news-block_three-image img:first-child {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	-webkit-transform: translatex(50%) scalex(2);
	transform: translatex(50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_three-inner:hover .news-block_three-image img:first-child {
	-webkit-transform: translatex(0) scalex(1);
	transform: translatex(0) scalex(1);
	opacity: 1;
	-webkit-filter: blur(0);
	filter: blur(0);
}

.news-block_three-inner:hover .news-block_three-image img:nth-child(2) {
	-webkit-transform: translatex(-50%) scalex(2);
	transform: translatex(-50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_three-image img {
	position: relative;
	width: 100%;
	display: block;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.news-block_three-post {
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 3;
	padding: 9px 15px 9px 7px;
	border-radius: 50px;
}

.news-block_three-post:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 40px;
	height: 42px;
	border-radius: 50px;
	background-color: var(--white-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.news-block_three-inner:hover .news-block_three-post:before {
	width: 100%;
}

.news-block_three-post-inner {
	position: relative;
	font-weight: 600;
	font-size: var(--font-12);
	text-transform: uppercase;
	color: var(--color-fourteen);
	padding-left: var(--padding-left-40);
}

.news-block_three-post-inner span {
	color: var(--main-color);
}

.news-block_three-post-image {
	position: absolute;
	left: 1px;
	top: 0px;
	width: 24px;
	height: 24px;
}

.news-block_three-post-image:before {
	position: absolute;
	content: '';
	left: -3px;
	top: -3px;
	right: -3px;
	bottom: -3px;
	border-radius: 50px;
	border: 1px solid var(--main-color);
}

.news-block_three-post-image img {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
}

.news-block_three-content {
	position: relative;
	z-index: 2;
	margin-top: -100px;
	padding: 25px 30px 25px;
	margin-left: var(--margin-left-20);
	margin-right: var(--margin-right-20);
	background-color: var(--white-color);
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
}

.news-block_three-meta {
	position: relative;
}

.news-block_three-meta li {
	position: relative;
	display: inline-block;
	font-size: var(--font-14);
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
	color: rgba(var(--black-color-rgb), 0.80);
}

.news-block_three-meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	font-weight: 800;
	color: var(--color-four);
}

.news-block_three-meta li:last-child {
	margin-right: var(--margin-zero);
}

.news-block_three-heading {
	font-weight: 700;
	line-height: 28px;
	margin-top: var(--margin-top-15);
}

.news-block_three-heading a {
	color: var(--color-two);
}

.news-block_three-heading a:hover {
	color: var(--color-three);
}

.news-block_three-more {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	display: inline-block;
	font-size: var(--font-14);
	color: var(--black-color);
	text-transform: uppercase;
	margin-top: var(--margin-top-15);
	padding-right: var(--padding-right-25);
	padding-bottom: var(--padding-bottom-5);
	border-bottom: 1px solid var(--black-color);
}

.news-block_three-more::before {
	position: absolute;
	content: '\f178';
	right: 0px;
	top: 5px;
	line-height: 1em;
	font-weight: 800;
	font-family: 'FontAwesome';
}

.news-block_three-more:hover {
	color: var(--color-three);
}

/*** 

====================================================================
	Clients Two
====================================================================

***/

.clients-two {
	position: relative;
	overflow: hidden;
	padding-bottom: var(--padding-bottom-100);
}

.clients-two.style-two {
	padding-top: var(--padding-top-100);
}

.clients-two .owl-dots,
.clients-two .owl-nav {
	display: none;
}

.clients-two .clients-two_column {
	margin: 0px 15px;
}

clients-two_column {
	position: relative;
}

.clients-two_column a {
	position: relative;
	display: block;
	width: 230px;
	border: 1px solid rgba(var(--black-color-rgb), 0.07);
}

.clients-two_column a:hover {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}


.clients-two_slide {
	-webkit-animation: Mover 20s linear infinite;
	animation: Mover 20s linear infinite;
}

@keyframes Mover {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(-90%);
		transform: translateX(-90%);
	}
  }

/*** 

====================================================================
	Gallery Two
====================================================================

***/

.gallery-two {
	position: relative;
	margin-bottom: -8px;
}

.gallery-two .owl-dots,
.gallery-two .owl-nav {
	display: none;
}

/*** 

====================================================================
	Page Title
====================================================================

***/

.page-title {
	position: relative;
	padding: 300px 0px 165px;
	background-attachment: fixed;
	background-size: cover;
}

.page-title .left-box {
	position: relative;
	/* margin-top: 60px; */
}

.page-title::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.60;
	z-index: 1;
	/* background: url(../images/background/12.jpg) no-repeat; */
}

.page-title_heading {
	position: relative;
	z-index: 1;
	font-size: 35px;
	letter-spacing: 1px;
	color: var(--white-color);
	text-transform: uppercase;
	max-width: 850px;
}

.page-title_big {
	opacity: 0.90;
	display: block;
	font-weight: 800;
	color: var(--main-color);
	font-size: 50px;
	text-transform: uppercase;
}

.bread-crumb {
	position: relative;
	z-index: 1;
}

.bread-crumb li {
	position: relative;
	font-weight: 800;
	letter-spacing: 1px;
	display: inline-block;
	color: var(--white-color);
	font-size: var(--font-16);
	text-transform: uppercase;
	padding-left: var(--padding-left-20);
}

.bread-crumb li:first-child {
	padding-left: var(--padding-zero);
}

.bread-crumb li a {
	position: relative;
	color: var(--white-color);
}

.bread-crumb li a:hover {
	color: var(--main-color);
}

.bread-crumb li::before {
	position: absolute;
	content: ':';
	right: -15px;
	top: -1px;
	color: var(--white-color);
}

.bread-crumb li:last-child::before {
	display: none;
}

/*** 

====================================================================
	Faq Two
====================================================================

***/

.faq-two {
	position: relative;
	padding: 120px 0px 120px;
}

.faq-two_form-box {
	position: relative;
	padding: 60px 50px 50px;
}

.faq-two_form-title {
	position: relative;
	color: var(--white-color);
	margin-bottom: var(--margin-bottom-25);
}

/*** 

====================================================================
	Faq Form
====================================================================

***/

.faq-form .form-group {
	margin-bottom: var(--margin-bottom-25);
}

.faq-form .form-group:last-child {
	margin-bottom: 0px;
}

.faq-form .form-group input[type="text"],
.faq-form .form-group input[type="password"],
.faq-form .form-group input[type="tel"],
.faq-form .form-group input[type="email"] {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 25px;
	height: 60px;
	border-radius: 0px;
	font-size: var(--font-16);
	color: #000;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
}

.faq-form .form-group textarea::-webkit-input-placeholder,
.faq-form .form-group input::-webkit-input-placeholder {
	color: var(--color-sixteen)
}

.faq-form .form-group input[type="text"]:focus,
.faq-form .form-group input[type="password"]:focus,
.faq-form .form-group input[type="tel"]:focus,
.faq-form .form-group input[type="email"]:focus,
.faq-form .form-group select:focus,
.faq-form .form-group textarea:focus {
	border-color: var(--black-color);
	background-color: var(--white-color);
}

.faq-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 25px 25px;
	font-size: var(--font-16);
	color: #000;
	height: 160px;
	resize: none;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
}

.faq-form .theme-btn {
	cursor: pointer;
	font-weight: 600;
	border-radius: 0px;
	padding: 18px 40px;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
	color: var(--black-color);
	background-color: var(--white-color);
}

/*** 

====================================================================
	Gallery Three
====================================================================

***/

.gallery-three {
	position: relative;
	padding: 110px 0px 110px;
}

.gallery-three .gallery-one_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

/*** 

====================================================================
	Testimonial Three
====================================================================

***/

.testimonial-two {
	position: relative;
	padding: 110px 0px 80px;
}

.testimonial-two .testimonial-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-35);
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.testimonial-two .styled-pagination {
	margin-top: var(--margin-top-20);
}

.styled-pagination {
	position: relative;
}

.styled-pagination li {
	position: relative;
	margin: 0px 5px 10px;
	display: inline-block;
}

.styled-pagination li a {
	position: relative;
	display: inline-block;
	line-height: 48px;
	height: 50px;
	width: 50px;
	min-width: 50px;
	font-weight: 400;
	border-radius: 50px;
	text-align: center;
	text-transform: capitalize;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	font-size: var(--font-16);
	color: rgb(255 255 255);
    border: 2px solid #c88c19;
}

.styled-pagination li.prev a,
.styled-pagination li.next a {
	line-height: 45px;
	top: 1px;
	font-family: "Flaticon";
}

.styled-pagination li a:hover,
.styled-pagination li a.active {
	color: var(--white-color);
	border-color: #c99c18;
	background-color: var(--black-color);
}




/*** 

====================================================================
			Coming Soon
====================================================================

***/

.coming-soon {
	position: fixed;
	width: 100%;
	height: 100%;
	display: block;
	overflow-y: auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-size: cover;
	text-align: center;
}

.coming-soon .content {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: table;
	vertical-align: middle;
}

.coming-soon .content:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--black-color);
	content: "";
	opacity: .50;
}

.coming-soon .content .content-inner {
	position: relative;
	padding: 90px 15px;
	display: table-cell;
	vertical-align: middle;
}

.coming-soon .content .content-inner .logo {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.coming-soon .content .content-inner h2 {
	color: var(--white-color);
	margin-bottom: var(--margin-bottom-200);
}

.time-counter {
	position: relative;
}

.time-counter .time-countdown {
	position: relative;
	margin-bottom: var(--margin-bottom-150);
}

.time-counter .time-countdown .time-section{
	position: relative;
	display: inline-block;
	width: 180px;
	height: 180px;
	line-height: 1em;
	color: var(--white-color);
	text-transform: uppercase;
	font-size: var(--font-20);
	font-weight: 600;
	text-align: center;
	z-index: 7;
	border-radius: 0px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
	background-color: rgba(0, 0, 0, .80);
	margin: 0 15px 20px;
}

.time-counter .time-countdown .time-section .time{
	position: relative;
	display: block;
	font-size: var(--font-70);
	line-height: 30px;
	padding: 60px 0px 30px;
	color: var(--white-color);
	font-weight: 600;
}

.coming-soon .emailed-form {
	position: relative;
	margin-bottom: var(--margin-bottom-40);
}

.coming-soon .emailed-form h3 {
	color: var(--white-color);
	margin-bottom: var(--margin-bottom-20);
}

.coming-soon .emailed-form .text {
	display: block;
	color: var(--white-color);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-40);
}

.coming-soon .emailed-form .form-group {
	position: relative;
	display: block;
	max-width: 540px;
	width: 100%;
	margin: 0 auto;
}

.coming-soon .emailed-form .form-group input[type="text"],
.coming-soon .emailed-form .form-group input[type="tel"],
.coming-soon .emailed-form .form-group input[type="email"],
.coming-soon .emailed-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	height: 60px;
	color: var(--black-color);
	font-size: var(--font-16);
	overflow: hidden;
	padding: 15px 50px 15px 20px;
	background: var(--white-color);
	border: 1px solid #f1f1f1;
	box-shadow: 0 0 13px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .emailed-form .form-group input:focus,
.coming-soon .emailed-form .form-group select:focus,
.coming-soon .emailed-form .form-group textarea:focus {
	border-color: var(--main-color);
	;
}

.coming-soon .emailed-form .form-group input[type="submit"],
.coming-soon .emailed-form button {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
	line-height: 40px;
	font-size: var(--font-18);
	color: var(--main-color);
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .emailed-form .form-group input[type="submit"]:hover,
.coming-soon .emailed-form button:hover {
	color: var(--main-color-two);
}

.coming-soon .copyright-text {
	position: relative;
	font-size: var(--font-18);
	line-height: 26px;
	color: var(--white-color);
	font-weight: 400;
}

.coming-soon .copyright-text a {
	color: var(--main-color);
}

.coming-soon .copyright-text a:hover {
	text-decoration: underline;
}

.clock-wrapper {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	width: 250px;
	height: 250px;
	margin: auto;
	margin-top: -145px;
	border-radius: 50%;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
	background-color: rgba(0, 0, 0, .40);
	transform: scale(2);
}

.clock-base {
	width: 250px;
	height: 250px;
	border-radius: 50%;
}

.click-indicator {
	position: absolute;
	z-index: 1;
	top: 10px;
	left: 10px;
	width: 230px;
	height: 230px;
}

.click-indicator div {
	position: absolute;
	width: 2px;
	height: 4px;
	margin: 113px 114px;
}

.click-indicator div>span {
	height: 5px;
	width: 5px;
	background-color: var(--white-color);
	display: inline-block;
	border-radius: 50%;
	margin-left: -1px;
	margin-top: -1px;
}

.click-indicator div:nth-child(1) {
	transform: rotate(30deg) translateY(-120px);
}

.click-indicator div:nth-child(2) {
	transform: rotate(60deg) translateY(-120px);
}

.click-indicator div:nth-child(3) {
	transform: rotate(90deg) translateY(-120px);
	background-color: var(--main-color);
}

.click-indicator div:nth-child(4) {
	transform: rotate(120deg) translateY(-120px);
}

.click-indicator div:nth-child(5) {
	transform: rotate(150deg) translateY(-120px);
}

.click-indicator div:nth-child(6) {
	transform: rotate(180deg) translateY(-120px);
	background-color: var(--main-color);
}

.click-indicator div:nth-child(7) {
	transform: rotate(210deg) translateY(-120px);
}

.click-indicator div:nth-child(8) {
	transform: rotate(240deg) translateY(-120px);
}

.click-indicator div:nth-child(9) {
	transform: rotate(270deg) translateY(-120px);
	background-color: var(--main-color);
}

.click-indicator div:nth-child(10) {
	transform: rotate(300deg) translateY(-120px);
}

.click-indicator div:nth-child(11) {
	transform: rotate(330deg) translateY(-120px);
}

.click-indicator div:nth-child(12) {
	transform: rotate(360deg) translateY(-120px);
	background-color: var(--main-color);
}

.clock-hour {
	position: absolute;
	z-index: 2;
	top: 75px;
	left: 123px;
	width: 4px;
	height: 65px;
	background-color: var(--main-color);
	border-radius: 2px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 2px 50px;
	transition: .5s;
	-webkit-animation: rotate-hour 43200s linear infinite;
	-moz-animation: rotate-hour 43200s linear infinite;
}

.clock-minute {
	position: absolute;
	z-index: 3;
	top: 55px;
	left: 123px;
	width: 4px;
	height: 85px;
	background-color: var(--main-color);
	border-radius: 2px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 2px 70px;
	transition: .5s;
	-webkit-animation: rotate-minute 3600s linear infinite;
	-moz-animation: rotate-minute 3600s linear infinite;
}

.clock-second {
	position: absolute;
	z-index: 4;
	top: 15px;
	left: 124px;
	width: 2px;
	height: 130px;
	background-color: var(--white-color);
	opacity: .9;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 1px 110px;
	transition: .5s;
	-webkit-animation: rotate-second 60s linear infinite;
	-moz-animation: rotate-second 60s linear infinite;
}

.clock-second:after {
	content: "";
	display: block;
	position: absolute;
	left: -3px;
	bottom: 16px;
	width: 8px;
	height: 8px;
	background-color: var(--text-color);
	border: solid 2px var(--text-color);
	border-radius: 50%;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.clock-center {
	position: absolute;
	z-index: 1;
	width: 150px;
	height: 150px;
	top: 50px;
	left: 50px;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
	border-radius: 50%;
}

.clock-center:after {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	margin: 65px;
	background-color: var(--text-color);
	border-radius: 50%;
}

.coming-soon .social-box {
	margin-top: var(--margin-top-10);
}

.coming-soon .social-box li {
	position: relative;
	display: inline-block;
	margin-right: var(--margin-right-10);
}

.coming-soon .social-box li a {
	position: relative;
	color: var(--white-color);
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	display: inline-block;
	border: 1px solid var(--white-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .social-box li a:hover {
	background-color: var(--main-color);
	border-color: var(--main-color);
	color: var(--white-color);
}

/*** 

====================================================================
	Privacy Section
====================================================================

***/

.privacy-section {
	position: relative;
	padding: 110px 0px 80px;
}

.privacy-section .pattern-layer {
	position: absolute;
	top: 50px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: right top;
}

.privacy-section .privacy-content {
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 25px;
	border-bottom: 1px solid #e2e2e2;
}

.social-box-link {
    position: relative;
    font-size: var(--font-16);
    width: 40px;
    height: 40px;
    line-height: 39px;
    text-align: center;
    border-radius: 0px;
    border: 1px solid #c99c18;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}
.privacy-section .privacy-content:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border: none;
	color: #fff;
}
.li{
	list-style: disc;
}
.privacy-section h3 {
	color: var(--main-color-two);
	margin-bottom: var(--margin-bottom-20);
}

.privacy-section p {
	margin-bottom: var(--margin-bottom-15);
}

.privacy-section .date {
	position: relative;
	font-weight: 500;
	color: var(--main-color);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-15);
}

.privacy-section h4 {
	color: var(--main-color-two);
	margin-bottom: var(--margin-bottom-20);
}

.privacy-section .privacy-list {
	position: relative;
	margin-left: var(--margin-left-20);
}

.privacy-section .privacy-list li {
	position: relative;
	font-weight: 400;
	padding-left: 26px;
	line-height: 1.7em;
	font-size: var(--font-18);
	color: var(--main-color-two);
	margin-bottom: var(--margin-bottom-10);
}

.privacy-section .privacy-list li:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 10px;
	width: 7px;
	height: 7px;
	border-radius: 50px;
	background-color: var(--main-color-two);
}

/*** 

====================================================================
	Speaker Detail
====================================================================

***/

.speaker-detail {
	position: relative;
	padding: 110px 0px 100px;
}

.speaker-detail_author-column {
	position: relative;
}

.speaker-detail_author-image {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	border: 4px solid var(--main-color);
}

.speaker-detail_author-image img {
	position: relative;
	width: 100%;
	display: block;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.speaker-detail_author-inner {
	position: relative;
}

.speaker-detail_author-pattern {
	position: absolute;
	left: 10px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-position: left bottom;
	background-size: no-repeat;
}

.speaker-detail_author-content {
	position: relative;
	padding-top: var(--padding-top-25);
	padding-left: var(--padding-left-55);
	padding-bottom: var(--padding-bottom-30);
}

.speaker-detail_author-column .speaker-detail_title {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
}

.speaker-detail_title a {
	position: relative;
	color: var(--white-color);
}

.speaker-detail_designation {
	position: relative;
	color: var(--white-color);
	margin-top: var(--margin-top-5);
}

.speaker-detail_social-list {
	position: absolute;
	right: 30px;
	bottom: 0px;
	width: 48px;
	background-color: var(--main-color);
}

.speaker-detail_social-list a {
	position: relative;
	width: 48px;
	height: 48px;
	display: block;
	line-height: 48px;
	text-align: center;
	color: var(--color-two);
}

.speaker-detail_social-list a:hover {
	color: var(--color-two);
	background-color: #000000;
}

.speaker-detail_title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-15);
}

.speaker-detail_subtitle {
	margin-bottom: var(--margin-bottom-15);
	color: #ffffff;
}

.speaker-detail_info {
	position: relative;
}

.speaker-detail_info li {
	position: relative;
	padding-left: 120px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-15);
}

.speaker-detail_info li span {
	position: absolute;
	left: 0px;
	font-weight: 700;
	color: var(--color-two);
}

.event-block_three {
	position: relative
}

.event-block_three-inner {
	position: relative;
	min-height: 235px;
	padding-left: 275px;
	padding-top: var(--padding-top-25);
}

.event-block_three-room {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 235px;
	height: 235px;
	text-align: center;
	background: #e00d78;
	padding-top: var(--padding-top-40);
	background: linear-gradient(to top, #e00d78 0%, #1C171A 100%);
}

.event-block_three-icon {
	position: relative;
	line-height: 1em;
	display: block;
	color: var(--white-color);
	font-size: var(--font-70);
	font-family: "Flaticon";
}

.event-block_three-room_number {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	font-size: var(--font-24);
	margin-top: var(--margin-top-25);
}

.event-block_three-room_number span {
	position: relative;
	display: block;
	font-weight: 400;
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.event-block_three-date {
	position: relative;
	font-weight: 600;
	color: var(--color-five);
	font-size: var(--font-18);
	text-transform: uppercase;
	padding-left: var(--padding-left-30);
}

.event-block_three-date::before {
	position: absolute;
	content: "\f16c";
	left: 0px;
	top: 0px;
	font-family: "Flaticon";
	color: var(--color-three);
}

.event-block_three-day {
	position: relative;
	font-weight: 600;
	letter-spacing: 1px;
	font-size: var(--font-18);
	text-transform: uppercase;
	color: var(--color-three);
	margin-left: var(--margin-left-30);
}

.event-block_three-heading {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-10);
}

.event-block_three-heading a {
	position: relative;
	color: var(--color-two);
}

.event-block_three-heading a:hover {
	color: var(--color-three);
}

.event-block_three-text {
	position: relative;
	line-height: 28px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-10);
}

.event-block_three-place {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.event-block_three-place strong {
	position: relative;
	color: var(--color-two);
}

.speaker-schedule {
	position: relative;
	padding-bottom: var(--padding-bottom-110);
}

.speaker-schedule_form {
	position: relative;
	padding: 40px 30px;
	background-size: cover;
}

.speaker-schedule_title-box {
	position: relative;
	text-align: center;
	margin-bottom: var(--margin-bottom-25);
}

.speaker-schedule_title {
	position: relative;
	color: var(--white-color);
	font-size: var(--font-18);
}

.speaker-schedule_heading {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	margin-top: var(--margin-top-10);
}

/*** 

====================================================================
	Event Three
====================================================================

***/

.event-three {
	position: relative;
	padding: 110px 0px 110px;
}

.event-three .leadership-one_block {
	margin-bottom: var(--margin-bottom-30);
}

/*** 

====================================================================
	Event Detail
====================================================================

***/

.event-detail {
	position: relative;
	padding: 110px 0px 80px;
}

.event-detail .upper-box {
	position: relative;
	margin-bottom: var(--margin-bottom-35);
}

.event-detail .inner-container {
	position: relative;
	max-width: 970px;
	margin: 0 auto;
}

.event-detail_image {
	position: relative;
	margin-bottom: 40px;
}

.event-detail_author {
	position: relative;
	padding-top: 15px;
	padding-left: 180px;
}

.event-detail_author-image {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: hidden;
	border: 10px solid var(--color-four);
	border-radius: 100px 100px 0px 100px;
	max-width: 160px;
}

.event-detail_author-name {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-18);
	text-transform: uppercase;
}

.event-detail_author-name span {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-14);
	text-transform: capitalize;
}

.event-detail_author-location {
	position: relative;
	line-height: 24px;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-top: var(--margin-top-15);
}

.event-detail_author-location span {
	position: relative;
	font-weight: 700;
	color: var(--color-three);
}

.event-detail_author-timing {
	position: relative;
	margin-left: -16px;
	top: 1px;
	color: var(--color-five);
	font-size: var(--font-14);
	padding: 10px 25px 10px 35px;
	border-radius: 0px 50px 50px 0px;
	margin-top: var(--margin-top-15);
	background-color: var(--color-ten);
}

.event-detail_author-icon {
	position: absolute;
	left: 153px;
	bottom: 0px;
	width: 40px;
	height: 41px;
	line-height: 40px;
	text-align: center;
	color: var(--color-two);
	font-size: var(--font-18);
	font-family: "Flaticon";
	border-radius: 50px 50px 50px 0px;
	background-color: var(--main-color);
}

.event-detail p {
	color: var(--color-five);
	margin-bottom: var(--margin-bottom-30);
}

.event-detail_subtitle {
	margin-bottom: var(--margin-bottom-25);
}

.event-detail_image-two {
	position: relative;
}

.event-detail_image-two img {
	position: relative;
	width: 100%;
	display: block;
}

.event-detail_title{
	max-width: 380px;
}

.event-detail_list {
	position: relative;
}

.event-detail_list li {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-30);
	margin-bottom: var(--margin-bottom-15);
}

.event-detail_list li::before {
	position: absolute;
	content: "\f1a9";
	left: 0px;
	font-family: "Flaticon";
	font-size: var(--font-16);
}

.event-detail .middle-column {
	position: relative;
	margin-top: var(--margin-top-30);
	margin-bottom: var(--margin-bottom-30);
}

/*** 

====================================================================
	Register Section
====================================================================

***/

.register-section {
	position: relative;
	padding: 110px 0px 80px;
}

.register-section .row {
	margin: 0px -40px;
}

.register-section .column {
	padding: 0px 40px;
}

.register-section h4 {
	font-weight: 500;
	color: var(--black-color);
	margin-bottom: var(--margin-bottom-30);
}

.styled-form {
	position: relative;
}

.styled-form .submit-text {
	font-weight: 400;
	font-size: var(--font-15);
	color: var(--main-color-two);
	margin-top: var(--margin-top-10);
}

.styled-form .form-group {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.styled-form .form-group label {
	position: relative;
	display: block;
	font-weight: 500;
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-15);
}

.checkout-section .styled-form .form-group {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.styled-form .form-group .check-box {
	line-height: 24px;
	font-size: var(--font-15);
	font-weight: normal;
	padding-top: var(--padding-top-5);
	color: var(--main-color-two);
}

.styled-form .form-group .check-box label {
	position: relative;
	display: block;
	width: 100%;
	line-height: 22px;
	padding: 4px 10px 0px;
	padding-left: 25px;
	font-size: 16px;
	font-weight: 400;
	color: #4e4f51;
	cursor: pointer;
	margin-bottom: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.styled-form .form-group .check-box label a {
	color: var(--color-three);
}

.styled-form .form-group .check-box input[type="checkbox"] {
	display: none;
}

.styled-form .form-group .check-box input[type="checkbox"]:checked+label:before {
	border: 5px solid var(--main-color);
	background: var(--white-color);
}

.styled-form .form-group .check-box input[type="checkbox"]:checked+label:after {
	max-width: 20px;
	opacity: 1;
}

.styled-form .form-group .check-box label:before {
	position: absolute;
	left: 0;
	top: 8px;
	height: 14px;
	width: 12px;
	background-color: var(--white-color);
	content: "";
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	border: 1px solid rgba(var(--black-color-rgb), 0.15);
}

.styled-form input[type="text"],
.styled-form input[type="email"],
.styled-form input[type="password"],
.styled-form input[type="tel"],
.styled-form input[type="number"],
.styled-form input[type="url"],
.styled-form select,
.styled-form textarea {
	position: relative;
	display: block;
	width: 100%;
	background: var(--white-color);
	line-height: 30px;
	padding: 8px 30px;
	height: 56px;
	font-weight: 400;
	border-radius: 50px;
	font-size: var(--font-14);
	border: 2px solid #f4f4f4;
	color: var(--color-two);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	font-family: var(--font-family-Inter);
}

.checkout-section .styled-form input[type="text"],
.checkout-section .styled-form input[type="email"],
.checkout-section .styled-form input[type="password"],
.checkout-section .styled-form select {
	line-height: 26px;
	height: 44px;
}

.styled-form select option {
	text-indent: 20px;
}

.styled-form .form-group button {}

.styled-form textarea {
	resize: none;
	height: 110px;
}

.styled-form input:focus,
.styled-form select:focus,
.styled-form textarea:focus {
	border-color: var(--main-color);
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container {
	position: relative;
	padding: 110px 0px 70px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side {
	margin-bottom: var(--margin-bottom-40);
}

.sidebar-page-container .content-side .news-block_one-image img{
   min-height: 505px;
   object-fit: cover;
}

@media(max-width:767px){
	.sidebar-page-container .content-side .news-block_one-image img{
		min-height: 100%;
	 }
}
.sticky-top {
	top: 90px;
	z-index: 1;
}

/*** 

====================================================================
	Sidebar Widget
====================================================================

***/

.sidebar-widget {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.sidebar-widget .widget-content {
	position: relative;
	padding: 35px 35px;
	background-color: var(--color-twelve);
}

.sidebar-widget_title {
	position: relative;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-25);
}

/* Search Widget */

.sidebar .search-box .form-group {
	position: relative;
	margin: var(--margin-zero);
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
	position: relative;
	line-height: 28px;
	font-size: var(--font-14);
	padding: 10px 50px 10px 25px;
	background: none;
	display: block;
	width: 100%;
	height: 56px;
	border-radius: 0px;
	color: var(--color-two);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border: 1px solid var(--color-ten);
}

.sidebar .search-box .form-group input::placeholder,
.sidebar .search-box .form-group textarea::placeholder {
	color: var(--color-two);
}

.sidebar .search-box .form-group button {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 56px;
	width: 56px;
	display: block;
	cursor: pointer;
	line-height: 56px;
	font-weight: normal;
	border-radius: 0px;
	font-size: var(--font-16);
	color: var(--color-two);
	background: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.sidebar .search-box .form-group button:hover {
	background-color: var(--main-color);
	color: var(--white-color);
}

/* Category Widget */

.category-list {
	position: relative;
}

.category-list li {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.category-list li a {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-25);
}

.category-list li a::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 7px;
	width: 8px;
	height: 8px;
	border-radius: 50px;
	border: 1px solid var(--color-five);
}

.category-list li:last-child {
	margin-bottom: var(--margin-zero);
}

.category-list li a:hover {
	color: var(--color-three);
}

.category-list li span {
	position: absolute;
	right: 0px;
}

.author-widget .widget-content {
	text-align: center;
	padding-top: var(--padding-zero);
}

.author-widget_image {
	position: relative;
	width: 200px;
	height: 300px;
	margin: 0 auto;
	overflow: hidden;
	border-radius: 0px 0px 100px 100px;
}

.author-widget_name {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	color: var(--color-two);
	font-size: var(--font-20);
	text-transform: uppercase;
	margin-top: var(--margin-top-30);
}

.author-widget_text {
	position: relative;
	line-height: 28px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.author-widget_socials {
	position: relative;
	margin-top: var(--margin-top-25);
}

.author-widget_socials li {
	position: relative;
	margin: 0px 4px;
	display: inline-block;
}

.author-widget_socials li a {
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-14);
	background-color: var(--white-color);
}

.author-widget_socials li a:hover {
	color: var(--white-color);
	background-color: var(--color-three);
}

/* Post Widget */

.post-widget .post {
	position: relative;
	min-height: 80px;
	padding-left: var(--padding-left-95);
	margin-bottom: var(--margin-bottom-20);
}

.post-widget .post:last-child {
	margin-bottom: var(--margin-zero);
	padding-bottom: var(--padding-zero);
}

.post-widget .post .thumb {
	position: absolute;
	left: 0;
	top: 0;
	height: 80px;
	width: 80px;
	border-radius: 50%;
	overflow: hidden;
}

.post-widget .post .thumb a {
	display: block;
}

.post-widget .post .thumb img {
	display: block;
	width: 100%;
	height: auto;
}

.post-widget .post h6 {
	line-height: 24px;
	margin-top: 3px;
}

.post-widget .post h6 a {
	color: var(--black-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.post-widget .post h6 a:hover {
	color: var(--color-three);
}

.post-widget .post .post-date {
	position: relative;
	display: block;
	font-weight: 500;
	letter-spacing: 1px;
	color: var(--color-three);
	font-size: var(--font-12);
	text-transform: uppercase;
}

/* Popular Tags */

.popular-tags a {
	position: relative;
	padding: 7px 18px;
	font-weight: 600;
	letter-spacing: 1px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-12);
	text-transform: uppercase;
	background-color: var(--white-color);
	margin-right: var(--margin-right-5);
	margin-bottom: var(--margin-bottom-10);
}

.popular-tags a:hover {
	color: var(--white-color);
	background-color: var(--main-color);
}

.sidebar-page-container.left-sidebar .sidebar-side {
	order: 1;
}

.sidebar-page-container.left-sidebar .content-side {
	order: 2;
}

/*** 

====================================================================
	Error Section
====================================================================

***/

.error-section {
	position: relative;
	text-align: center;
	padding: 110px 0px 80px;
}
.video{
	height: 55vh;
    overflow: hidden;
    width: 88%;
    border-radius: 20px;
    margin-right: 3%;
}
.error-section .content {
	position: relative;
}

.error-section h1 {
	line-height: 1em;
	font-size: 340px;
	font-weight: 700;
	color: #fff;
}

.error-section h2 {
	color: #fff;
	margin-top: var(--margin-top-15);
}

.error-section .text {
	line-height: 1.8em;
	font-size: var(--font-18);
	color: var(--color-two);
	margin-top: var(--margin-top-35);
}

.error-section .button-box {
	margin-top: var(--margin-top-35);
}

.error-section .button-box .theme-btn {
	border-radius: 0px;
	padding: 18px 40px;
}

/*** 

====================================================================
	Blog Detail
====================================================================

***/

.blog-detail {
	position: relative;
}

.blog-detail_inner {
	position: relative;
}

.blog-detail_image {
	position: relative;
}

.blog-detail_image img {
	position: relative;
	width: 100%;
	display: block;
	height: auto;
	object-fit: cover;
}

.blog-detail_content {
	position: relative;
	padding: 45px 30px 30px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.blog-detail_date {
	position: absolute;
	left: 30px;
	top: -17px;
	font-weight: 600;
	padding: 6px 15px;
	letter-spacing: 2px;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: uppercase;
	background-color: var(--main-color);
}

.blog-detail_meta {
	position: relative;
}

.blog-detail_meta li {
	position: relative;
	display: inline-block;
	color: #000000;
	text-transform: capitalize;
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
}

.blog-detail_meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
	color: var(--color-four);
}

.blog-detail_heading {
	margin: 15px 0px 15px;
}

blockquote {
	position: relative;
	padding: 35px 35px;
	font-style: italic;
	line-height: 32px;
	color: var(--color-five);
	font-size: var(--font-18);
	margin-top: var(--margin-top-30);
	background-color: var(--color-seven);
	margin-bottom: var(--margin-bottom-40);
}

.blockquote_author {
	position: relative;
	display: block;
	font-weight: 700;
	font-style: normal;
	text-transform: uppercase;
	color: var(--color-three);
	margin-top: var(--margin-top-10);
}

.blockquote_quote {
	position: absolute;
	right: 30px;
	bottom: 20px;
	opacity: 0.20;
	line-height: 1em;
	font-style: normal;
	font-family: "Flaticon";
	color: var(--color-three);
	font-size: var(--font-50);
}

.blog-detail .two-column {
	position: relative;
	margin-top: var(--margin-top-30);
	margin-bottom: var(--margin-bottom-30);
}

/* Post Share Options */

.post-share-options {
	position: relative;
	margin-top: var(--margin-top-30);
}

.post-share-options .post-share-inner {
	position: relative;
	padding: 25px 0px 0px;
	border-top: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.post-share-options .tags span {
	position: relative;
	font-weight: 700;
	font-size: var(--font-16);
	color: var(--black-color);
	margin-right: var(--margin-right-10);
}

.post-share-options .tags a {
	position: relative;
	font-weight: 400;
	line-height: 1em;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: capitalize;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	margin-right: var(--margin-right-10);
}

.post-share-options .tags a:last-child {
	margin-right: 0px;
}

.post-share-options .tags a:hover {
	color: var(--main-color);
}

.post-share-options .social-box {
	position: relative;
}

.post-share-options .social-box span {
	position: relative;
	font-weight: 600;
	font-size: var(--font-16);
	color: var(--black-color);
	margin-right: var(--margin-right-10);
}

.post-share-options .social-box li {
	position: relative;
	display: inline-block;
	margin-right: var(--margin-right-10);
}

.post-share-options .social-box li:last-child {
	margin-right: var(--margin-zero);
}

.post-share-options .social-box li a {
	position: relative;
	font-weight: 400;
	line-height: 1em;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: capitalize;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.post-share-options .social-box li a:hover {
	color: var(--main-color);
}

/* Blog Author Box */

.blog-author-box {
	position: relative;
	padding: 30px 30px;
	margin-top: var(--margin-top-45);
	background-color: var(--color-seven);
}

.blog-author-box .author-inner {
	position: relative;
	min-height: 170px;
	padding-right: 200px;
}

.blog-author-box .thumb {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 170px;
	height: 170px;
	border: 4px solid var(--white-color);
}

.blog-author-box .name {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-24);
}

.blog-author-box .name span {
	position: relative;
	display: block;
	color: var(--color-four);
	font-size: var(--font-14);
	text-transform: capitalize;
	margin-top: var(--margin-top-10);
}

.blog-author-box .text {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.blog-author-box .social-box {
	position: absolute;
	right: 200px;
	top: 5px;
}

.blog-author-box .social-box li {
	position: relative;
	display: inline-block;
	margin-right: var(--margin-right-5);
}

.blog-author-box .social-box li a {
	position: relative;
	color: var(--color-two);
	font-size: var(--font-16);
}

.blog-author-box .social-box li a:hover {
	color: var(--color-three);
}

/* Comment Login Box */

.comment-login_box {
	position: relative;
	margin-top: var(--margin-top-40);
}

.comment-login_title {
	position: relative;
	font-weight: 500;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-20);
}

.comment-login_box-inner {
	position: relative;
	padding: 20px 30px;
	border-radius: 8px;
	margin-bottom: var(--margin-bottom-40);
	border: 2px solid rgba(var(--black-color-rgb), 0.08);
}

.comment-login_box-text {
	position: relative;
	font-size: var(--font-14);
	color: var(--color-two);
}

.comment-login_box-nav {
	position: relative;
}

.comment-login_box-nav li {
	position: relative;
	line-height: 1em;
	display: inline-block;
	margin-right: var(--margin-right-15);
	padding-right: var(--padding-right-15);
	border-right: 2px solid rgba(var(--black-color-rgb), 0.15);
}

.comment-login_box-nav li a {
	position: relative;
	line-height: 1em;
	font-size: var(--font-14);
	color: var(--color-two);
}

.comment-login_box-nav li:last-child {
	border-right: none;
	margin-right: var(--margin-zero);
	padding-right: var(--padding-zero);
}

.comment-login_box-nav li a:hover {
	color: var(--black-color);
}

.comments-area {
	position: relative;
}

.comments-area .group-title {
	position: relative;
	margin-bottom: var(--margin-bottom-25);
}

.comments-content {
	position: relative;
	padding: 45px 40px;
	border-radius: 5px;
	background-color: var(--color-seven);
	margin-bottom: var(--margin-bottom-30);
}

.comment-box {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.comment-box:last-child {
	margin-bottom: var(--margin-zero);
}

.comment-box .comment {
	position: relative;
	padding-left: var(--padding-left-80);
}

.comment-box .author-thumb {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
	overflow: hidden;
	border-radius: 50%;
}

.comment-options {
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}

.comment-options li {
	position: relative;
	display: inline-block;
	margin-left: var(--margin-left-15);
}

.comment-options li a {
	position: relative;
	font-size: var(--font-14);
	color: var(--color-fiftyeight);
}

.comment-options li a:hover {
	color: var(--black-color);
}

.comment-box .comment-info {
	position: relative;
	padding-top: var(--padding-top-5);
}

.comment-box .comment-info strong {
	position: relative;
	display: block;
	font-size: var(--font-18);
}

.comment-box .comment-time {
	position: relative;
	font-size: var(--font-14);
	margin-top: var(--margin-top-5);
}

.comment-box .text {
	position: relative;
	line-height: 28px;
	font-size: var(--font-14);
	margin-top: var(--margin-top-15);
	margin-bottom: var(--margin-bottom-15);
}

.likes-option {
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.likes-option li {
	position: relative;
	display: inline-block;
	margin-left: var(--margin-left-15);
	padding-left: var(--padding-left-25);
}

.likes-option li span {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
}

.comment-reply {
	position: relative;
	font-size: var(--font-14);
	color: var(--color-fiftyeight);
}

.comment-reply span {
	position: relative;
	margin-right: var(--margin-right-5);
}

.reply-comment {
	position: relative;
	padding: 40px 40px;
	border-radius: 5px;
	margin-left: var(--margin-left-80);
	background-color: var(--white-color);
}

.comment-form {
	position: relative;
	margin-top: var(--margin-top-50);
}

.comment-form .group-title {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.comment-form .default-form {
	position: relative;
	padding: 45px 45px;
	border-radius: 5px;
	background-color: var(--color-seven);
}

.comment-form .default-form input,
.comment-form .default-form textarea {
	background-color: var(--white-color) !important;
}

/*** 

====================================================================
	Comment Form
====================================================================

***/

.comment-form-outer {
	position: relative;
}

.comment-form .form-group {
	margin-bottom: var(--margin-bottom-25);
}

.comment-form .form-group:last-child {
	margin-bottom: 0px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 25px;
	height: 60px;
	border-radius: 0px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	border: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.comment-form .form-group textarea::-webkit-input-placeholder,
.comment-form .form-group input::-webkit-input-placeholder {
	color: var(--color-twentyone);
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
	border-color: var(--black-color);
}

.comment-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 25px 25px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	height: 190px;
	resize: none;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	border: 1px solid rgba(var(--black-color-rgb), 0.10);
}

/*** 

====================================================================
	Contact Form
====================================================================

***/

.contact-form .form-group {
	margin-bottom: var(--margin-bottom-25);
}

.contact-form .form-group:last-child {
	margin-bottom: 0px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 25px;
	height: 56px;
	border-radius: 0px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
	border: 1px solid rgba(var(--black-color-rgb), 0.04);
}

.contact-form .form-group textarea::-webkit-input-placeholder,
.contact-form .form-group input::-webkit-input-placeholder {
	color: var(--color-twentyone);
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
	border-color: var(--black-color);
}

.contact-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 25px 25px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	height: 190px;
	resize: none;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
	border: 1px solid rgba(var(--black-color-rgb), 0.04);
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
	border-color: #ff0000 !important;
}

.contact-form label.error {
	display: block;
	line-height: 24px;
	padding: 5px 0px 0px;
	margin: 0px;
	text-transform: uppercase;
	font-size: 12px;
	color: #ff0000;
	font-weight: 500;
}

.map-one {
	position: relative;
	padding: 110px 0px 110px;
}

.map-one iframe {
	width: 100%;
	height: 450px;
}


/*** 

====================================================================
	Contact Page Section
====================================================================

***/

.contact-page-section {
	position: relative;
	padding: 100px 0px 70px;
}

.contact-page-section .info-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section .info-column .inner-column {
	position: relative;
}

.contact-page-section .info-column .info-box {
	position: relative;
	margin-bottom: var(--margin-bottom-50);
}

.contact-page-section .info-column .info-box .box-inner {
	position: relative;
	padding: 40px 40px;
	border: 1px solid #dddddd;
}

.contact-page-section .info-column .info-box .icon {
	position: relative;
	display: block;
	line-height: 1em;
	font-size: var(--font-50);
	color: var(--main-color);
	font-family: "Flaticon";
	margin-bottom: var(--margin-bottom-10);
}

.contact-page-section .info-column .info-box h6 {
	text-transform: uppercase;
	margin-bottom: var(--margin-bottom-10);
}

.contact-page-section .info-column a {
	position: relative;
	font-size: var(--font-16);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.contact-page-section .info-column a:hover {
	color: var(--main-color);
}

.contact-page-section .info-column .social-box {
	position: relative;
}

.contact-page-section .info-column .social-box li {
	position: relative;
	display: inline-block;
}

.contact-page-section .info-column .social-box li a {
	position: relative;
	font-size: var(--font-16);
	width: 40px;
	height: 40px;
	line-height: 38px;
	text-align: center;
	border-radius: 0px;
	border: 1px solid #dddddd;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.contact-page-section .info-column .social-box li a:hover {
	background-color: var(--main-color);
	border-color: var(--main-color);
	color: var(--white-color)
}

.contact-page-section .form-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section .form-column .inner-column {
	position: relative;
}

.contact-page-section .form-column iframe {
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 415px;
	margin-top: var(--margin-top-30);
}



/*** 

====================================================================
	Contact Page Section
====================================================================

***/

.contact-page-section-two {
	position: relative;
	padding: 100px 0px 0px;
}

.contact-page-section-two .map-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section-two .map-column .inner-column {
	position: relative;
}

.contact-page-section-two .map-column iframe {
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 570px;
}


.contact-page-section-two .info-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section-two .info-column .inner-column {
	position: relative;
	margin-left: var(--margin-left-60);
	border-left: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.contact-page-section-two .info {
	position: relative;
}

.contact-page-section-two .info li {
	position: relative;
	line-height: 26px;
	color: var(--color-two);
	font-size: var(--font-16);
	padding-left: var(--padding-left-45);
	margin-bottom: var(--margin-bottom-40);
	padding-bottom: var(--padding-bottom-40);
	border-bottom: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.contact-page-section-two .info li span {
	position: relative;
	display: block;
	font-weight: 600;
	font-size: var(--font-22);
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-10);
}

.contact-page-section-two .info li:last-child {
	border-bottom: none;
}

.contact-form-section {
	position: relative;
	padding-bottom: var(--padding-bottom-110);
}

.contact-form-section h3 {
	position: relative;
	margin-bottom: var(--margin-bottom-25);
}

.xs-sidebar-group .close-button {
	font-family: "Flaticon";
}

.mrl-12{
	margin: 0 120px 0 120px;
}

p.pera {
    color: #fff;
    text-align: center;
}

.timeline {
	position: relative;
	width: 100%;
	padding: 30px 0;
  }
  
  .timeline .timeline-container {
	position: relative;
	width: 100%;
  }
  
  .timeline .timeline-end,
  .timeline .timeline-start,
  .timeline .timeline-year {
	position: relative;
	width: 100%;
	text-align: center;
	z-index: 1;
  }
  div#panel1bh-header{
	text-align: left !important;
  }

  p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
    text-align: left !important;
	color: #ffffff;
	}

  .timeline .timeline-end p,
  .timeline .timeline-start p,
  .timeline .timeline-year p {
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: 0;
	padding: 30px 0;
	text-align: center;
	background: #c99c18;
	/* background: linear-gradient(#72706c, #c88c19); */
	border-radius: 100px;
	box-shadow: 0 0 5px rgba(0, 0, 0, .4);
	color: #ffffff;
	font-size: 14px;
	text-transform: uppercase;
  }
  
  .timeline .timeline-year {
	margin: 30px 0;
  }
  
  .timeline .timeline-continue {
	position: relative;
	width: 100%;
	padding: 60px 0;
  }
  
  .timeline .timeline-continue::after {
	position: absolute;
	content: "";
	width: 1px;
	height: 100%;
	top: 0;
	left: 50%;
	margin-left: -1px;
	background: #c88c19;
  }
  
  .timeline .row.timeline-left,
  .timeline .row.timeline-right .timeline-date {
	text-align: right;
	color:#ffffff
  }
  
  .timeline .row.timeline-right,
  .timeline .row.timeline-left .timeline-date {
	text-align: left;
  }
  
  .timeline .timeline-date {
	font-size: 14px;
	font-weight: 600;
	margin: 41px 0 0 0;
  }
  
  .timeline .timeline-date::after {
	content: '';
	display: block;
	position: absolute;
	width: 14px;
	height: 14px;
	top: 43px;
	background: linear-gradient(#72706c, #c88c19);
	box-shadow: 0 0 5px rgba(0, 0, 0, .4);
	border-radius: 15px;
	z-index: 1;
  }
  
  .timeline .row.timeline-left .timeline-date::after {
	left: -19px;
  }
  
  .timeline .row.timeline-right .timeline-date::after {
	right: -19px;
  }
  
  .timeline .timeline-box,
  .timeline .timeline-launch {
	position: relative;
	display: inline-block;
	margin: 15px;
	padding: 20px;
	border: 1px solid #dddddd;
	border-radius: 6px;
	background: #ffffff;
  }
  
  .timeline .timeline-launch {
	width: 100%;
	margin: 15px 0;
	padding: 0;
	border: none;
	text-align: center;
	background: transparent;
  }
  
  .timeline .timeline-box::after,
  .timeline .timeline-box::before {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
  }
  
  .timeline .row.timeline-left .timeline-box::after,
  .timeline .row.timeline-left .timeline-box::before {
	left: 100%;
  }
  
  .timeline .row.timeline-right .timeline-box::after,
  .timeline .row.timeline-right .timeline-box::before {
	right: 100%;
  }
  
  .timeline .timeline-launch .timeline-box::after,
  .timeline .timeline-launch .timeline-box::before {
	left: 50%;
	margin-left: -10px;
  }
  
  .timeline .timeline-box::after {
	top: 26px;
	border-color: transparent transparent transparent #ffffff;
	border-width: 10px;
  }
  
  .timeline .timeline-box::before {
	top: 25px;
	border-color: transparent transparent transparent #dddddd;
	border-width: 11px;
  }
  
  .timeline .row.timeline-right .timeline-box::after {
	border-color: transparent #ffffff transparent transparent;
  }
  
  .timeline .row.timeline-right .timeline-box::before {
	border-color: transparent #dddddd transparent transparent;
  }
  
  .timeline .timeline-launch .timeline-box::after {
	top: -20px;
	border-color: transparent transparent #dddddd transparent;
  }
  
  .timeline .timeline-launch .timeline-box::before {
	top: -19px;
	border-color: transparent transparent #ffffff transparent;
	border-width: 10px;
	z-index: 1;
  }
  
  .timeline .timeline-box .timeline-icon {
	position: relative;
	width: 40px;
	height: auto;
	float: left;
  }
  
  .timeline .timeline-icon i {
	font-size: 25px;
	color: #c99c18;
  }
  
  .timeline .timeline-box .timeline-text {
	position: relative;
	width: calc(100% - 40px);
	float: left;
  }
  
  .timeline .timeline-launch .timeline-text {
	width: 100%;
  }
  
  .timeline .timeline-text h3 {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 3px;
	color: #c99C18;
    font-weight: 700;
  }
  
  .timeline .timeline-text p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
	color: #000;
  }
  
  @media (max-width: 768px) {
	.timeline .timeline-continue::after {
	  left: 40px;
	}
  
	.timeline .timeline-end,
	.timeline .timeline-start,
	.timeline .timeline-year,
	.timeline .row.timeline-left,
	.timeline .row.timeline-right .timeline-date,
	.timeline .row.timeline-right,
	.timeline .row.timeline-left .timeline-date,
	.timeline .timeline-launch {
	  text-align: left;
	}
  
	.timeline .row.timeline-left .timeline-date::after,
	.timeline .row.timeline-right .timeline-date::after {
	  left: -22px;
	}
  
	.timeline .timeline-box,
	.timeline .row.timeline-right .timeline-date,
	.timeline .row.timeline-left .timeline-date {
	  margin-left: 55px;
	}
  
	.timeline .timeline-launch .timeline-box {
	  margin-left: 0;
	}
  
	.timeline .row.timeline-left .timeline-box::after {
	  left: -20px;
	  border-color: transparent #ffffff transparent transparent;
	}
  
	.timeline .row.timeline-left .timeline-box::before {
	  left: -22px;
	  border-color: transparent #dddddd transparent transparent;
	}
  
	.timeline .timeline-launch .timeline-box::after,
	.timeline .timeline-launch .timeline-box::before {
	  left: 30px;
	  margin-left: 0;
	}
  }


  .fa-twitter:before {
    content: "";
    background: url(../images/twitter.png);
    width: 18px;
    height: 18px;
    display: block;
    background-size: cover;
    position: relative;
    top: 10px;
    right: -10px;
}


.main-timeline-section {
	position: relative;
	width: 100%;
	margin: auto;
	height: 300px;
  }
  .main-timeline-section .timeline-start,
  .main-timeline-section .timeline-end {
	position: absolute;
	background: #c88c19;
	border-radius: 100px;
	top: 50%;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
  }
  .main-timeline-section .timeline-end {
	right: 0px;
  }
  .main-timeline-section .conference-center-line {
	position: absolute;
	width: 100%;
	height: 5px;
	top: 50%;
	transform: translateY(-50%);
	background: #c88c19;
  }
  .timeline {
    margin-bottom: 105px;
	margin-top: 60px;
  }
  .timeline-article {
	width: 20%;
	position: relative;
	min-height: 300px;
	float: right;
  }
  .timeline-article .content-date {
	position: absolute;
	color:#fff;
	top: 35%;
	left: 103px;
	font-size: 18px;
  }
  .timeline-article .meta-date {
	position: absolute;
	top: 50%;
	left: 118px;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	border-radius: 100%;
	background: #fff;
	border: 1px solid #c88c19;
  }
  .timeline-article .content-box {
	/* box-shadow: 2px 2px 4px 0px #c1c1c1;
	border: 1px solid #c88c19; */
	border-radius: 15px;
	background-color: #fff;
	width: 219px;
	position: absolute;
	top: 69%;
	left: 19px;
	padding: 10px;
  }
  .timeline-article-top .content-box:before {
	content: " ";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: -20px;
	border: 10px solid transparent;
	border-bottom-color: #c88c19;
  }
  .timeline-article-bottom .content-date {
	top: 59%;
  }
  .timeline-article-bottom .content-box {
	top: 0%;
  }
  .timep{
	font-size: 16px;
	text-align: center;
	margin-bottom: 0rem !important;
  }
  .timeline-article-bottom .content-box:before {
	content: " ";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -20px;
	border: 10px solid transparent;
	border-top-color: #c88c19;
  }
  @media (max-width: 460px) {
  }
a.fa.fa-twitter {
    position: relative;
    top: 15px;
}

/* .clients-two_slide {
    justify-content: center;
    gap: 0px;
    overflow: visible;
}
.clients-two_column {
    width: 155px;
    height: auto;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.clients-two_slide.d-flex img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
} */


.MuiCollapse-wrapper.MuiCollapse-vertical.css-hboir5 {
    text-align: left;
    margin-left: 14px;
}

.accordion-box li {
    list-style: decimal;
    margin-bottom: 15px;
    text-align: left;
}
.sociala:hover {
    color: #fff;
}

.timeline.mobile {
    display: none;
}

.home {
    height: 100vh;
    position: relative;
}
video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.home-content {
    position: relative;
    padding-top: 150px;
    color: #fff;
    text-align: center;
}
h1 {
    font-family: Playfair Display, serif;
    font-size: clamp(45px, 7vw, 130px);
    line-height: 1.1;
}

.home p {
    font-size: clamp(25px, 4vw, 40px);
    margin-top: 10px;
}

.home-content button {
    display: block;
    font-size: clamp(14px, 1.5vw, 18px);
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    background: transparent;
    color: #fff;
    margin: 50px auto 0;
    padding: 12px 20px;
    cursor: pointer;
}
/* .MuiCollapse-wrapper>.MuiCollapse-wrapperInner {
    background: #fff;
} */
p.insd {
    font-size: 15px;
    color: #fff;
}

.videoo{
	width: 730px;
    height: 465px;
}
.videoo-div{
	width: 734px;
    height: 470px;
}
.videoo-div-s{
	width: 734px;
    height: 470px;
}
/* #about{
	margin-bottom: 170px;
} */



.banner-who {
    background-image: url('../images/background/Who-We-Are-1.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-team {
    background-image: url('../images/background/team.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-winner {
    background-image: url('../images/background/Past-Winner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-spicialb {
    background-image: url('../images/background/Special-Invitees-Page-Business-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-spicialh {
    background-image: url('../images/background/Special-Invitees-Page-Health-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-specialindia {
    background-image: url('../images/background/SpecialINvitee-Page-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-guide {
    background-image: url('../images/background/Participation-Guideline.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-newroom {
    background-image: url('../images/background/Newsroom-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-memoeryb {
    background-image: url('../images/background/Memory-Room-Business-Page-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-memoeryh {
    background-image: url('../images/background/Memory-Room-Health-Page-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-partnerb {
    background-image: url('../images/background/Media-Coverage-Business-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-partnerh {
    background-image: url('../images/background/Media-Coverage-Health-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-partner2023 {
    background-image: url('../images/background/Awards.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-keynoteb {
    background-image: url('../images/background/Keynote-Speaker-Business-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-keynoteh {
    background-image: url('../images/background/Keynote-Speaker-Health-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-aim {
    background-image: url('../images/background/our-aim.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-health {
    background-image: url('../images/background/Health-2024.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-business {
    background-image: url('../images/background/Business-Leaders-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-green {
    background-image: url('../images/background/Green-Leaders-Page-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-contact {
    background-image: url('../images/background/Contact-us-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-awardcat {
    background-image: url('../images/background/Global-Edition-Category-Page.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-specialg {
    background-image: url('../images/background/Special-Guest.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-jury {
    background-image: url('../images/background/Jury.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-blog {
    background-image: url('../images/background/Blog.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-faq {
    background-image: url('../images/background/FAQs.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-indiab {
    background-image: url('../images/background/Business-2024.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-indiah {
    background-image: url('../images/background/Health-2023.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-memory2023 {
    background-image: url('../images/background/Gallery.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.banner-award {
    background-image: url('../images/background/Awards-Page-Banner.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.glimp {
    background-image: url('../images/background/2.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}
.glimpd {
    background-image: url('../images/background/2d.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; 
}

.videoo-s{
	width: 100%;
    height: 419px;
  }

.desk-viso{
	display: block;
}
.mob-viso{
	display: none;
}

.homevideo{
	display: block;
}
.homevideomob{
	display: none;
}
a.par.theme-btn.btn-style-one {
    margin-top: 18px;
}
/* Mobile Background */
@media (max-width: 768px) {
	.homevideo{
		display: none;
	}
	.homevideomob{
		display: block;
	}
	.videoo{
		width: 335px;
        height: 290px;
	}
	.desk-viso{
		display: none;
	}
	.mob-viso{
		display: block;
	}
	.videoo-s{
    width: 100%;
    height: 175px;
	}
	.videoo-div{
		width: 339px;
		height: 295px;
	}
	.videoo-div-s{
		width: 339px;
		height: 200px;
	}
	#about{
		margin-bottom: 0px;
	}
    .banner-who {
        background-image: url('../images/background/Who-We-Are-mob.jpg');
    }
    .banner-team {
        background-image: url('../images/background/team-mob.jpg');
    }
    .banner-winner {
        background-image: url('../images/background/Past-Winner-mob.jpg');
    }
    .banner-spicialb {
        background-image: url('../images/background/Special-Invitees-Page-Business-Banner-mob.jpg');
    }
    .banner-spicialh {
        background-image: url('../images/background/Special-Invitees-Page-Health-Banner-mob.jpg');
    }
    .banner-specialindia {
        background-image: url('../images/background/SpecialINvitee-Page-Banner-mob.jpg');
    }
    .banner-guide {
        background-image: url('../images/background/Participation-Guideline-mob.jpg');
    }
    .banner-newroom {
        background-image: url('../images/background/Newsroom-Banner-mob.jpg');
    }
    .banner-memoeryb {
        background-image: url('../images/background/Memory-Room-Business-Page-Banner-mob.jpg');
    }
    .banner-memoeryh {
        background-image: url('../images/background/Memory-Room-Health-Page-Banner-mob.jpg');
    }
    .banner-partnerb {
        background-image: url('../images/background/Media-Coverage-Business-Banner-mob.jpg');
    }
    .banner-partnerh {
        background-image: url('../images/background/Media-Coverage-Health-Banner-mob.jpg');
    }
    .banner-partner2023 {
        background-image: url('../images/background/Awards-mob.jpg');
    }
    .banner-keynoteb {
        background-image: url('../images/background/Keynote-Speaker-Business-Banner-mob.jpg');
    }
    .banner-keynoteh {
        background-image: url('../images/background/Keynote-Speaker-Health-Banner-mob.jpg');
    }
    .banner-aim {
        background-image: url('../images/background/our-aim-mob.jpg');
    }
    .banner-health {
        background-image: url('../images/background/Health-2024-mob.jpg');
    }
    .banner-business {
        background-image: url('../images/background/Business-Leaders-Banner-mob.jpg');
    }
    .banner-green {
        background-image: url('../images/background/Green-Leaders-Page-Banner-mob.jpg');
    }
    .banner-contact {
        background-image: url('../images/background/Contact-us-Banner-mob.jpg');
    }
    .banner-awardcat {
        background-image: url('../images/background/Global-Edition-Category-Page-mob.jpg');
    }
    .banner-specialg {
        background-image: url('../images/background/Special-Guest-mob.jpg');
    }
    .banner-jury {
        background-image: url('../images/background/Jury-mob.jpg');
    }
    .banner-blog {
        background-image: url('../images/background/Blog-mob.jpg');
    }
    .banner-faq {
        background-image: url('../images/background/FAQs-mob.jpg');
    }
    .banner-indiab {
        background-image: url('../images/background/Business-2024-mob.jpg');
    }
    .banner-indiah {
        background-image: url('../images/background/Health-2023-mob.jpg');
    }
    .banner-memory2023 {
        background-image: url('../images/background/Gallery-mob.jpg');
    }
    .banner-award {
        background-image: url('../images/background/Awards-Page-Banner-mob.jpg');
    }
    .glimp {
        background-image: url('../images/background/2-mob.jpg');
    }
    .glimpd {
        background-image: url('../images/background/2-mobd.jpg');
    }
}




.snowflake {
	--size: 1vw;
	width: var(--size);
	height: var(--size);
	background: white;
	border-radius: 50%;
	position: absolute;
	top: -5vh;
  }
  
  @keyframes snowfall {
	0% {
	  transform: translate3d(var(--left-ini), 0, 0);
	}
	100% {
	  transform: translate3d(var(--left-end), 110vh, 0);
	}
  }
  .snowflake:nth-child(1) {
	--size: 0.2vw;
	--left-ini: 5vw;
	--left-end: 6vw;
	left: 46vw;
	animation: snowfall 10s linear infinite;
	animation-delay: -3s;
  }
  
  .snowflake:nth-child(2) {
	--size: 0.8vw;
	--left-ini: 4vw;
	--left-end: 4vw;
	left: 30vw;
	animation: snowfall 15s linear infinite;
	animation-delay: -1s;
  }
  
  .snowflake:nth-child(3) {
	--size: 1vw;
	--left-ini: 8vw;
	--left-end: -2vw;
	left: 8vw;
	animation: snowfall 7s linear infinite;
	animation-delay: -6s;
  }
  
  .snowflake:nth-child(4) {
	--size: 0.4vw;
	--left-ini: 3vw;
	--left-end: 0vw;
	left: 84vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -1s;
  }
  
  .snowflake:nth-child(5) {
	--size: 1vw;
	--left-ini: 1vw;
	--left-end: 5vw;
	left: 30vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -7s;
  }
  
  .snowflake:nth-child(6) {
	--size: 1vw;
	--left-ini: 7vw;
	--left-end: -4vw;
	left: 28vw;
	animation: snowfall 15s linear infinite;
	animation-delay: -4s;
  }
  
  .snowflake:nth-child(7) {
	--size: 0.8vw;
	--left-ini: -2vw;
	--left-end: -7vw;
	left: 23vw;
	animation: snowfall 15s linear infinite;
	animation-delay: -4s;
  }
  
  .snowflake:nth-child(8) {
	--size: 1vw;
	--left-ini: 7vw;
	--left-end: 8vw;
	left: 100vw;
	animation: snowfall 6s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(9) {
	--size: 0.2vw;
	--left-ini: -1vw;
	--left-end: 7vw;
	left: 100vw;
	animation: snowfall 9s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(10) {
	--size: 0.8vw;
	--left-ini: 2vw;
	--left-end: -5vw;
	left: 10vw;
	animation: snowfall 10s linear infinite;
	animation-delay: -3s;
  }
  
  .snowflake:nth-child(11) {
	--size: 0.4vw;
	--left-ini: -2vw;
	--left-end: -6vw;
	left: 64vw;
	animation: snowfall 9s linear infinite;
	animation-delay: -10s;
  }
  
  .snowflake:nth-child(12) {
	--size: 0.8vw;
	--left-ini: -6vw;
	--left-end: -8vw;
	left: 88vw;
	animation: snowfall 12s linear infinite;
	animation-delay: -6s;
  }
  
  .snowflake:nth-child(13) {
	--size: 0.4vw;
	--left-ini: 3vw;
	--left-end: -7vw;
	left: 59vw;
	animation: snowfall 8s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(14) {
	--size: 0.2vw;
	--left-ini: 3vw;
	--left-end: 5vw;
	left: 97vw;
	animation: snowfall 6s linear infinite;
	animation-delay: -6s;
  }
  
  .snowflake:nth-child(15) {
	--size: 0.2vw;
	--left-ini: -5vw;
	--left-end: -4vw;
	left: 81vw;
	animation: snowfall 8s linear infinite;
	animation-delay: -7s;
  }
  
  .snowflake:nth-child(16) {
	--size: 0.6vw;
	--left-ini: 6vw;
	--left-end: 7vw;
	left: 30vw;
	animation: snowfall 7s linear infinite;
	animation-delay: -3s;
  }
  
  .snowflake:nth-child(17) {
	--size: 0.4vw;
	--left-ini: 9vw;
	--left-end: 7vw;
	left: 71vw;
	animation: snowfall 15s linear infinite;
	animation-delay: -1s;
  }
  
  .snowflake:nth-child(18) {
	--size: 0.8vw;
	--left-ini: -4vw;
	--left-end: -7vw;
	left: 68vw;
	animation: snowfall 10s linear infinite;
	animation-delay: -2s;
  }
  
  .snowflake:nth-child(19) {
	--size: 0.6vw;
	--left-ini: 4vw;
	--left-end: -3vw;
	left: 9vw;
	animation: snowfall 8s linear infinite;
	animation-delay: -1s;
  }
  
  .snowflake:nth-child(20) {
	--size: 0.4vw;
	--left-ini: 4vw;
	--left-end: 6vw;
	left: 15vw;
	animation: snowfall 13s linear infinite;
	animation-delay: -9s;
  }
  
  .snowflake:nth-child(21) {
	--size: 0.2vw;
	--left-ini: -8vw;
	--left-end: 7vw;
	left: 53vw;
	animation: snowfall 8s linear infinite;
	animation-delay: -4s;
  }
  
  .snowflake:nth-child(22) {
	--size: 0.6vw;
	--left-ini: -6vw;
	--left-end: 10vw;
	left: 47vw;
	animation: snowfall 6s linear infinite;
	animation-delay: -7s;
  }
  
  .snowflake:nth-child(23) {
	--size: 0.4vw;
	--left-ini: 6vw;
	--left-end: 2vw;
	left: 43vw;
	animation: snowfall 7s linear infinite;
	animation-delay: -5s;
  }
  
  .snowflake:nth-child(24) {
	--size: 0.4vw;
	--left-ini: 10vw;
	--left-end: -2vw;
	left: 64vw;
	animation: snowfall 6s linear infinite;
	animation-delay: -7s;
  }
  
  .snowflake:nth-child(25) {
	--size: 0.6vw;
	--left-ini: 9vw;
	--left-end: -4vw;
	left: 69vw;
	animation: snowfall 10s linear infinite;
	animation-delay: -10s;
  }
  
  .snowflake:nth-child(26) {
	--size: 0.8vw;
	--left-ini: 5vw;
	--left-end: 9vw;
	left: 49vw;
	animation: snowfall 12s linear infinite;
	animation-delay: -2s;
  }
  
  .snowflake:nth-child(27) {
	--size: 0.8vw;
	--left-ini: -6vw;
	--left-end: -3vw;
	left: 63vw;
	animation: snowfall 9s linear infinite;
	animation-delay: -9s;
  }
  
  .snowflake:nth-child(28) {
	--size: 0.8vw;
	--left-ini: 3vw;
	--left-end: 7vw;
	left: 29vw;
	animation: snowfall 6s linear infinite;
	animation-delay: -5s;
  }
  
  .snowflake:nth-child(29) {
	--size: 0.4vw;
	--left-ini: 8vw;
	--left-end: -9vw;
	left: 84vw;
	animation: snowfall 6s linear infinite;
	animation-delay: -4s;
  }
  
  .snowflake:nth-child(30) {
	--size: 0.8vw;
	--left-ini: 6vw;
	--left-end: 2vw;
	left: 26vw;
	animation: snowfall 8s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(31) {
	--size: 0.2vw;
	--left-ini: 1vw;
	--left-end: -3vw;
	left: 46vw;
	animation: snowfall 13s linear infinite;
	animation-delay: -7s;
  }
  
  .snowflake:nth-child(32) {
	--size: 0.4vw;
	--left-ini: -1vw;
	--left-end: -1vw;
	left: 56vw;
	animation: snowfall 11s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(33) {
	--size: 0.6vw;
	--left-ini: -4vw;
	--left-end: 9vw;
	left: 81vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -6s;
  }
  
  .snowflake:nth-child(34) {
	--size: 1vw;
	--left-ini: -1vw;
	--left-end: -1vw;
	left: 33vw;
	animation: snowfall 11s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(35) {
	--size: 0.8vw;
	--left-ini: 5vw;
	--left-end: 4vw;
	left: 83vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -9s;
  }
  
  .snowflake:nth-child(36) {
	--size: 1vw;
	--left-ini: -3vw;
	--left-end: -1vw;
	left: 34vw;
	animation: snowfall 13s linear infinite;
	animation-delay: -4s;
  }
  
  .snowflake:nth-child(37) {
	--size: 0.4vw;
	--left-ini: -1vw;
	--left-end: -5vw;
	left: 80vw;
	animation: snowfall 10s linear infinite;
	animation-delay: -6s;
  }
  
  .snowflake:nth-child(38) {
	--size: 0.8vw;
	--left-ini: 7vw;
	--left-end: 10vw;
	left: 44vw;
	animation: snowfall 7s linear infinite;
	animation-delay: -7s;
  }
  
  .snowflake:nth-child(39) {
	--size: 1vw;
	--left-ini: -6vw;
	--left-end: 1vw;
	left: 66vw;
	animation: snowfall 11s linear infinite;
	animation-delay: -4s;
  }
  
  .snowflake:nth-child(40) {
	--size: 0.2vw;
	--left-ini: -5vw;
	--left-end: -3vw;
	left: 30vw;
	animation: snowfall 7s linear infinite;
	animation-delay: -7s;
  }
  
  .snowflake:nth-child(41) {
	--size: 1vw;
	--left-ini: -6vw;
	--left-end: -2vw;
	left: 21vw;
	animation: snowfall 11s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(42) {
	--size: 0.2vw;
	--left-ini: 3vw;
	--left-end: -1vw;
	left: 53vw;
	animation: snowfall 7s linear infinite;
	animation-delay: -3s;
  }
  
  .snowflake:nth-child(43) {
	--size: 0.8vw;
	--left-ini: 6vw;
	--left-end: 2vw;
	left: 42vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -9s;
  }
  
  .snowflake:nth-child(44) {
	--size: 0.4vw;
	--left-ini: 9vw;
	--left-end: 9vw;
	left: 36vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -3s;
  }
  
  .snowflake:nth-child(45) {
	--size: 1vw;
	--left-ini: -4vw;
	--left-end: 4vw;
	left: 38vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(46) {
	--size: 0.4vw;
	--left-ini: -2vw;
	--left-end: -8vw;
	left: 76vw;
	animation: snowfall 6s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(47) {
	--size: 1vw;
	--left-ini: -4vw;
	--left-end: 1vw;
	left: 89vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -8s;
  }
  
  .snowflake:nth-child(48) {
	--size: 1vw;
	--left-ini: -8vw;
	--left-end: -3vw;
	left: 8vw;
	animation: snowfall 7s linear infinite;
	animation-delay: -2s;
  }
  
  .snowflake:nth-child(49) {
	--size: 0.2vw;
	--left-ini: -4vw;
	--left-end: -7vw;
	left: 68vw;
	animation: snowfall 14s linear infinite;
	animation-delay: -6s;
  }
  
  .snowflake:nth-child(50) {
	--size: 0.2vw;
	--left-ini: -7vw;
	--left-end: 7vw;
	left: 3vw;
	animation: snowfall 13s linear infinite;
	animation-delay: -9s;
  }
  
  /* added small blur every 6 snowflakes*/
  .snowflake:nth-child(6n) {
	filter: blur(1px);
  }
 